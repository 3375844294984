/**
 * @file   src\components\Timer.tsx
 * @brief  This file is responsible for displaying timer for resend otp.
 * @date   JULY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { useState, useEffect } from './ThirdPartyComponents';
import { IOtpTimer } from '../interfaces/generalInterface';
import { DEFAULT_OTP_TIMER } from '../utils/constants';
import Strings from '../assests/strings/Strings.json';
import { stringFormat } from '../utils/helper';

const Timer = ({ timeLimit, isActive, handleOtpLink }: IOtpTimer) => {
  // Initialize the component state.
  const [seconds, setSeconds] = useState<number>(timeLimit ? timeLimit - 1 : DEFAULT_OTP_TIMER - 1);

  // Component side effect to handle isActive, seconds state change.
  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec) => sec - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      handleOtpLink(false);
      if (seconds <= 0) setSeconds(timeLimit);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return <>{stringFormat(Strings.VerifyMobileModal.TimerText, new Date(seconds * 1000).toISOString().slice(14, 19))}</>;
};

export default Timer;
