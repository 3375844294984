/**
 * @file   src\store\slices\manageCAScheduleSlice.ts
 * @brief  This file is responsible for creating Clinical assistants(CA) manage schedule slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageScheduleState } from '../../interfaces/scheduleInterface';
import { getAllShifts, getAllShiftTypes, unassignCA, assignCA, getShiftDetails, callOutShift, getUserShifts, revertAllScheduleState } from '../actions/manageCAScheduleAction';

// Initial state.
const shiftsDefaultState: IManageScheduleState = {
  isGetAllShiftSuccess: false,
  isGetAllShiftLoading: false,
  getAllShiftErrorCode: 0,
  getAllShiftMessage: null,
  shiftsList: null,

  isShiftTypesSuccess: false,
  isShiftTypesLoading: false,
  shiftTypesErrorCode: 0,
  shiftTypesMessage: null,
  shiftTypes: [],
  isAssignShiftSuccess: false,
  isAssignShiftLoading: false,
  assignShiftErrorCode: 0,
  assignShiftMessage: null,

  isUnassignShiftSuccess: false,
  isUnassignShiftLoading: false,
  unassignShiftErrorCode: 0,
  unassignShiftMessage: null,

  isShiftDetailsLoading: false,
  isShiftDetailsSuccess: false,
  shiftDetailsErrorCode: 0,
  shiftDetailsMessage: null,
  shiftDetails: null,

  isCallOutShiftSuccess: false,
  isCallOutShiftLoading: false,
  callOutShiftErrorCode: 0,
  callOutShiftMessage: null,

  isGetAllUserShiftSuccess: false,
  isGetAllUserShiftLoading: false,
  getAllUserShiftErrorCode: 0,
  getAllUserShiftMessage: null,
  userShiftsList: [],
};

// Manage schedule slice
const manageScheduleSlice = createSlice({
  name: 'manageCASchedule',
  initialState: shiftsDefaultState,
  reducers: {
    resetShiftsDefaultState: () => shiftsDefaultState,
  },
  extraReducers(builder) {
    // ********************* Start of all shifts action state handling. *********************
    // Get all shifts default state.
    builder.addCase(getAllShifts.pending, (state) => {
      state.isGetAllShiftLoading = true;
      state.isGetAllShiftSuccess = false;
      state.getAllShiftErrorCode = 0;
      state.getAllShiftMessage = '';
    });
    // Get all shifts success state.
    builder.addCase(getAllShifts.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isGetAllShiftLoading = false;
      state.isGetAllShiftSuccess = true;
      state.shiftsList = data || null;
      state.getAllShiftErrorCode = code;
      state.getAllShiftMessage = status || '';
    });
    // Get all shifts error state.
    builder.addCase(getAllShifts.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isGetAllShiftLoading = false;
      state.isGetAllShiftSuccess = false;
      state.getAllShiftErrorCode = errcode;
      state.getAllShiftMessage = error || '';
    });

    // ********************* End of all shifts action state handling. *********************

    // ********************* Start of all shift types action state handling. *********************
    // Get all shifts type default state.
    builder.addCase(getAllShiftTypes.pending, (state) => {
      state.isShiftTypesLoading = true;
      state.isShiftTypesSuccess = false;
      state.shiftTypesErrorCode = 0;
      state.shiftTypesMessage = '';
    });
    // Get all shifts type success state.
    builder.addCase(getAllShiftTypes.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isShiftTypesLoading = false;
      state.isShiftTypesSuccess = true;
      state.shiftTypes = data || null;
      state.shiftTypesErrorCode = code;
      state.shiftTypesMessage = status || '';
    });
    // Get all shifts type error state.
    builder.addCase(getAllShiftTypes.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isShiftTypesLoading = false;
      state.isShiftTypesSuccess = false;
      state.shiftTypesErrorCode = errcode;
      state.shiftTypesMessage = error || '';
    });

    // ********************* End of all shift types action state handling. *********************

    // ********************* Start of assign CA from a shift action state handling. *********************
    // Assign CA to a shift default state.
    builder.addCase(assignCA.pending, (state) => {
      state.isAssignShiftLoading = true;
      state.isAssignShiftSuccess = false;
      state.assignShiftErrorCode = 0;
      state.assignShiftMessage = '';
    });
    //  Assign CA to a shift success state.
    builder.addCase(assignCA.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isAssignShiftLoading = false;
      state.isAssignShiftSuccess = true;
      state.assignShiftErrorCode = code;
      state.assignShiftMessage = status || '';
    });
    // Assign CA to a shift error state.
    builder.addCase(assignCA.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isAssignShiftLoading = false;
      state.isAssignShiftSuccess = false;
      state.assignShiftErrorCode = errcode;
      state.assignShiftMessage = error || '';
    });
    // ********************* End of assign CA to a shift action state handling. *********************

    // ********************* Start of unassign CA from a shift action state handling. *********************
    // Unassign CA from a shift default state.
    builder.addCase(unassignCA.pending, (state) => {
      state.isUnassignShiftLoading = true;
      state.isUnassignShiftSuccess = false;
      state.unassignShiftErrorCode = 0;
      state.unassignShiftMessage = '';
    });
    //  Unassign CA from a shift success state.
    builder.addCase(unassignCA.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUnassignShiftLoading = false;
      state.isUnassignShiftSuccess = true;
      state.unassignShiftErrorCode = code;
      state.unassignShiftMessage = status || '';
    });
    // Unassign CA from a shift error state.
    builder.addCase(unassignCA.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUnassignShiftLoading = false;
      state.isUnassignShiftSuccess = false;
      state.unassignShiftErrorCode = errcode;
      state.unassignShiftMessage = error || '';
    });
    // ********************* End of unassign CA from a shift action state handling. *********************
    // ********************* Start of fetch shift details action state handling. *********************
    // Get my shift and leave details default state.
    builder.addCase(getShiftDetails.pending, (state) => {
      state.isShiftDetailsLoading = true;
      state.isShiftDetailsSuccess = false;
      state.shiftDetailsErrorCode = 0;
      state.shiftDetailsMessage = '';
    });
    //  Get my shift and leave details success state.
    builder.addCase(getShiftDetails.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isShiftDetailsLoading = false;
      state.isShiftDetailsSuccess = true;
      state.shiftDetails = info;
      state.shiftDetailsErrorCode = code;
      state.shiftDetailsMessage = status || '';
    });
    //  Get my shift and leave details error state.
    builder.addCase(getShiftDetails.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isShiftDetailsLoading = false;
      state.isShiftDetailsSuccess = false;
      state.shiftDetailsErrorCode = errcode;
      state.shiftDetailsMessage = error || '';
    });

    // ********************* End of fetch shift details action state handling. *********************

    // ********************* Start of callOut shift action state handling. *********************
    // Call out shift default state.
    builder.addCase(callOutShift.pending, (state) => {
      state.isCallOutShiftLoading = true;
      state.isCallOutShiftSuccess = false;
      state.callOutShiftErrorCode = 0;
      state.callOutShiftMessage = '';
    });
    // Call out shift success state.
    builder.addCase(callOutShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isCallOutShiftLoading = false;
      state.isCallOutShiftSuccess = true;
      state.callOutShiftErrorCode = code;
      state.callOutShiftMessage = status || '';
    });
    //  Call out shift error state.
    builder.addCase(callOutShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isCallOutShiftLoading = false;
      state.isCallOutShiftSuccess = false;
      state.callOutShiftErrorCode = errcode;
      state.callOutShiftMessage = error || '';
    });
    // ********************* End of callOut shift action state handling. *********************

    // ********************* Start of user shifts action state handling. *********************
    // Get user shifts default state.
    builder.addCase(getUserShifts.pending, (state) => {
      state.isGetAllUserShiftLoading = true;
      state.isGetAllUserShiftSuccess = false;
      state.getAllUserShiftErrorCode = 0;
      state.getAllUserShiftMessage = '';
    });
    // Get user shifts success state.
    builder.addCase(getUserShifts.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isGetAllUserShiftLoading = false;
      state.isGetAllUserShiftSuccess = true;
      state.userShiftsList = data || null;
      state.getAllUserShiftErrorCode = code;
      state.getAllUserShiftMessage = status || '';
    });
    // Get user shifts error state.
    builder.addCase(getUserShifts.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isGetAllUserShiftLoading = false;
      state.isGetAllUserShiftSuccess = false;
      state.getAllUserShiftErrorCode = errcode;
      state.getAllUserShiftMessage = error || '';
    });

    // ********************* End of user shifts action state handling. *********************

    // ********************* Clear all schedule states to initial state action. *********************
    builder.addCase(revertAllScheduleState, (state) => {
      state.isGetAllShiftSuccess = false;
      state.isGetAllShiftLoading = false;
      state.getAllShiftErrorCode = 0;
      state.getAllShiftMessage = null;
      state.isShiftTypesSuccess = false;
      state.isShiftTypesLoading = false;
      state.shiftTypesErrorCode = 0;
      state.shiftTypesMessage = null;
      state.isAssignShiftSuccess = false;
      state.isAssignShiftLoading = false;
      state.assignShiftErrorCode = 0;
      state.assignShiftMessage = null;
      state.isUnassignShiftSuccess = false;
      state.isUnassignShiftLoading = false;
      state.unassignShiftErrorCode = 0;
      state.unassignShiftMessage = null;
      state.isShiftDetailsSuccess = false;
      state.isShiftDetailsLoading = false;
      state.shiftDetailsErrorCode = 0;
      state.shiftDetailsMessage = null;
      state.isCallOutShiftSuccess = false;
      state.isCallOutShiftLoading = false;
      state.callOutShiftErrorCode = 0;
      state.callOutShiftMessage = null;
      state.isGetAllUserShiftSuccess = false;
      state.isGetAllUserShiftLoading = false;
      state.getAllUserShiftErrorCode = 0;
      state.getAllUserShiftMessage = null;
    });
  },
});

// Export manage shift actions.
export const { resetShiftsDefaultState } = manageScheduleSlice.actions;

// Export reducer.
export default manageScheduleSlice.reducer;
