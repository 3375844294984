import React from 'react';

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.222" height="17" viewBox="0 0 15.222 17">
      <g id="user" transform="translate(-3.5 -2.5)">
        <path
          id="Path_10376"
          data-name="Path 10376"
          d="M18.222,20.258V18.505A3.531,3.531,0,0,0,14.667,15H7.556A3.531,3.531,0,0,0,4,18.505v1.753"
          transform="translate(0 -1.258)"
          fill="none"
          stroke="#400286"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          id="Ellipse_110"
          data-name="Ellipse 110"
          cx="3.601"
          cy="3.5"
          rx="3.601"
          ry="3.5"
          transform="translate(7.021 3)"
          fill="none"
          stroke="#400286"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
