import React from 'react';

const TrackLDIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
      <g id="Group_16252" data-name="Group 16252" transform="translate(-7336 1407)">
        <path
          id="Rectangle_565"
          data-name="Rectangle 565"
          d="M4.5,1A3.5,3.5,0,0,0,1,4.5v18a3.5,3.5,0,0,0,7,0V4.5A3.5,3.5,0,0,0,4.5,1m0-1A4.5,4.5,0,0,1,9,4.5v18a4.5,4.5,0,0,1-9,0V4.5A4.5,4.5,0,0,1,4.5,0Z"
          transform="translate(7345 -1407)"
          fill="#1b1658"
        />
        <path
          id="Rectangle_566"
          data-name="Rectangle 566"
          d="M4.5,1A3.5,3.5,0,0,0,1,4.5v18a3.5,3.5,0,0,0,7,0V4.5A3.5,3.5,0,0,0,4.5,1m0-1A4.5,4.5,0,0,1,9,4.5v18a4.5,4.5,0,0,1-9,0V4.5A4.5,4.5,0,0,1,4.5,0Z"
          transform="translate(7363 -1398) rotate(90)"
          fill="#1b1658"
        />
        <rect id="Rectangle_567" data-name="Rectangle 567" width="5" height="1" transform="translate(7347 -1394)" fill="#1b1658" />
        <rect id="Rectangle_571" data-name="Rectangle 571" width="5" height="1" transform="translate(7347 -1396)" fill="#1b1658" />
        <rect id="Rectangle_569" data-name="Rectangle 569" width="5" height="1" transform="translate(7347 -1392)" fill="#1b1658" />
      </g>
    </svg>
  );
};

export default TrackLDIcon;
