/**
 * @file   src\containers\CA\ManageSwap\SwapFilter.tsx
 * @brief  Component for swap my requests tab data.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState } from 'react';
import { Col, Row, Button, Filter } from '../../../components/ThirdPartyComponents';
import KKISearch from '../../../components/KKISearch';
import Strings from '../../../assests/strings/Strings.json';
import Close from '../../../assests/icons/Close';
import KKISelect from '../../../components/KKISelect';
import { IObject, ISwapFilter } from '../../../interfaces/generalInterface';
import { ALL_TEXT, NUMBER_0 } from '../../../utils/constants';
import { SwapStatus } from '../../../utils/enums';

const SwapFilter = ({ onApplyFilter, onResetFilter, onSearch }: ISwapFilter) => {
  // Initialize component state variables.
  const [statusFilterValue, setStatusFilterValue] = useState<IObject | null>({ label: ALL_TEXT, value: NUMBER_0.toString() });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  // Status select change event handler.
  const onStatusFilterChange = async (val: IObject) => {
    setStatusFilterValue(val);
  };

  // Apply filter button click event handler
  const handleApplyFilter = () => {
    setIsFilterOpen(false);
    if (onApplyFilter) {
      onApplyFilter(statusFilterValue);
    }
  };

  // Reset filter button click event handler
  const handleResetFilter = () => {
    setStatusFilterValue({ label: ALL_TEXT, value: NUMBER_0.toString() });
    if (onResetFilter) {
      onResetFilter();
    }
  };

  // filter change handler
  const handleFilterChange = (state: { isOpen: boolean }) => {
    setIsFilterOpen(state.isOpen);
  };

  // Handle search input change
  const handleSearch = (query: string) => {
    if (onSearch) onSearch(query);
  };

  const status = [
    { label: ALL_TEXT, value: NUMBER_0.toString() },
    { label: SwapStatus[SwapStatus.Pending_Partner_Approval].replace(/_/g, ' '), value: SwapStatus.Pending_Partner_Approval.toString() },
    { label: SwapStatus[SwapStatus.Partner_Declined].replace(/_/g, ' '), value: SwapStatus.Partner_Declined.toString() },
    { label: SwapStatus[SwapStatus.Pending_Supervisor_Approval].replace(/_/g, ' '), value: SwapStatus.Pending_Supervisor_Approval.toString() },
    { label: SwapStatus[SwapStatus.Supervisor_Approved].replace(/_/g, ' '), value: SwapStatus.Supervisor_Approved.toString() },
    { label: SwapStatus[SwapStatus.Supervisor_Declined].replace(/_/g, ' '), value: SwapStatus.Supervisor_Declined.toString() },
  ];

  return (
    <div className="filter-main row-filter">
      <Row className="d-flex justify-content-between">
        <Col xl={4} md={5} sm={12}>
          <Row className="align-items-center">
            <Col xs>
              <KKISearch placeholder={Strings.Input.SearchShiftType} onSearch={handleSearch} />
            </Col>
            <Col xs="auto" className="btn-container d-flex ps-0">
              <Filter
                width={310}
                right
                pageWrapId="filter-wrapper"
                outerContainerId="outer-container"
                isOpen={isFilterOpen}
                onStateChange={handleFilterChange}
                customCrossIcon={<Close />}
              >
                <div id="filter-wrapper" className="filter-overlay">
                  <div className="overlay-hd p-3 border-bottom">
                    <h1>{Strings.Filter.Filter}</h1>
                  </div>
                  <div className="overlay-filter-content">
                    <div>
                      <Col>
                        <KKISelect
                          id="Status"
                          label="Status"
                          name="Status"
                          className="custom-select"
                          placeholder={Strings.Sort.Select}
                          onSelectChange={onStatusFilterChange}
                          searchvalue={false}
                          options={status}
                          value={statusFilterValue}
                        />
                      </Col>
                    </div>
                  </div>

                  <Col className="overlay-button-container">
                    <Button variant="outline-primary" onClick={handleResetFilter}>
                      {Strings.Button.Reset}
                    </Button>
                    <Button variant="primary" onClick={handleApplyFilter}>
                      {Strings.Button.Apply}
                    </Button>
                  </Col>
                </div>
              </Filter>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default SwapFilter;
