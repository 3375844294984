/**
 * @file   src\components\KKIOtpInput.tsx
 * @brief  Reusable OTP input component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import OtpInput from 'react-otp-input';
import { IOtpInput } from '../interfaces/generalInterface';
import { allowIntegerOnly, isEmpty } from '../utils/helper';
import '../styles/KKIOTP.scss';

const KKIOtpInput = ({ onOtpChange, numInputs, otp, type, errorMessage }: IOtpInput) => {
  // Otp change event.
  const onChange = (e: string) => {
    if (onOtpChange) onOtpChange(e);
  };

  // Render otp input and properties.
  const renderInput = (inputProps: any) => {
    return <input {...inputProps} onKeyDown={allowIntegerOnly} />;
  };

  return (
    <div className="form-group">
      <div className="cust-otp-group">
        <OtpInput numInputs={numInputs} value={otp} onChange={onChange} shouldAutoFocus renderInput={renderInput} inputType={type || 'text'} />
        {!isEmpty(errorMessage || '') && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default KKIOtpInput;
