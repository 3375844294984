/**
 * @file   src\store\slices\authSlice.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { Iauth } from '../../interfaces/authInterface';
import { signUp, validateUser, validateAdminUser, adminSignUp, revertAllAuthState } from '../actions/authAction';
import { HTTP_STATUS_200 } from '../../utils/constants';

// Initial state.
const authState: Iauth = {
  userInfo: null,
  userId: 0,
  userToken: null,
  errorCode: 0,
  message: null,
  isSuccess: false,
  isLoading: false,
  isSignUpSucess: false,
  isSignUpLoading: false,
  signUpErrorCode: 0,
  signUpMessage: null,
  signUpInfo: null,
};

// Config auth slice.
export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    resetAuthState: () => authState,
  },
  extraReducers(builder) {
    // ********************* Start of validate user (Login) action state handling. *********************
    // Validate user default state.
    builder.addCase(validateUser.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.message = '';
    });
    // Validate user success state.
    builder.addCase(validateUser.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isLoading = false;
      state.isSuccess = code === HTTP_STATUS_200;
      state.userInfo = data;
      state.errorCode = code;
      state.message = status || '';
    });
    // Validate user error state.
    builder.addCase(validateUser.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = errcode;
      state.message = error || '';
    });
    // ********************* End of validate user (Login) action state handling. *********************

    // ********************* Start of user signup action state handling. *********************
    // User signup default state.
    builder.addCase(signUp.pending, (state) => {
      state.isSignUpSucess = false;
      state.isSignUpLoading = true;
      state.signUpErrorCode = 0;
      state.signUpMessage = '';
    });
    // User signup success state.
    builder.addCase(signUp.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isSignUpLoading = false;
      state.isSignUpSucess = code === HTTP_STATUS_200;
      state.signUpInfo = data;
      state.signUpErrorCode = code;
      state.signUpMessage = status || '';
    });
    // User signup error state.
    builder.addCase(signUp.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isSignUpSucess = false;
      state.isSignUpLoading = false;
      state.signUpErrorCode = errcode;
      state.signUpMessage = error || '';
    });
    // ********************* End of user signup action state handling. *********************

    // ********************* Start of validate admin users(Scheduler/Manager) action state handling. *********************
    // Validate users(Scheduler/Manager) default state.
    builder.addCase(validateAdminUser.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.message = '';
    });
    // Validate admin users(Scheduler/Manager) success state.
    builder.addCase(validateAdminUser.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.userInfo = data;
      state.errorCode = code;
      state.message = status || '';
    });
    // Validate admin users(Scheduler/Manager) error state.
    builder.addCase(validateAdminUser.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = errcode;
      state.message = error || '';
    });
    // ********************* End of validate admin users(Scheduler/Manager) action state handling. *********************

    // ********************* Start of admin user signup action state handling. *********************
    // Admin user signup default state.
    builder.addCase(adminSignUp.pending, (state) => {
      state.isSignUpSucess = false;
      state.isSignUpLoading = true;
      state.signUpErrorCode = 0;
      state.signUpMessage = '';
    });
    // Admin user signup success state.
    builder.addCase(adminSignUp.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isSignUpLoading = false;
      state.isSignUpSucess = true;
      state.signUpErrorCode = code;
      state.signUpInfo = info;
      state.signUpMessage = status || '';
    });
    // Admin user signup error state.
    builder.addCase(adminSignUp.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isSignUpSucess = false;
      state.isSignUpLoading = false;
      state.signUpErrorCode = errcode;
      state.signUpMessage = error || '';
    });
    // ********************* End of admin user signup action state handling. *********************

    // ********************* Clear all common states to initial sate action. *********************
    builder.addCase(revertAllAuthState, () => authState);
  },
});
// Export auth actions.
export const { resetAuthState } = authSlice.actions;
// Export reducer.
export default authSlice.reducer;
