/**
 * @file   src\containers\Scheduler\ManageHolidays\Holidays.tsx
 * @brief  Holiday list page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Modal, Button, Col, moment, useState, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import HolidayCard from '../../../components/commonComponents/HolidayCard';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKIInput from '../../../components/KKIInput';
import { IAddHolidayRequest, IGetAllHolidayResponse } from '../../../interfaces/holidaysInterface';
import { DATE_FORMAT_MMMM_D_YYYY_DDD, HTTP_STATUS_200, DATE_FORMAT_YYYY_MM_DD, NUMBER_50 } from '../../../utils/constants';
import { IObject } from '../../../interfaces/generalInterface';
import { ADD_HOLIDAY_SCHEMA } from '../../../validations/manageHolidaySchema';
import { validateForm } from '../../../utils/validationHelper';
import { MessageToaster } from '../../../utils/toastUtils';
import { getAllHolidayList, addHoliday, revertAllHolidayState, deleteHoliday, editHoliday } from '../../../store/actions/manageHolidayAction';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const SCHHolidays = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { holidayList, isHolidayListLoading } = useAppSelector((state: RootState) => state.manageHoliday);
  const { isAddNewHolidaySuccess, addNewHolidayErrorCode, addNewHolidayMessage } = useAppSelector((state: RootState) => state.manageHoliday);
  const { isDeleteHolidaySuccess, deleteHolidayRequestErrorCode, deleteHolidayRequestMessage } = useAppSelector((state: RootState) => state.manageHoliday);
  const { isEditHolidaySuccess, editHolidayErrorCode, editHolidayMessage } = useAppSelector((state: RootState) => state.manageHoliday);

  // Initialize component state variables.
  const [show, setShow] = useState(false);
  const [dateTime, setDateTime] = useState<Date | null>(null);
  const [holiday, setHoliday] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [holidayIdToDelete, setHolidayIdToDelete] = useState<number | null>(null);
  const [editingHolidayId, setEditingHolidayId] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllHolidayState());
    };
  }, []);

  // Fetch all holiday list
  useEffect(() => {
    dispatch(getAllHolidayList());
  }, [dispatch]);

  // Add holiday api response state side effect handling.
  useEffect(() => {
    if (isAddNewHolidaySuccess && addNewHolidayErrorCode === HTTP_STATUS_200 && addNewHolidayMessage) {
      toast.toastSuccess(addNewHolidayMessage);
      dispatch(getAllHolidayList());
    } else if (!isAddNewHolidaySuccess && addNewHolidayErrorCode > HTTP_STATUS_200 && addNewHolidayMessage) {
      toast.toastError(addNewHolidayMessage);
    }
  }, [isAddNewHolidaySuccess, addNewHolidayMessage, addNewHolidayErrorCode, dispatch]);

  // Edit holiday api response state side effect handling.
  useEffect(() => {
    if (isEditHolidaySuccess && editHolidayErrorCode === HTTP_STATUS_200 && editHolidayMessage) {
      toast.toastSuccess(editHolidayMessage);
      dispatch(getAllHolidayList());
      setIsEditing(false);
      setEditingHolidayId(null);
    } else if (!isEditHolidaySuccess && editHolidayErrorCode > HTTP_STATUS_200 && editHolidayMessage) {
      toast.toastError(editHolidayMessage);
    }
  }, [isEditHolidaySuccess, editHolidayMessage, editHolidayErrorCode, dispatch]);

  // Delete holiday api response state side effect handling.
  useEffect(() => {
    if (isDeleteHolidaySuccess && deleteHolidayRequestErrorCode === HTTP_STATUS_200 && deleteHolidayRequestMessage) {
      toast.toastSuccess(deleteHolidayRequestMessage);
      dispatch(getAllHolidayList());
    } else if (!isDeleteHolidaySuccess && deleteHolidayRequestErrorCode > HTTP_STATUS_200 && deleteHolidayRequestMessage) {
      toast.toastError(deleteHolidayRequestMessage);
    }
  }, [isDeleteHolidaySuccess, deleteHolidayRequestMessage, deleteHolidayRequestErrorCode, dispatch]);

  // Closes the modal and resets editing state
  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setEditingHolidayId(null);
  };

  // Reset or set fields based on whether it's adding or editing holiday
  const handleShow = (holidayItem?: IGetAllHolidayResponse) => {
    if (holidayItem) {
      setIsEditing(true);
      setEditingHolidayId(holidayItem.holidayId);
      setDateTime(moment(holidayItem.dateTime).toDate());
      setHoliday(holidayItem.holiday);
    } else {
      setIsEditing(false);
      setEditingHolidayId(null);
      setDateTime(null);
      setHoliday('');
      setErrorFields(null);
    }
    setShow(true);
  };

  //  Handle date selection change.
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDateTime(date);
    }
  };

  // Handle holiday name input change.
  const handleHolidayChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setHoliday(evt.target.value);
    const holidayRequest = {
      holiday: value,
    };

    const errorResult = await validateForm(holidayRequest, ADD_HOLIDAY_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };

  // Handle delete confirmation modal
  const handleShowDeleteModal = (holidayId: number) => {
    setHolidayIdToDelete(holidayId);
    setShowDeleteModal(true);
  };
  // Closes the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setHolidayIdToDelete(null);
  };

  // Confirms the deletion of the holiday
  const handleConfirmDeleteHolidayChange = () => {
    if (holidayIdToDelete) {
      dispatch(deleteHoliday(holidayIdToDelete));
      handleCloseDeleteModal();
    }
  };
  // Handle holiday add/edit submit action
  const onSubmit = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();

    const formattedDate = dateTime ? moment(dateTime).format(DATE_FORMAT_YYYY_MM_DD) : '';
    const holidayRequest: IAddHolidayRequest = {
      holidayId: isEditing && editingHolidayId !== null ? editingHolidayId : undefined,
      dateTime: formattedDate,
      holiday,
    };

    const errorResult = await validateForm(holidayRequest, ADD_HOLIDAY_SCHEMA, errorFields);
    setErrorFields(errorResult);

    if (Object.keys(errorResult).length === 0) {
      if (isEditing && editingHolidayId !== null) {
        dispatch(editHoliday(holidayRequest));
      } else {
        dispatch(addHoliday(holidayRequest));
      }
      handleClose();
    }
  };

  return (
    <div>
      <div className="pageheader d-flex align-items-center justify-content-between">
        <h1>{Strings.Menu.ManageHolidays}</h1>
        <Button variant="primary" onClick={() => handleShow()}>
          {Strings.Button.AddHoliday}
        </Button>
      </div>

      <ol className="ps-0">
        {holidayList.length > 0 ? (
          holidayList.map((holidayItem: IGetAllHolidayResponse) => (
            <HolidayCard
              key={holidayItem.holidayId}
              date={moment(holidayItem.dateTime).format(DATE_FORMAT_MMMM_D_YYYY_DDD)}
              holidayName={holidayItem.holiday}
              onDelete={() => handleShowDeleteModal(holidayItem.holidayId)}
              onEdit={() => handleShow(holidayItem)}
            />
          ))
        ) : (
          <p>{Strings.Text.NoDataFound}</p>
        )}
      </ol>
      {isHolidayListLoading && <Loader />}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? Strings.HD.EditHolidayDetails : Strings.HD.AddHolidayDetails}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="mb-3">
            <KKIDatepicker
              id="kkidatepicker"
              name="kkidatepicker"
              placeholder={Strings.Label.Date}
              label={Strings.Label.Date}
              value={dateTime}
              dateFormat="dd MMM yyyy"
              alert={errorFields?.dateTime || ''}
              onChangeDatepicker={handleDateChange}
              minDate={moment().toDate()}
            />
          </Col>
          <Col>
            <KKIInput
              id="Holiday"
              label={Strings.Label.Holiday}
              name="Holiday"
              type="text"
              value={holiday}
              alert={errorFields?.holiday || ''}
              onChange={handleHolidayChange}
              maxLength={NUMBER_50}
            />
          </Col>
          <Col className="button-container-right text-end mt-4">
            <Button variant="outline-primary" onClick={handleClose}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Holiday.DeleteMessage}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={handleCloseDeleteModal}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={handleConfirmDeleteHolidayChange} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SCHHolidays;
