/**
 * @file   src\store\slices\manageSwapSlice.ts
 * @brief  This file is responsible for creating Clinical assistants(CA) schedule swap management slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getAlertMessage } from '../../utils/helper';
import { IManageSwap } from '../../interfaces/swapInterface';
import {
  approveSwapRequestByAdmin,
  declineSwapRequestByAdmin,
  deleteSwapReuest,
  getCASwapRequestByAdmin,
  getDeclineSwapReasons,
  getSwapRequests,
  resendSwapRequest,
  revertAllSwapState,
  swapShift,
  updateSwapRequestStatus,
  updateSwapRequestStatusByAdmin,
} from '../actions/manageSwapAction';
import { SwapRequestStatus } from '../../utils/enums';

// Initial state.
const manageSwapState: IManageSwap = {
  isSwapSuccess: false,
  isSwapLoading: false,
  swapErrorCode: 0,
  swapMessage: null,
  isFetchSwapRequestSuccess: false,
  isFetchSwapRequestLoading: false,
  fetchSwapRequestErrorCode: 0,
  fetchSwapRequestMessage: null,
  swapRequests: [],
  isDeleteSwapRequestSuccess: false,
  isDeleteSwapRequestLoading: false,
  deleteSwapRequestErrorCode: 0,
  deleteSwapRequestMessage: null,
  isSwapRequestStatusUpdateSuccess: false,
  isSwapRequestStatusUpdateLoading: false,
  swapRequestStatusUpdateErrorCode: 0,
  swapRequestStatusUpdateMessage: null,
  isResendSwapRequestSuccess: false,
  isResendSwapRequestLoading: false,
  resendSwapRequestErrorCode: 0,
  resendSwapRequestMessage: null,
  isFetchSwapRequestByAdminSuccess: false,
  isFetchSwapRequestByAdminLoading: false,
  fetchSwapRequestByAdminErrorCode: 0,
  fetchSwapRequestByAdminMessage: null,
  isUpdateSwapStatusByAdminSuccess: false,
  isUpdateSwapStatusByAdminLoading: false,
  updateSwapStatusByAdminErrorCode: 0,
  updateSwapStatusByAdminMessage: null,
  isApproveSwapByAdminSuccess: false,
  isApproveSwapByAdminLoading: false,
  approveSwapByAdminErrorCode: 0,
  approveSwapByAdminMessage: null,
  isDeclineSwapByAdminSuccess: false,
  isDeclineSwapByAdminLoading: false,
  declineSwapByAdminErrorCode: 0,
  declineSwapByAdminMessage: null,
  isDeclineSwapReasonsLoading: false,
  isDeclineSwapReasonsSuccess: false,
  declineSwapReasonsErrorCode: 0,
  declineSwapReasonsMessage: null,
  declineSwapReasons: [],
};

// Config schedule swap management slice.
export const manageSwapSlice = createSlice({
  name: 'manageSwap',
  initialState: manageSwapState,
  reducers: {
    resetManageSwapState: () => manageSwapState,
  },
  extraReducers(builder) {
    // ********************* Start of shift swap action state handling. *********************
    // Swap shift default state.
    builder.addCase(swapShift.pending, (state) => {
      state.isSwapSuccess = false;
      state.isSwapLoading = true;
      state.swapErrorCode = 0;
      state.swapMessage = '';
    });
    // Swap shift success state.
    builder.addCase(swapShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isSwapLoading = false;
      state.isSwapSuccess = true;
      state.swapErrorCode = code;
      state.swapMessage = status || '';
    });
    // Swap shift error state.
    builder.addCase(swapShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isSwapSuccess = false;
      state.isSwapLoading = false;
      state.swapErrorCode = errcode;
      state.swapMessage = error || '';
    });
    // ********************* End of shift swap action state handling. *********************

    // ********************* Start of get swap request action state handling. *********************
    // Get swap requests default state.
    builder.addCase(getSwapRequests.pending, (state) => {
      state.isFetchSwapRequestSuccess = false;
      state.isFetchSwapRequestLoading = true;
      state.fetchSwapRequestErrorCode = 0;
      state.fetchSwapRequestMessage = '';
    });
    // Get swap requests success state.
    builder.addCase(getSwapRequests.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isFetchSwapRequestLoading = false;
      state.isFetchSwapRequestSuccess = true;
      state.swapRequests = info || [];
      state.fetchSwapRequestErrorCode = code;
      state.fetchSwapRequestMessage = status || '';
    });
    // Get swap requests error state.
    builder.addCase(getSwapRequests.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchSwapRequestSuccess = false;
      state.isFetchSwapRequestLoading = false;
      state.fetchSwapRequestErrorCode = errcode;
      state.fetchSwapRequestMessage = error || '';
    });
    // ********************* End of get swap request action state handling. *********************

    // ********************* Start of delete swap request action state handling. *********************
    // Delete swap request default state.
    builder.addCase(deleteSwapReuest.pending, (state) => {
      state.isDeleteSwapRequestSuccess = false;
      state.isDeleteSwapRequestLoading = true;
      state.deleteSwapRequestErrorCode = 0;
      state.deleteSwapRequestMessage = '';
    });
    // Delete swap request success state.
    builder.addCase(deleteSwapReuest.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeleteSwapRequestLoading = false;
      state.isDeleteSwapRequestSuccess = true;
      state.deleteSwapRequestErrorCode = code;
      state.deleteSwapRequestMessage = status || '';
    });
    // Delete swap request error state.
    builder.addCase(deleteSwapReuest.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeleteSwapRequestSuccess = false;
      state.isSwapLoading = false;
      state.deleteSwapRequestErrorCode = errcode;
      state.deleteSwapRequestMessage = error || '';
    });
    // ********************* End of delete swap request action state handling. *********************

    // ********************* Start of update swap request status action state handling. *********************
    // Swap shift default state.
    builder.addCase(updateSwapRequestStatus.pending, (state) => {
      state.isSwapRequestStatusUpdateSuccess = false;
      state.isSwapRequestStatusUpdateLoading = true;
      state.swapRequestStatusUpdateErrorCode = 0;
      state.swapRequestStatusUpdateMessage = '';
    });
    // Swap shift success state.
    builder.addCase(updateSwapRequestStatus.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isSwapRequestStatusUpdateLoading = false;
      state.isSwapRequestStatusUpdateSuccess = true;
      state.swapRequestStatusUpdateErrorCode = code;
      state.swapRequestStatusUpdateMessage = status || '';
    });
    // Swap shift error state.
    builder.addCase(updateSwapRequestStatus.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isSwapRequestStatusUpdateSuccess = false;
      state.isSwapRequestStatusUpdateLoading = false;
      state.swapRequestStatusUpdateErrorCode = errcode;
      state.swapRequestStatusUpdateMessage = error || '';
    });
    // ********************* End of update swap request status action state handling. *********************

    // ********************* Start of resend swap request action state handling. *********************
    // Resend swap request default state.
    builder.addCase(resendSwapRequest.pending, (state) => {
      state.isResendSwapRequestSuccess = false;
      state.isResendSwapRequestLoading = true;
      state.resendSwapRequestErrorCode = 0;
      state.resendSwapRequestMessage = '';
    });
    // Resend swap request success state.
    builder.addCase(resendSwapRequest.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isResendSwapRequestLoading = false;
      state.isResendSwapRequestSuccess = true;
      state.resendSwapRequestErrorCode = code;
      state.resendSwapRequestMessage = status || '';
    });
    // Resend swap request error state.
    builder.addCase(resendSwapRequest.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isResendSwapRequestSuccess = false;
      state.isResendSwapRequestLoading = false;
      state.resendSwapRequestErrorCode = errcode;
      state.resendSwapRequestMessage = error || '';
    });
    // ********************* End of resend swap request action state handling. *********************

    // ********************* Start of getting CA swap requests by admin action state handling. *********************
    // Get CA swap requests by admin default state.
    builder.addCase(getCASwapRequestByAdmin.pending, (state) => {
      state.isFetchSwapRequestByAdminSuccess = false;
      state.isFetchSwapRequestByAdminLoading = true;
      state.fetchSwapRequestByAdminErrorCode = 0;
      state.fetchSwapRequestByAdminMessage = '';
    });
    // Get CA swap requests by admin success state.
    builder.addCase(getCASwapRequestByAdmin.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isFetchSwapRequestByAdminLoading = false;
      state.isFetchSwapRequestByAdminSuccess = true;
      state.swapRequests = info?.swapRequestsList || [];
      state.fetchSwapRequestByAdminErrorCode = code;
      state.fetchSwapRequestByAdminMessage = status || '';
    });
    // Get CA swap requests by admin error state.
    builder.addCase(getCASwapRequestByAdmin.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchSwapRequestByAdminSuccess = false;
      state.isFetchSwapRequestByAdminLoading = false;
      state.fetchSwapRequestByAdminErrorCode = errcode;
      state.fetchSwapRequestByAdminMessage = error || '';
    });
    // ********************* End of getting CA swap requests by admin action state handling. *********************

    // ********************* Start of update(Approve/decline) CA swap request by admin action state handling. *********************
    // Update(Approve/decline) CA swap request by admin default state.
    builder.addCase(updateSwapRequestStatusByAdmin.pending, (state) => {
      state.isUpdateSwapStatusByAdminSuccess = false;
      state.isUpdateSwapStatusByAdminLoading = true;
      state.updateSwapStatusByAdminErrorCode = 0;
      state.updateSwapStatusByAdminMessage = '';
    });
    // Update(Approve/decline) CA swap request by admin success state.
    builder.addCase(updateSwapRequestStatusByAdmin.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.isUpdateSwapStatusByAdminLoading = error === 0;
      state.isUpdateSwapStatusByAdminSuccess = true;
      state.updateSwapStatusByAdminErrorCode = error;
      state.updateSwapStatusByAdminMessage = getAlertMessage(
        error !== 0 ? error.toString() : meta.arg.status === SwapRequestStatus.Accepted ? 'SWAP_REQUEST_ACCEPETED_SUCCESSFULLY' : 'SWAP_REQUEST_DECLINED_SUCCESSFULLY',
      );
    });
    // Update(Approve/decline) CA swap request by admin error state.
    builder.addCase(updateSwapRequestStatusByAdmin.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isUpdateSwapStatusByAdminSuccess = false;
      state.isUpdateSwapStatusByAdminLoading = false;
      state.updateSwapStatusByAdminErrorCode = error;
      state.updateSwapStatusByAdminMessage = getAlertMessage(error && error !== 0 ? error.toString() : '');
    });
    // ********************* End of update(Approve/decline) CA swap request by admin action state handling. *********************

    // ********************* Start of approve swap request action state handling. *********************
    // Approve swap request default state.
    builder.addCase(approveSwapRequestByAdmin.pending, (state) => {
      state.isApproveSwapByAdminSuccess = false;
      state.isApproveSwapByAdminLoading = true;
      state.approveSwapByAdminErrorCode = 0;
      state.approveSwapByAdminMessage = '';
    });
    // Approve swap request success state.
    builder.addCase(approveSwapRequestByAdmin.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isApproveSwapByAdminLoading = false;
      state.isApproveSwapByAdminSuccess = true;
      state.approveSwapByAdminErrorCode = code;
      state.approveSwapByAdminMessage = status || '';
    });
    // Approve swap request error state.
    builder.addCase(approveSwapRequestByAdmin.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isApproveSwapByAdminSuccess = false;
      state.isApproveSwapByAdminLoading = false;
      state.approveSwapByAdminErrorCode = errcode;
      state.approveSwapByAdminMessage = error || '';
    });
    // ********************* End of approve swap request action state handling. *********************

    // ********************* Start of decline swap request action state handling. *********************
    // Decline swap request default state.
    builder.addCase(declineSwapRequestByAdmin.pending, (state) => {
      state.isDeclineSwapByAdminSuccess = false;
      state.isDeclineSwapByAdminLoading = true;
      state.declineSwapByAdminErrorCode = 0;
      state.declineSwapByAdminMessage = '';
    });
    // Decline swap request success state.
    builder.addCase(declineSwapRequestByAdmin.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeclineSwapByAdminLoading = false;
      state.isDeclineSwapByAdminSuccess = true;
      state.declineSwapByAdminErrorCode = code;
      state.declineSwapByAdminMessage = status || '';
    });
    // Decline swap request error state.
    builder.addCase(declineSwapRequestByAdmin.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeclineSwapByAdminSuccess = false;
      state.isDeclineSwapByAdminLoading = false;
      state.declineSwapByAdminErrorCode = errcode;
      state.declineSwapByAdminMessage = error || '';
    });
    // ********************* End of decline swap request action state handling. *********************

    // ********************* Start of fetching decline swap reasons action state handling. *********************
    // Get all swap decline reasons default state.
    builder.addCase(getDeclineSwapReasons.pending, (state) => {
      state.isDeclineSwapReasonsSuccess = false;
      state.isDeclineSwapReasonsLoading = true;
      state.declineSwapReasonsErrorCode = 0;
      state.declineSwapReasonsMessage = '';
    });
    //  Get all swap decline reasons success state.
    builder.addCase(getDeclineSwapReasons.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isDeclineSwapReasonsLoading = false;
      state.isDeclineSwapReasonsSuccess = true;
      state.declineSwapReasonsErrorCode = code;
      state.declineSwapReasonsMessage = status || '';
      state.declineSwapReasons = info;
    });
    //  Get all swap decline reasons error state.
    builder.addCase(getDeclineSwapReasons.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeclineSwapReasonsSuccess = false;
      state.isDeclineSwapReasonsLoading = false;
      state.declineSwapReasonsErrorCode = errcode;
      state.declineSwapReasonsMessage = error || '';
    });
    // ********************* End of fetching decline swap reasons action state handling. *********************

    // ********************* Clear all swap states to initial sate action. *********************
    builder.addCase(revertAllSwapState, (state) => {
      state.isSwapSuccess = false;
      state.isSwapLoading = false;
      state.swapErrorCode = 0;
      state.swapMessage = null;
      state.isFetchSwapRequestSuccess = false;
      state.isFetchSwapRequestLoading = false;
      state.fetchSwapRequestErrorCode = 0;
      state.fetchSwapRequestMessage = null;
      state.isDeleteSwapRequestSuccess = false;
      state.isDeleteSwapRequestLoading = false;
      state.deleteSwapRequestErrorCode = 0;
      state.deleteSwapRequestMessage = null;
      state.isSwapRequestStatusUpdateSuccess = false;
      state.isSwapRequestStatusUpdateLoading = false;
      state.swapRequestStatusUpdateErrorCode = 0;
      state.swapRequestStatusUpdateMessage = null;
      state.isResendSwapRequestSuccess = false;
      state.isResendSwapRequestLoading = false;
      state.resendSwapRequestErrorCode = 0;
      state.resendSwapRequestMessage = null;
      state.isFetchSwapRequestByAdminSuccess = false;
      state.isFetchSwapRequestByAdminLoading = false;
      state.fetchSwapRequestByAdminErrorCode = 0;
      state.fetchSwapRequestByAdminMessage = null;
      state.isUpdateSwapStatusByAdminSuccess = false;
      state.isUpdateSwapStatusByAdminLoading = false;
      state.updateSwapStatusByAdminErrorCode = 0;
      state.updateSwapStatusByAdminMessage = null;
      state.isApproveSwapByAdminSuccess = false;
      state.isApproveSwapByAdminLoading = false;
      state.approveSwapByAdminErrorCode = 0;
      state.approveSwapByAdminMessage = null;
      state.isDeclineSwapByAdminSuccess = false;
      state.isDeclineSwapByAdminLoading = false;
      state.declineSwapByAdminErrorCode = 0;
      state.declineSwapByAdminMessage = null;
      state.isDeclineSwapReasonsLoading = false;
      state.isDeclineSwapReasonsSuccess = false;
      state.declineSwapReasonsErrorCode = 0;
      state.declineSwapReasonsMessage = null;
    });
  },
});
// Export manage swap actions.
export const { resetManageSwapState } = manageSwapSlice.actions;
// Export reducer.
export default manageSwapSlice.reducer;
