/**
 * @file   src\components\commonComponents\ShiftTypes.tsx
 * @brief  Component for listing shift types.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { useEffect, useState, Filter, Button, Col } from '../ThirdPartyComponents';
import { IShiftTypeLists } from '../../interfaces/generalInterface';
import KKICheckbox from '../KKICheckbox';
import Close from '../../assests/icons/Close';
import Strings from '../../assests/strings/Strings.json';
import { NUMBER_1, NUMBER_0 } from '../../utils/constants';
import { ShiftStatus } from '../../utils/enums';

const ShiftTypes = ({ selectedShiftTypeIds, selectedShiftStatus, leaveStatus, onChange, shiftTypes, isRosterView }: IShiftTypeLists) => {
  // Initialize component state variables.
  const [localSelectedShiftTypeIds, setLocalSelectedShiftTypeIds] = useState<number[]>([]);
  const [shiftValues, setShiftValues] = useState<any[]>([]);
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(selectedShiftStatus || [ShiftStatus.Published, ShiftStatus.Draft]);
  const [isFullScheduleFilterOpen, setIsFullScheduleFilterOpen] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [isLeaveSelected, setIsLeaveSelected] = useState<boolean>(leaveStatus !== false);
  // Shift type checkbox change event handler.
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    const numericId = Number(id); // Convert ID from string to number
    const updatedSelectedShiftTypes = localSelectedShiftTypeIds.includes(numericId)
      ? localSelectedShiftTypeIds.filter((shiftId) => shiftId !== numericId)
      : [...localSelectedShiftTypeIds, numericId];
    setLocalSelectedShiftTypeIds(updatedSelectedShiftTypes || []);
  };

  // Publish status checkbox change event handler.
  const handleStatusCheckboxChange = (statusId: number) => {
    setSelectedPublishStatus((prevStatus) => (prevStatus.includes(statusId) ? prevStatus.filter((status) => status !== statusId) : [...prevStatus, statusId]));
  };

  useEffect(() => {
    setShiftValues(shiftTypes);
  }, [shiftTypes]);

  useEffect(() => {
    if (!filtersApplied) {
      const allShiftIds = shiftValues.map((shift) => shift.shiftId);
      setLocalSelectedShiftTypeIds(selectedShiftTypeIds && selectedShiftTypeIds?.length === 0 ? allShiftIds : selectedShiftTypeIds);
    }
  }, [selectedShiftTypeIds, shiftValues, filtersApplied]);

  // Handle apply button click
  const applyFilters = () => {
    setIsFullScheduleFilterOpen(false);
    onChange({
      shiftTypeIds: localSelectedShiftTypeIds,
      publishStatus: selectedPublishStatus,
      isLeaveSelected,
    });
    setFiltersApplied(true);
  };

  // Reset filter button click.
  const resetFilters = () => {
    const allShiftIds = shiftValues.map((shift) => shift.shiftId);
    setLocalSelectedShiftTypeIds(allShiftIds);
    setSelectedPublishStatus([ShiftStatus.Published, ShiftStatus.Draft]);
    setIsLeaveSelected(true);
    setFiltersApplied(true);
  };

  // Full schedule filter change handler.
  const handleFullScheduleFilterChange = (state: { isOpen: boolean }) => {
    setIsFullScheduleFilterOpen(state.isOpen);
  };

  // Leave checkbox change event handler.
  const handleLeaveCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = evt.target;
    setIsLeaveSelected(checked);
  };
  return (
    <Filter
      width={310}
      right
      pageWrapId="filter-wrapper"
      outerContainerId="outer-container"
      isOpen={isFullScheduleFilterOpen}
      onStateChange={handleFullScheduleFilterChange}
      customCrossIcon={<Close />}
      bodyClassName="overflow-hidden"
    >
      <div id="filter-wrapper" className="filter-overlay">
        <div className="overlay-hd p-3 border-bottom">
          <h1>{Strings.Filter.Filter}</h1>
        </div>
        <div className="overlay-filter-content">
          <div className="mb-4">
            <h3>{Strings.Filter.PublishStatus}</h3>
            <KKICheckbox
              id="p1"
              key={1}
              name="publishStatus"
              checkBoxType="checkbox"
              label={Strings.Filter.Publish}
              checked={selectedPublishStatus.includes(NUMBER_1)}
              onChange={() => handleStatusCheckboxChange(NUMBER_1)}
            />
            <KKICheckbox
              id="p2"
              key={2}
              name="publishStatus"
              checkBoxType="checkbox"
              label={Strings.Filter.Draft}
              checked={selectedPublishStatus.includes(NUMBER_0)}
              onChange={() => handleStatusCheckboxChange(NUMBER_0)}
            />
          </div>
          <div>
            <h3>{Strings.Filter.ShiftType}</h3>
            {shiftTypes.map((shiftType) => (
              <KKICheckbox
                key={shiftType.shiftId}
                id={shiftType.shiftId.toString()}
                name="shiftType"
                checkBoxType="checkbox"
                label={`${shiftType?.shiftName} (${shiftType?.shiftShortName})`}
                checked={localSelectedShiftTypeIds.includes(shiftType.shiftId)}
                onChange={handleCheckboxChange}
              />
            ))}
            {isRosterView && (
              <KKICheckbox
                id="leave"
                key="leave-1"
                name="leave"
                checkBoxType="checkbox"
                label={Strings.Filter.Leave}
                checked={isLeaveSelected}
                onChange={handleLeaveCheckboxChange}
              />
            )}
          </div>
        </div>

        <Col className="overlay-button-container">
          <Button variant="outline-primary" onClick={resetFilters}>
            {Strings.Button.Reset}
          </Button>
          <Button variant="primary" onClick={applyFilters}>
            {Strings.Button.Apply}
          </Button>
        </Col>
      </div>
    </Filter>
  );
};
export default ShiftTypes;
