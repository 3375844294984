/**
 * @file   src\components\commonComponents\HolidayCard.tsx
 * @brief  Holiday list card.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Strings from '../../assests/strings/Strings.json';
import '../../styles/ShiftCard.scss';
import { Col, Row, Button } from '../ThirdPartyComponents';
import { IHolidayCard } from '../../interfaces/generalInterface';
import DeleteIcon from '../../assests/icons/Delete';
import EditIcon from '../../assests/icons/Edit';

const HolidayCard = ({ date, holidayName, onDelete, onEdit }: IHolidayCard) => {
  return (
    <div className="filter-main border ps-5 mb-3">
      <li className="ps-4">
        <Row className="holiday-main align-items-center">
          <Col>{date}</Col>
          <Col sm={12} md>
            {holidayName}
          </Col>
          <Col md="auto" sm={12} className="button-container m-sm-16">
            <Button variant="outline-secondary" className="delete" onClick={onDelete}>
              <DeleteIcon />
              {Strings.Button.Delete}
            </Button>
            <Button variant="outline-secondary" onClick={onEdit}>
              <EditIcon />
              {Strings.Button.Edit}
            </Button>
          </Col>
        </Row>
      </li>
    </div>
  );
};
export default HolidayCard;
