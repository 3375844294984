/**
 * @file   src\containers\Scheduler\ManageCallOut\ManageCallOutShift.tsx
 * @brief  Call-Out Shifts listing page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Filter, Button, moment, useState, Modal, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Close from '../../../assests/icons/Close';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKISelect from '../../../components/KKISelect';
import KKISearch from '../../../components/KKISearch';
import SwapCard from '../../../components/AdminSwapCard';
import { ALL_TEXT, DATE_FORMAT_YYYY_MM_DD, HTTP_STATUS_200, NUMBER_0, NUMBER_EMPTY, OTHER_REASON_LABEL } from '../../../utils/constants';
import { ISwapDeclineReasons, ISwapRequests } from '../../../interfaces/swapInterface';
import { MessageToaster } from '../../../utils/toastUtils';
import { IObject } from '../../../interfaces/generalInterface';
import { SwapStatusByAdmin } from '../../../utils/enums';
import KKIInput from '../../../components/KKIInput';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { approveSwapRequestByAdmin, declineSwapRequestByAdmin, getCASwapRequestByAdmin, getDeclineSwapReasons, revertAllSwapState } from '../../../store/actions/manageSwapAction';
import { validateForm } from '../../../utils/validationHelper';
import { SWAP_DECLINE_SCHEMA } from '../../../validations/manageSwapSchema';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const SCHManageCallOutShift = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    isFetchSwapRequestByAdminLoading,
    isFetchSwapRequestByAdminSuccess,
    fetchSwapRequestByAdminErrorCode,
    fetchSwapRequestByAdminMessage,
    swapRequests,
    isDeclineSwapReasonsSuccess,
    declineSwapReasonsErrorCode,
    declineSwapReasonsMessage,
    declineSwapReasons,
    isApproveSwapByAdminLoading,
    isApproveSwapByAdminSuccess,
    approveSwapByAdminErrorCode,
    approveSwapByAdminMessage,
    isDeclineSwapByAdminLoading,
    isDeclineSwapByAdminSuccess,
    declineSwapByAdminErrorCode,
    declineSwapByAdminMessage,
  } = useAppSelector((state: RootState) => state.manageSwap);

  // Initialize component stat variables.
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<IObject>({ label: ALL_TEXT, value: NUMBER_0.toString() });
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState<string>('');
  const [selectedSwapId, setSelectedSwapId] = useState<number>(NUMBER_0);
  const [swapReasonList, setSwapReasonList] = useState<IObject[]>([]);
  const [selectedReason, setSelectedReason] = useState<IObject | null>(null);
  const [showApproveConfirmPopup, setApproveConfirmPopup] = useState<boolean>(false);
  const [showDeclineConfirmPopup, setDeclineConfirmPopup] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    // Component unmount
    return () => {
      dispatch(revertAllSwapState());
    };
  }, []);

  // Method to fetch all swap request api call.
  const fetchAllSwapRequest = () => {
    const fromDate = startDate ? moment(startDate).format(DATE_FORMAT_YYYY_MM_DD) : '';
    const toDate = endDate ? moment(endDate).format(DATE_FORMAT_YYYY_MM_DD) : '';
    dispatch(getCASwapRequestByAdmin({ searchText, shiftStartDate: fromDate, shiftEndDate: toDate, status: selectedStatus?.value ? Number(selectedStatus?.value) : NUMBER_0 }));
  };

  // Fetch all swap requests when Search text state change.
  useEffect(() => {
    fetchAllSwapRequest();
  }, [searchText]);

  // Fetch swap request api response state change.
  useEffect(() => {
    if (!isFetchSwapRequestByAdminSuccess && fetchSwapRequestByAdminErrorCode > HTTP_STATUS_200 && fetchSwapRequestByAdminMessage) {
      toast.toastError(fetchSwapRequestByAdminMessage);
    }
  }, [isFetchSwapRequestByAdminSuccess, fetchSwapRequestByAdminErrorCode, fetchSwapRequestByAdminMessage]);

  // Decline swap reasons api response state change.
  useEffect(() => {
    if (isDeclineSwapReasonsSuccess && declineSwapReasonsErrorCode === HTTP_STATUS_200) {
      if (declineSwapReasons && declineSwapReasons.length > 0) {
        const reasonOptions = declineSwapReasons.map((leave: ISwapDeclineReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setSwapReasonList(reasonOptions);
        setSelectedReason(reasonOptions[0] ?? null);
      }
    } else if (!isDeclineSwapReasonsSuccess && declineSwapReasonsErrorCode > HTTP_STATUS_200 && declineSwapReasonsMessage) {
      toast.toastError(declineSwapReasonsMessage);
    }
  }, [isDeclineSwapReasonsSuccess, declineSwapReasonsErrorCode, declineSwapReasonsMessage, declineSwapReasons]);

  // Approve swap request api response state change.
  useEffect(() => {
    if (isApproveSwapByAdminSuccess && approveSwapByAdminErrorCode === HTTP_STATUS_200 && approveSwapByAdminMessage) {
      toast.toastSuccess(approveSwapByAdminMessage);
      setShowDeclineModal(false);
      dispatch(revertAllSwapState());
      fetchAllSwapRequest();
    } else if (!isApproveSwapByAdminSuccess && approveSwapByAdminErrorCode > HTTP_STATUS_200 && approveSwapByAdminMessage) {
      toast.toastError(approveSwapByAdminMessage);
      dispatch(revertAllSwapState());
    }
  }, [isApproveSwapByAdminSuccess, approveSwapByAdminErrorCode, approveSwapByAdminMessage]);

  // Decline swap request api response state change.
  useEffect(() => {
    if (isDeclineSwapByAdminSuccess && declineSwapByAdminErrorCode === HTTP_STATUS_200 && declineSwapByAdminMessage) {
      toast.toastSuccess(declineSwapByAdminMessage);
      setShowDeclineModal(false);
      dispatch(revertAllSwapState());
      fetchAllSwapRequest();
    } else if (!isDeclineSwapByAdminSuccess && declineSwapByAdminErrorCode > HTTP_STATUS_200 && declineSwapByAdminMessage) {
      toast.toastError(declineSwapByAdminMessage);
      dispatch(revertAllSwapState());
    }
  }, [isDeclineSwapByAdminSuccess, declineSwapByAdminErrorCode, declineSwapByAdminMessage]);

  // Function to handle approve action.
  const handleApprove = (swapRequestId: number) => {
    setSelectedSwapId(swapRequestId);
    setApproveConfirmPopup(true);
  };

  // Handler for search input change.
  const handleSearchChange = (query: string) => {
    setSearchText(query);
  };

  // Handler for start date picker changes.
  const handleStartDateChange = (date: Date | null) => {
    if (date !== null) {
      setStartDate(date);
      setEndDate(null);
    }
  };

  // Handler for end date picker changes.
  const handleEndDateChange = (date: Date | null) => {
    if (date !== null) {
      setEndDate(date);
    }
  };

  // Handler for status select change.
  const handleStatusChange = (val: IObject) => {
    setSelectedStatus(val);
  };

  // Filter change handler.
  const handleFilterChange = (state: { isOpen: boolean }) => {
    setFilterOpen(state.isOpen);
  };

  // Apply filters to swap requests.
  const applyFilters = () => {
    fetchAllSwapRequest();
    setFilterOpen(false);
  };

  // Reset all filters.
  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedStatus({ label: ALL_TEXT, value: NUMBER_0.toString() });
  };

  // Decline reason input change handler.
  const onDeclineReasonChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const swapDeclineForm = {
      swapReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: value,
    };
    const errorResult = await validateForm(swapDeclineForm, SWAP_DECLINE_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setDeclineReason(value);
  };

  // Swap decline reason type dropdown select change handler.
  const onDeclineReasonSelect = (val: IObject) => {
    setSelectedReason(val);
    setDeclineReason('');
    setErrorFields(null);
  };

  // Function to handle the decline action.
  const handleDecline = (swapId: number) => {
    dispatch(getDeclineSwapReasons());
    setSelectedSwapId(swapId);
    setDeclineReason('');
    setShowDeclineModal(true);
  };

  // Function to confirm decline
  const confirmDecline = async () => {
    const swapDeclineForm = {
      swapReasonType: selectedReason?.value || 0,
      reason: declineReason,
    };
    const errorResult = await validateForm(swapDeclineForm, SWAP_DECLINE_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length === 0) {
      setDeclineConfirmPopup(true);
    }
  };

  // Approve swap confirm event.
  const onSwapApprove = () => {
    setApproveConfirmPopup(false);
    if (selectedSwapId > NUMBER_0) {
      dispatch(approveSwapRequestByAdmin({ swapRequestId: selectedSwapId }));
    }
  };

  // Decline swap confirm event.
  const onSwapDecline = () => {
    setDeclineConfirmPopup(false);
    if (selectedSwapId > NUMBER_0 && selectedReason) {
      dispatch(declineSwapRequestByAdmin({ swapRequestId: selectedSwapId, reasonId: Number(selectedReason.value), description: declineReason }));
    }
  };

  // Swap status filter options.
  const status = [
    { label: ALL_TEXT, value: NUMBER_0.toString() },
    { label: SwapStatusByAdmin[SwapStatusByAdmin.Pending_Supervisor_Approval].replace(/_/g, ' '), value: SwapStatusByAdmin.Pending_Supervisor_Approval.toString() },
    { label: SwapStatusByAdmin[SwapStatusByAdmin.Approved].replace(/_/g, ' '), value: SwapStatusByAdmin.Approved.toString() },
    { label: SwapStatusByAdmin[SwapStatusByAdmin.Declined].replace(/_/g, ' '), value: SwapStatusByAdmin.Declined.toString() },
  ];

  return (
    <div>
      <div className="pageheader">
        <h1>{Strings.HD.ManageSwaps}</h1>
      </div>
      <div className="filter-main">
        <Row className="d-flex justify-content-between">
          <Col xxl={4} xl={4} lg={5} md={6} xs="auto" className="pe-0">
            <KKISearch placeholder={Strings.Input.SwapSearch} onSearch={handleSearchChange} />
          </Col>
          <Col xs="auto" className="btn-container d-flex ps-0">
            <Filter
              width={310}
              right
              pageWrapId="filter-wrapper"
              outerContainerId="outer-container"
              isOpen={isFilterOpen}
              onStateChange={handleFilterChange}
              customCrossIcon={<Close />}
              bodyClassName="overflow-hidden"
            >
              <div id="filter-wrapper" className="filter-overlay">
                <div className="overlay-hd p-3 border-bottom">
                  <h1>{Strings.Filter.Filter}</h1>
                </div>
                <div className="overlay-filter-content">
                  <div className="mb-3">
                    <KKIDatepicker
                      id="startDate"
                      name="startDate"
                      placeholder={Strings.Label.StartDate}
                      label={Strings.Label.ShiftDate}
                      value={startDate}
                      dateFormat="dd MMM yyyy"
                      onChangeDatepicker={(date: any) => handleStartDateChange(date)}
                    />
                  </div>
                  <div className="mb-3">
                    <KKIDatepicker
                      id="endDate"
                      name="endDate"
                      placeholder={Strings.Label.EndDate}
                      value={endDate}
                      dateFormat="dd MMM yyyy"
                      minDate={startDate || moment().toDate()}
                      onChangeDatepicker={(date: any) => handleEndDateChange(date)}
                    />
                  </div>
                  <div>
                    <KKISelect
                      id="signupselect"
                      label={Strings.Label.Status}
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Label.Status}
                      searchvalue={false}
                      options={status}
                      value={selectedStatus}
                      onSelectChange={handleStatusChange}
                    />
                  </div>
                </div>

                <Col className="overlay-button-container">
                  <Button variant="outline-primary" onClick={resetFilters}>
                    {Strings.Button.Reset}
                  </Button>
                  <Button variant="primary" onClick={applyFilters}>
                    {Strings.Button.Apply}
                  </Button>
                </Col>
              </div>
            </Filter>
          </Col>
        </Row>
      </div>
      <Row>
        {swapRequests &&
          swapRequests.length > 0 &&
          swapRequests.map((swap: ISwapRequests) => (
            <Col lg={6} md={12} key={swap.swapRequestId}>
              <SwapCard swapInfo={swap} onApprove={() => handleApprove(swap.swapRequestId)} handleDecline={() => handleDecline(swap.swapRequestId)} />
            </Col>
          ))}
        {(!swapRequests || (swapRequests && swapRequests.length === 0)) && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>}
      </Row>
      <Modal show={showDeclineModal} onHide={() => setShowDeclineModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.HD.ReasonForDecline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="mb-3">
            <KKISelect
              id="reasonTypes"
              label={Strings.Label.SelectReason}
              name="reasonTypes"
              className="custom-select"
              placeholder={Strings.Label.LeaveType}
              searchvalue={false}
              options={swapReasonList}
              value={selectedReason}
              onSelectChange={onDeclineReasonSelect}
            />
          </Col>
          {selectedReason?.label === OTHER_REASON_LABEL && (
            <Col>
              <KKIInput
                id="declineReason"
                label={Strings.Label.EnterReason}
                name="declineReason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Swap.PlaceHolder.EnterReason}
                value={declineReason}
                onChange={onDeclineReasonChange}
                alert={errorFields?.reason || ''}
              />
            </Col>
          )}
          <Col className="button-container-right text-end mt-4">
            <Button variant="outline-primary" onClick={() => setShowDeclineModal(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" onClick={confirmDecline}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showApproveConfirmPopup} onHide={() => setApproveConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.SwapApprovePopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setApproveConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onSwapApprove()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showDeclineConfirmPopup} onHide={() => setDeclineConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.SwapDeclinePopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setDeclineConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onSwapDecline()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isFetchSwapRequestByAdminLoading || isApproveSwapByAdminLoading || isDeclineSwapByAdminLoading) && <Loader />}
    </div>
  );
};
export default SCHManageCallOutShift;
