/**
 * @file   src\store\slices\manageLeaveSlice.ts
 * @brief  This file is responsible for creating leave management slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageLeave } from '../../interfaces/leaveInterface';
import { getAlertMessage } from '../../utils/helper';
import {
  applyLeave,
  approveLeave,
  declineLeave,
  deleteLeaveReuest,
  getAllLeaves,
  getDeclineLeaveReasons,
  getLeavesDetails,
  revertAllLeaveState,
} from '../actions/manageLeaveAction';

// Initial state.
const manageLeaveState: IManageLeave = {
  isLeaveSuccess: false,
  isLeaveLoading: false,
  leaveErrorCode: 0,
  leaveMessage: null,
  leaveListResponse: null,

  isApplyLeaveSuccess: false,
  isApplyLeaveLoading: false,
  applyLeaveErrorCode: 0,
  applyLeaveMessage: null,

  isDeleteLeaveRequestSuccess: false,
  isDeleteLeaveRequestLoading: false,
  deleteLeaveRequestErrorCode: 0,
  deleteLeaveRequestMessage: null,

  isApproveLeaveSuccess: false,
  isApproveLeaveLoading: false,
  approveLeaveErrorCode: 0,
  approveLeaveMessage: null,

  isDeclineLeaveSuccess: false,
  isDeclineLeaveLoading: false,
  declineLeaveErrorCode: 0,
  declineLeaveMessage: null,

  isLeaveDetailsSuccess: false,
  isLeaveDetailsLoading: false,
  leaveDetailsErrorCode: 0,
  leavDetailsMessage: null,
  leaveInfo: null,

  isDeclineLeaveReasonsLoading: false,
  isDeclineLeaveReasonsSuccess: false,
  declineLeaveReasonsErrorCode: 0,
  declineLeaveReasonsMessage: null,
  declineLeaveReasons: [],
};

// leave management slice.
export const manageLeaveSlice = createSlice({
  name: 'manageCALeave',
  initialState: manageLeaveState,
  reducers: {
    resetLeaveState: () => manageLeaveState,
  },
  extraReducers(builder) {
    // ********************* Start of all requested leaves action state handling. *********************
    // Get all leave request default state.
    builder.addCase(getAllLeaves.pending, (state) => {
      state.isLeaveSuccess = false;
      state.isLeaveLoading = true;
      state.leaveErrorCode = 0;
      state.leaveMessage = '';
    });
    //  Get all leave request success state.
    builder.addCase(getAllLeaves.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isLeaveLoading = false;
      state.isLeaveSuccess = true;
      state.leaveErrorCode = code;
      state.leaveMessage = status || '';
      state.leaveListResponse = info;
    });
    //  Get all leave request error state.
    builder.addCase(getAllLeaves.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isLeaveSuccess = false;
      state.isLeaveLoading = false;
      state.leaveErrorCode = errcode;
      state.leaveMessage = error || '';
    });
    // ********************* End of all requested leaves action state handling. *********************

    // ********************* Start of apply leave action state handling. *********************
    // Apply leave requests default state.
    builder.addCase(applyLeave.pending, (state) => {
      state.isApplyLeaveSuccess = false;
      state.isApplyLeaveLoading = true;
      state.applyLeaveErrorCode = 0;
      state.applyLeaveMessage = '';
    });
    // Apply leave requests success state.
    builder.addCase(applyLeave.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isApplyLeaveLoading = false;
      state.isApplyLeaveSuccess = true;
      state.applyLeaveErrorCode = code;
      state.applyLeaveMessage = status || '';
    });
    // Apply leave requests error state.
    builder.addCase(applyLeave.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isApplyLeaveSuccess = false;
      state.isApplyLeaveLoading = false;
      state.applyLeaveErrorCode = errcode;
      state.applyLeaveMessage = error || '';
    });
    // ********************* End of apply leave action state handling. *********************

    // ********************* Start of delete leave request action state handling. *********************
    // Delete leave request default state.
    builder.addCase(deleteLeaveReuest.pending, (state) => {
      state.isDeleteLeaveRequestSuccess = false;
      state.isDeleteLeaveRequestLoading = true;
      state.deleteLeaveRequestErrorCode = 0;
      state.deleteLeaveRequestMessage = '';
    });
    // Delete leave request success state.
    builder.addCase(deleteLeaveReuest.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeleteLeaveRequestLoading = false;
      state.isDeleteLeaveRequestSuccess = true;
      state.deleteLeaveRequestErrorCode = code;
      state.deleteLeaveRequestMessage = status || '';
    });
    // Delete leave request error state.
    builder.addCase(deleteLeaveReuest.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeleteLeaveRequestSuccess = false;
      state.isDeleteLeaveRequestLoading = false;
      state.deleteLeaveRequestErrorCode = errcode;
      state.deleteLeaveRequestMessage = error || '';
    });
    // ********************* End of delete leave request action state handling. *********************

    // ********************* Start of approve leave action state handling. *********************
    // Approve leave default state.
    builder.addCase(approveLeave.pending, (state) => {
      state.isApproveLeaveSuccess = false;
      state.isApproveLeaveLoading = true;
      state.approveLeaveErrorCode = 0;
      state.approveLeaveMessage = '';
    });
    // Approve leave success state.
    builder.addCase(approveLeave.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isApproveLeaveLoading = false;
      state.isApproveLeaveSuccess = true;
      state.approveLeaveErrorCode = code;
      state.approveLeaveMessage = status || '';
    });
    // Approve leave error state.
    builder.addCase(approveLeave.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isApproveLeaveSuccess = false;
      state.isApproveLeaveLoading = false;
      state.approveLeaveErrorCode = errcode;
      state.approveLeaveMessage = error || '';
    });
    // ********************* End of approve leave action state handling. *********************

    // ********************* Start of decline leave action state handling. *********************
    // Decline leave default state.
    builder.addCase(declineLeave.pending, (state) => {
      state.isDeclineLeaveSuccess = false;
      state.isDeclineLeaveLoading = true;
      state.declineLeaveErrorCode = 0;
      state.declineLeaveMessage = '';
    });
    // Decline leave success state.
    builder.addCase(declineLeave.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeclineLeaveLoading = false;
      state.isDeclineLeaveSuccess = true;
      state.declineLeaveErrorCode = code;
      state.declineLeaveMessage = status || '';
    });
    // Decline leave error state.
    builder.addCase(declineLeave.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeclineLeaveSuccess = false;
      state.isDeclineLeaveLoading = false;
      state.declineLeaveErrorCode = errcode;
      state.declineLeaveMessage = error || '';
    });
    // ********************* End of decline leave action state handling. *********************

    // ********************* Start of get leave details action state handling. *********************
    // Get leave details default state.
    builder.addCase(getLeavesDetails.pending, (state) => {
      state.isLeaveDetailsLoading = true;
      state.isLeaveDetailsSuccess = false;
      state.leaveDetailsErrorCode = 0;
      state.leavDetailsMessage = '';
    });
    // Get leave details success state.
    builder.addCase(getLeavesDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.isLeaveDetailsLoading = error === 0;
      state.isLeaveDetailsSuccess = true;
      state.leaveInfo = data;
      state.leaveDetailsErrorCode = error;
      state.leavDetailsMessage = getAlertMessage(error !== 0 ? error.toString() : '');
    });
    // Get leave details error state.
    builder.addCase(getLeavesDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLeaveDetailsSuccess = false;
      state.isLeaveDetailsLoading = false;
      state.leaveDetailsErrorCode = error;
      state.leavDetailsMessage = getAlertMessage(error && error !== 0 ? error.toString() : '');
    });
    // ********************* End of get leave details action state handling. *********************

    // ********************* Start of fetching decline leave reasons action state handling. *********************
    // Get all leave decline reasons default state.
    builder.addCase(getDeclineLeaveReasons.pending, (state) => {
      state.isDeclineLeaveReasonsSuccess = false;
      state.isDeclineLeaveReasonsLoading = true;
      state.declineLeaveReasonsErrorCode = 0;
      state.declineLeaveReasonsMessage = '';
    });
    //  Get all leave decline reasons success state.
    builder.addCase(getDeclineLeaveReasons.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isDeclineLeaveReasonsLoading = false;
      state.isDeclineLeaveReasonsSuccess = true;
      state.declineLeaveReasonsErrorCode = code;
      state.declineLeaveReasonsMessage = status || '';
      state.declineLeaveReasons = info;
    });
    //  Get all leave decline reasons error state.
    builder.addCase(getDeclineLeaveReasons.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeclineLeaveReasonsSuccess = false;
      state.isDeclineLeaveReasonsLoading = false;
      state.declineLeaveReasonsErrorCode = errcode;
      state.declineLeaveReasonsMessage = error || '';
    });
    // ********************* End of fetching decline leave reasons action state handling. *********************

    // ********************* Clear all leave states to initial sate action. *********************
    builder.addCase(revertAllLeaveState, (state) => {
      state.isLeaveSuccess = false;
      state.isLeaveLoading = false;
      state.leaveErrorCode = 0;
      state.leaveMessage = null;
      state.isApplyLeaveSuccess = false;
      state.isApplyLeaveLoading = false;
      state.applyLeaveErrorCode = 0;
      state.applyLeaveMessage = null;
      state.isDeleteLeaveRequestSuccess = false;
      state.isDeleteLeaveRequestLoading = false;
      state.deleteLeaveRequestErrorCode = 0;
      state.deleteLeaveRequestMessage = null;
      state.isApproveLeaveSuccess = false;
      state.isApproveLeaveLoading = false;
      state.approveLeaveErrorCode = 0;
      state.approveLeaveMessage = null;
      state.isDeclineLeaveSuccess = false;
      state.isDeclineLeaveLoading = false;
      state.declineLeaveErrorCode = 0;
      state.declineLeaveMessage = null;
      state.isLeaveDetailsSuccess = false;
      state.isLeaveDetailsLoading = false;
      state.leaveDetailsErrorCode = 0;
      state.leavDetailsMessage = null;
      state.isDeclineLeaveReasonsLoading = false;
      state.isDeclineLeaveReasonsSuccess = false;
      state.declineLeaveReasonsErrorCode = 0;
      state.declineLeaveReasonsMessage = null;
    });
  },
});

// Export manage leave actions.
export const { resetLeaveState } = manageLeaveSlice.actions;
// Export reducer.
export default manageLeaveSlice.reducer;
