/**
 * @file   src\containers\CA\Profile\ChangePassword.tsx
 * @brief  Change password page.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import { Col, Button, useState, useEffect, Row } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import KKIPassword from '../../../components/KKIPassword';
import PassowrdIcon from '../../../assests/icons/PasswordHide';
import PassowrdShow from '../../../assests/icons/PasswordShow';
import { RESET_PASSWORD_SCHEMA } from '../../../validations/userSchema';
import { validateForm } from '../../../utils/validationHelper';
import { IObject } from '../../../interfaces/generalInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changePassword, revertAllCommonState } from '../../../store/actions/commonAction';
import { IChangePasswordRequest } from '../../../interfaces/commonInterface';
import { RootState } from '../../../store';
import { HTTP_STATUS_200, NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_30 } from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import PasswordImg from '../../../assests/images/change-password.svg';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const ChangePassword = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isChangePasswordLoading, isChangePasswordSuccess, changePasswordErrorCode, changePasswordMessage } = useAppSelector((state: RootState) => state.common);

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllCommonState());
    };
  }, []);

  // Clear input fields.
  const onClear = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  // Otp send api response state side effect handling.
  useEffect(() => {
    if (isChangePasswordSuccess && changePasswordErrorCode === HTTP_STATUS_200 && changePasswordMessage) {
      onClear();
      toast.toastSuccess(changePasswordMessage);
    } else if (!isChangePasswordSuccess && changePasswordErrorCode > HTTP_STATUS_200 && changePasswordMessage) {
      toast.toastError(changePasswordMessage);
    }
  }, [isChangePasswordSuccess, changePasswordErrorCode, changePasswordMessage]);

  // Show/hide password.
  const togglePassword = (type: number) => {
    switch (type) {
      case NUMBER_1:
        setShowOldPassword(!showOldPassword);
        break;
      case NUMBER_2:
        setShowPassword(!showPassword);
        break;
      case NUMBER_3:
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  // Old password element change event handler.
  const onOldPasswordChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ oldPassword: value }, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setOldPassword(value);
  };

  // New password element change event handler.
  const onNewPasswordChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ password: value }, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setNewPassword(value);
  };

  // Confirm password element change event handler.
  const onConfirmPasswordChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ password: newPassword, confirmPassword: value }, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setConfirmPassword(value);
  };

  // Change password button submit event handler.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const changePasswordForm = {
      oldPassword,
      password: newPassword,
      confirmPassword,
    };
    const errorresult = await validateForm(changePasswordForm, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      const changePasswordRequest: IChangePasswordRequest = {
        old_password: oldPassword,
        password: newPassword,
      };
      dispatch(changePassword(changePasswordRequest));
    }
  };

  return (
    <div>
      <div className="pageheader d-flex justify-content-between">
        <h1>{Strings.HD.ChangePassword}</h1>
      </div>
      <Col className="border p-4 rounded">
        <Row className="align-items-center">
          <Col>
            <form onSubmit={onSubmit}>
              <Col className="mb-3" xl={8} md={6} sm={12}>
                <KKIPassword
                  id="oldPassword"
                  name="oldPassword"
                  label={Strings.Label.OldPassword}
                  type={showOldPassword ? 'text' : 'password'}
                  icon={<PassowrdIcon />}
                  showpass={showOldPassword ? <PassowrdIcon togglePassword={() => togglePassword(NUMBER_1)} /> : <PassowrdShow togglePassword={() => togglePassword(NUMBER_1)} />}
                  placeholder={Strings.Label.OldPassword}
                  value={oldPassword}
                  alert={errorFields?.oldPassword || ''}
                  onChange={onOldPasswordChange}
                  mandatory
                  maxLength={NUMBER_30}
                />
              </Col>
              <Col className="mb-3" xl={8} md={6} sm={12}>
                <KKIPassword
                  id="newPassword"
                  name="newPassword"
                  label={Strings.Label.NewPassword}
                  type={showPassword ? 'text' : 'password'}
                  icon={<PassowrdIcon />}
                  showpass={showPassword ? <PassowrdIcon togglePassword={() => togglePassword(NUMBER_2)} /> : <PassowrdShow togglePassword={() => togglePassword(NUMBER_2)} />}
                  placeholder={Strings.Label.NewPassword}
                  value={newPassword}
                  alert={errorFields?.password || ''}
                  onChange={onNewPasswordChange}
                  mandatory
                  maxLength={NUMBER_30}
                />
              </Col>
              <Col className="mb-3" xl={8} md={6} sm={12}>
                <KKIPassword
                  id="confirmPassword"
                  name="confirmPassword"
                  label={Strings.Label.ConfirmNewPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  icon={<PassowrdIcon />}
                  showpass={
                    showConfirmPassword ? <PassowrdIcon togglePassword={() => togglePassword(NUMBER_3)} /> : <PassowrdShow togglePassword={() => togglePassword(NUMBER_3)} />
                  }
                  placeholder={Strings.Label.ConfirmNewPassword}
                  value={confirmPassword}
                  alert={errorFields?.confirmPassword || ''}
                  onChange={onConfirmPasswordChange}
                  mandatory
                  maxLength={NUMBER_30}
                />
              </Col>
              <Col className="mb-3 button-container">
                <Button variant="outline-primary" onClick={() => onClear()}>
                  {Strings.Button.Clear}
                </Button>
                <Button type="submit" variant="primary">
                  {Strings.Button.ChangePassword}
                </Button>
              </Col>
            </form>
          </Col>
          <Col xl={5} className="text-center d-none d-xl-block">
            <img src={PasswordImg} className="w-50" alt="" />
          </Col>
        </Row>
      </Col>
      {isChangePasswordLoading && <Loader />}
    </div>
  );
};
export default ChangePassword;
