import React from 'react';

const OnCallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
      <g id="Group_16270" data-name="Group 16270" transform="translate(-259.754 -681.754)">
        <circle id="Ellipse_123" data-name="Ellipse 123" cx="6.5" cy="6.5" r="6.5" transform="translate(259.754 681.754)" fill="#fd861f" />
        <path
          id="call_24dp_5F6368_FILL1_wght400_GRAD0_opsz24"
          d="M126.157-833.461a5.452,5.452,0,0,1-2.243-.5,6.71,6.71,0,0,1-2.016-1.4,6.711,6.711,0,0,1-1.4-2.016,5.453,5.453,0,0,1-.495-2.243.37.37,0,0,1,.109-.272.37.37,0,0,1,.272-.109h1.471a.338.338,0,0,1,.227.086.332.332,0,0,1,.118.2l.236,1.271a.64.64,0,0,1-.009.245.382.382,0,0,1-.1.173l-.881.89a4.389,4.389,0,0,0,.431.649,6.4,6.4,0,0,0,.549.6,6.375,6.375,0,0,0,.59.522,5.737,5.737,0,0,0,.654.44l.854-.854a.508.508,0,0,1,.213-.123.583.583,0,0,1,.259-.023l1.253.254a.42.42,0,0,1,.209.132.32.32,0,0,1,.082.213v1.471a.37.37,0,0,1-.109.273A.37.37,0,0,1,126.157-833.461Z"
          transform="translate(142.985 1525.027)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default OnCallIcon;
