import React from 'react';

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.205" height="16" viewBox="0 0 14.205 16">
      <path
        id="delete_24dp_5F6368_FILL0_wght200_GRAD0_opsz24"
        d="M202.654-774.77a1.581,1.581,0,0,1-1.16-.479,1.58,1.58,0,0,1-.479-1.16v-12.566H200v-1.015h4.058v-.78h6.088v.78h4.059v1.015H213.19v12.566a1.59,1.59,0,0,1-.469,1.17,1.589,1.589,0,0,1-1.17.469Zm9.522-14.205H202.03v12.566a.607.607,0,0,0,.176.448.608.608,0,0,0,.449.176h8.9a.6.6,0,0,0,.429-.2.6.6,0,0,0,.2-.429Zm-7.3,11.161h1.015v-9.132h-1.015Zm3.434,0h1.015v-9.132h-1.015Zm-6.283-11.161v0Z"
        transform="translate(-200 790.77)"
        fill="#ef614b"
      />
    </svg>
  );
};

export default DeleteIcon;
