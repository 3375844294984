import React from 'react';

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g id="log-out" transform="translate(-2.5 -2.5)">
        <path
          id="Path_10377"
          data-name="Path 10377"
          d="M9,19.5H5a2.4,2.4,0,0,1-2.5-2.278V4.778A2.4,2.4,0,0,1,5,2.5H9a.5.5,0,0,1,0,1H5A1.4,1.4,0,0,0,3.5,4.778V17.222A1.4,1.4,0,0,0,5,18.5H9a.5.5,0,0,1,0,1Z"
          transform="translate(0 0)"
          fill="#400286"
        />
        <path
          id="Path_10378"
          data-name="Path 10378"
          d="M16,15.5a.5.5,0,0,1-.313-.89L20.2,11,15.688,7.39a.5.5,0,0,1,.625-.781l5,4a.5.5,0,0,1,0,.781l-5,4A.5.5,0,0,1,16,15.5Z"
          transform="translate(-2)"
          fill="#400286"
        />
        <path id="Line_162" data-name="Line 162" d="M12,.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H12a.5.5,0,0,1,.5.5A.5.5,0,0,1,12,.5Z" transform="translate(7 11)" fill="#400286" />
      </g>
    </svg>
  );
};

export default LogoutIcon;
