/**
 * @file   src\containers\CA\ManageSwap\CreateSwapRequest.tsx
 * @brief  Option for create swap request.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Col, Row, Card, Link, Button, useParams, moment, useNavigate, useEffect, useState, useLocation, Modal } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Arrow from '../../../assests/icons/DownArrow';
import KKIDatepicker from '../../../components/KKIDatepicker';
import SwapShiftCard from '../../../components/SwapShiftOfferedCard';
import { IShift } from '../../../interfaces/scheduleInterface';
import {
  CURRENT_DATE_FORMAT_WITH_OFFSET,
  DATE_FORMAT_DD_MMM_YYYY,
  DATE_FORMAT_DD_MMM_YYYY_HHMMA,
  DATE_FORMAT_HHMMA,
  DATE_FORMAT_YYYY_MM_DD,
  DEAULT_TIME_FORMAT,
  HTTP_STATUS_200,
  NIGHT_SHIFTS,
  NUMBER_0,
  NUMBER_1,
} from '../../../utils/constants';
import { CARoutePath } from '../../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getShiftDetails, getUserShifts, revertAllScheduleState } from '../../../store/actions/manageCAScheduleAction';
import { RootState } from '../../../store';
import { revertAllSwapState, swapShift } from '../../../store/actions/manageSwapAction';
import { MessageToaster } from '../../../utils/toastUtils';
import { SHIFT_REQUIRED } from '../../../messages/validationMessages';

// Toast object creation.
const toast = new MessageToaster();

const CreateSwapRequest = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Create route param object to access the route parameters.
  const params = useParams();
  const location = useLocation();

  /* Access and set the location url parameters. */
  const shiftId: number = params.shiftId ? Number(params.shiftId) : 0;

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const { shiftDetails, userShiftsList } = useAppSelector((state: RootState) => state.manageCASchedule);
  const { isSwapSuccess, swapErrorCode, swapMessage } = useAppSelector((state: RootState) => state.manageSwap);

  // Initialize component stat variables.
  const [showOfferShifts, setShowOfferShifts] = useState<boolean>(false);
  const [requestedDate, setRequestedDate] = useState<Date | null>(moment().toDate());
  const [selectedShift, setSelectedShift] = useState<{ shiftToId: number; shiftToDate: string } | null>(null);
  const [confirmPopup, showConfirmPopup] = useState<boolean>(false);

  // Component initial loading.
  useEffect(() => {
    dispatch(getShiftDetails({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));

    // On unmount reset states
    return () => {
      setShowOfferShifts(false);
      dispatch(revertAllScheduleState());
      dispatch(revertAllSwapState());
    };
  }, []);

  // Swap request response state change.
  useEffect(() => {
    if (isSwapSuccess && swapErrorCode === HTTP_STATUS_200 && swapMessage) {
      toast.toastSuccess(swapMessage);
      navigate(location.state?.from === NUMBER_1 ? CARoutePath.Home : CARoutePath.ShiftDetails.replace(':shiftId', shiftId.toString()).replace(':type', NUMBER_1.toString()));
    } else if (!isSwapSuccess && swapErrorCode > HTTP_STATUS_200 && swapMessage) {
      toast.toastError(swapMessage);
    }
  }, [isSwapSuccess, swapErrorCode, swapMessage]);

  // Go button submit event handler.
  const onClickGo = () => {
    setSelectedShift(null);
    const validDate = requestedDate || new Date();
    const requestParams = {
      startDate: moment(validDate).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      endDate: moment(validDate).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      limit: NUMBER_0,
      page: NUMBER_0,
    };
    dispatch(getUserShifts(requestParams));
    setShowOfferShifts(true);
  };

  // Date filter input select event handler.
  const handleDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      setRequestedDate(date);
    }
  };

  // Handler function to update the state with the selected shift details
  const handleCheckboxChange = (shiftToId: number, shiftToDate: string | null) => {
    if (shiftToId && shiftToDate) {
      setSelectedShift({
        shiftToId,
        shiftToDate,
      });
    }
  };

  // Submit swap request API call
  const submitSwapRequest = () => {
    if (selectedShift !== null && shiftDetails !== null) {
      const requestParams = {
        shiftId: selectedShift.shiftToId,
        shiftToType: shiftDetails.shiftId,
        shiftFromDate: selectedShift.shiftToDate,
        shiftToDate: shiftDetails.startDate,
      };
      dispatch(swapShift(requestParams));
    } else {
      // Handle error if no shift is selected
      toast.toastError(SHIFT_REQUIRED);
    }
  };

  // On Cliking submit button
  const onClickSubmitButton = () => {
    if (selectedShift !== null && shiftDetails !== null) {
      showConfirmPopup(true);
    } else {
      // Handle error if no shift is selected
      toast.toastError(SHIFT_REQUIRED);
    }
  };
  // Handle modal confirmation
  const confirmSwapRequest = () => {
    submitSwapRequest();
    showConfirmPopup(false); // Hide confirmation modal
  };

  return (
    <>
      <div>
        <div className="pageheader">
          <h1>
            <Link
              to={location.state?.from === NUMBER_1 ? CARoutePath.Home : CARoutePath.ShiftDetails.replace(':shiftId', shiftId.toString()).replace(':type', NUMBER_1.toString())}
              className="back-btn"
            >
              <Arrow />
            </Link>
            {Strings.HD.CreateSwapRequest}
          </h1>
        </div>
        {shiftDetails && (
          <>
            <div className="shiftcard-main shiftdetails-card">
              <Card>
                <Card.Body className="shift-details pb-0-min-lg">
                  <Col className="shift-details-sub mb-0">
                    <Row>
                      <Col xs={12} md={6} className="bb-0-min-md">
                        {Strings.Schedule.ShiftType}
                        <span>{`${shiftDetails?.shiftName} (${shiftDetails?.shortName})`}</span>
                      </Col>
                      <Col xs={12} md={6} className="text-start">
                        {Strings.Schedule.ShiftTiming}
                        <span>
                          {' '}
                          {NIGHT_SHIFTS.includes(shiftDetails.shortName)
                            ? `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)}`
                            : `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)} , ${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA)}`}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </div>

            <Col xl={4} md={6} sm={12} className="my-3">
              <label htmlFor="Offered Shift" className="form-label">
                {Strings.Label.OfferedShift}
              </label>
              <Row className="align-items-center">
                <Col xs className="calendar-mob-left">
                  <KKIDatepicker
                    id="kkidatepicker"
                    name="kkidatepicker"
                    placeholder="placeholder"
                    value={requestedDate}
                    dateFormat="dd MMM yyyy"
                    minDate={moment().toDate()}
                    onChangeDatepicker={(date: any) => handleDateFilterChange(date)}
                  />
                </Col>
                <Col xs="auto">
                  <Button variant="primary" onClick={onClickGo}>
                    {Strings.Button.Go}
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        )}
        {showOfferShifts && userShiftsList?.length > 0 && (
          <>
            <Col className="mb-3">
              <h1>{Strings.HD.MyShiftOffered}</h1>
              {userShiftsList?.map((shiftItem: IShift) => <SwapShiftCard key={shiftItem.shiftId} swapShifts={shiftItem} handleCheckboxChange={handleCheckboxChange} />)}
            </Col>
            <Button
              variant="primary"
              onClick={() => {
                onClickSubmitButton();
              }}
            >
              {Strings.Button.Submit}
            </Button>
          </>
        )}
        {showOfferShifts && (!userShiftsList || (userShiftsList && userShiftsList?.length === 0 && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>))}
      </div>

      <Modal show={confirmPopup} onHide={() => showConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.SwapRequestPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={confirmSwapRequest} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateSwapRequest;
