import React from 'react';

const ManageHolidaysIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.651" height="25.853" viewBox="0 0 26.651 25.853">
      <g id="Group_16256" data-name="Group 16256" transform="translate(2024.652 -2618.01)">
        <g id="Group_16247" data-name="Group 16247" transform="translate(-2024.732 2618.172) rotate(-1)">
          <path
            id="Subtraction_1"
            data-name="Subtraction 1"
            d="M17.422,8.241a8.242,8.242,0,0,0-16.362,0H17.422m1.06,1H0a9.241,9.241,0,0,1,18.482,0Z"
            transform="matrix(1, 0.017, -0.017, 1, 0.082, -0.161)"
            fill="#1b1658"
          />
          <path
            id="Path_10414"
            data-name="Path 10414"
            d="M0,17.183a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V16.683A.5.5,0,0,1,0,17.183Z"
            transform="translate(9.241 8.625)"
            fill="#1b1658"
          />
          <path
            id="Path_10415"
            data-name="Path 10415"
            d="M0,8.961a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.461A.5.5,0,0,1,0,8.961Z"
            transform="translate(9.224 1.32)"
            fill="#1b1658"
          />
          <path
            id="Path_10416"
            data-name="Path 10416"
            d="M0,8H-.016A.5.5,0,0,1-.5,7.484,9.541,9.541,0,0,1,.509,3.67,20.931,20.931,0,0,1,4.129-.376a.5.5,0,0,1,.706.046.5.5,0,0,1-.046.706,20.445,20.445,0,0,0-3.4,3.776A8.655,8.655,0,0,0,.5,7.516.5.5,0,0,1,0,8Z"
            transform="translate(4.78 0.869)"
            fill="#1b1658"
          />
          <path
            id="Path_10417"
            data-name="Path 10417"
            d="M4.458,8a.5.5,0,0,1-.5-.484,8.657,8.657,0,0,0-.886-3.364A20.416,20.416,0,0,0-.33.376.5.5,0,0,1-.376-.33.5.5,0,0,1,.33-.376,20.927,20.927,0,0,1,3.95,3.67,9.543,9.543,0,0,1,4.958,7.484.5.5,0,0,1,4.475,8Z"
            transform="translate(9.093 0.869)"
            fill="#1b1658"
          />
          <circle id="Ellipse_117" data-name="Ellipse 117" cx="1" cy="1" r="1" transform="translate(0.856 8.274)" fill="#1b1658" />
          <circle id="Ellipse_118" data-name="Ellipse 118" cx="1" cy="1" r="1" transform="translate(3.633 8.322)" fill="#1b1658" />
          <circle id="Ellipse_119" data-name="Ellipse 119" cx="1" cy="1" r="1" transform="translate(6.41 8.371)" fill="#1b1658" />
          <circle id="Ellipse_120" data-name="Ellipse 120" cx="1" cy="1" r="1" transform="translate(10.192 8.424)" fill="#1b1658" />
          <circle id="Ellipse_121" data-name="Ellipse 121" cx="1" cy="1" r="1" transform="translate(12.882 8.471)" fill="#1b1658" />
          <circle id="Ellipse_122" data-name="Ellipse 122" cx="1" cy="1" r="1" transform="translate(15.659 8.519)" fill="#1b1658" />
        </g>
        <path
          id="Path_10418"
          data-name="Path 10418"
          d="M-1981.5,2673.5a.5.5,0,0,1-.4-.2c-.37-.505-2.068-2.825-2.564-3.554-1.444-.167-11.348-.053-17.5.063a.505.505,0,0,1-.445-.255l-4.542-8.084a.5.5,0,0,1,.191-.681.5.5,0,0,1,.681.191l4.4,7.823c1.067-.019,4.818-.084,8.545-.12,2.576-.024,4.638-.029,6.127-.016.888.008,1.583.024,2.063.045.788.036,1.068.063,1.231.345.131.226,1.681,2.359,2.626,3.65a.5.5,0,0,1-.108.7A.5.5,0,0,1-1981.5,2673.5Z"
          transform="translate(-17.004 -29.895)"
          fill="#1b1658"
        />
        <path
          id="Path_10419"
          data-name="Path 10419"
          d="M-2000.829,2687.182a.5.5,0,0,1-.263-.075.5.5,0,0,1-.161-.688l2.464-3.965a.5.5,0,0,1,.689-.161.5.5,0,0,1,.161.688l-2.464,3.965A.5.5,0,0,1-2000.829,2687.182Z"
          transform="translate(-20.556 -43.319)"
          fill="#1b1658"
        />
      </g>
    </svg>
  );
};

export default ManageHolidaysIcon;
