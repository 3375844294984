/**
 * @file   src\hooks\useAccordionCollapse.ts
 * @brief  Custom hook to manage the collapsed state of accordions based on window size.
 * @date   Jul, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';
import { MOBILE_PIXELS } from '../utils/constants';

const useAccordionCollapse = () => {
  // Initialize component state variables.
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const debouncedWindowWidth = useDebounce(windowWidth, 100);

  // Effect to handle window resize events
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Effect to update accordion collapsed state based on debounced window width
  useEffect(() => {
    if (debouncedWindowWidth < MOBILE_PIXELS) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [debouncedWindowWidth]);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return { isCollapsed, toggleCollapse };
};

export default useAccordionCollapse;
