import React from 'react';

const ManageLeaveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.35" height="28" viewBox="0 0 25.35 28">
      <path
        id="Union_22"
        data-name="Union 22"
        d="M-905.259,3996.058h-8.9a2.846,2.846,0,0,1-2.841-2.846v-15.565a2.846,2.846,0,0,1,2.841-2.845h2.684v-1.181a.627.627,0,0,1,.632-.622.628.628,0,0,1,.632.622v1.181h7.577v-1.181A.628.628,0,0,1-902,3973a.627.627,0,0,1,.631.622v1.181h2.684a2.847,2.847,0,0,1,2.841,2.845v10.219q-.183-.077-.372-.145a6.854,6.854,0,0,1,4.569,6.434,6.893,6.893,0,0,1-6.94,6.845A6.93,6.93,0,0,1-905.259,3996.058Zm.99-1.9a5.646,5.646,0,0,0,5.679,5.6,5.645,5.645,0,0,0,5.678-5.6,5.645,5.645,0,0,0-5.678-5.6A5.646,5.646,0,0,0-904.269,3994.155Zm-11.469-16.508v15.565a1.593,1.593,0,0,0,1.579,1.6h8.66q-.032-.325-.032-.66a6.824,6.824,0,0,1,3.442-5.912.539.539,0,0,1-.006-.08c0-.866-.208-1.253-1.435-1.253H-908.4a1.911,1.911,0,0,0-1.35.549,1.844,1.844,0,0,0-.554,1.321v1.2a.531.531,0,0,1-.531.531.531.531,0,0,1-.53-.531v-1.2a2.894,2.894,0,0,1,.871-2.076,2.964,2.964,0,0,1,2.095-.856h4.869c1,0,2.3.208,2.476,1.907a7.012,7.012,0,0,1,2.464-.444,7.077,7.077,0,0,1,1.482.157v-9.82a1.593,1.593,0,0,0-1.579-1.6h-2.684v1.148a.627.627,0,0,1-.631.623.628.628,0,0,1-.633-.623v-1.148h-7.577v1.148a.628.628,0,0,1-.632.623.627.627,0,0,1-.632-.623v-1.148h-2.684A1.592,1.592,0,0,0-915.738,3977.647Zm19.519,10.073-.063-.022Zm-.067-.023-.032-.011Zm-.048-.017-.021-.007Zm-.044-.015-.014,0Zm-.043-.014-.008,0Zm-.042-.013h0Zm-.039-.012,0,0Zm-.041-.013h0Zm-5.048,6.887a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h6a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5Zm-7.7-13.081a2.965,2.965,0,0,1,3-2.926,2.965,2.965,0,0,1,3,2.926,2.966,2.966,0,0,1-3,2.928A2.966,2.966,0,0,1-909.3,3981.42Zm1.062,0a1.9,1.9,0,0,0,1.934,1.866,1.9,1.9,0,0,0,1.935-1.866,1.9,1.9,0,0,0-1.935-1.865A1.9,1.9,0,0,0-908.235,3981.42Z"
        transform="translate(917 -3973)"
        fill="#1b1658"
      />
    </svg>
  );
};

export default ManageLeaveIcon;
