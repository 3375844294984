import React from 'react';

const ManageCalloutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.21" height="26.209" viewBox="0 0 26.21 26.209">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_175" data-name="Rectangle 175" width="26.21" height="26.209" fill="#1b1658" />
        </clipPath>
      </defs>
      <g id="Group_328" data-name="Group 328" clipPath="url(#clip-path)">
        <path
          id="Path_421"
          data-name="Path 421"
          d="M0,12.29c.065-.471.111-.946.2-1.413A12.734,12.734,0,0,1,6.87,1.6a13.089,13.089,0,1,1,9.238,24.248c-.661.153-1.344.214-2.016.318-.075.012-.149.028-.224.042h-.921a.616.616,0,0,1-.357-.491c-.011-.3.218-.5.558-.536a18.475,18.475,0,0,0,2.557-.3,11.679,11.679,0,0,0,9.036-8.636,12.061,12.061,0,1,0-23.457-5.57,21.349,21.349,0,0,0-.254,2.282C1,13.33.9,13.527.643,13.6.4,13.669.207,13.566,0,13.262V12.29"
          transform="translate(0 0)"
          fill="#1b1658"
        />
        <path
          id="Path_422"
          data-name="Path 422"
          d="M10.011,311.381a.662.662,0,0,1-.36-.7,11.271,11.271,0,0,1,.143-1.826,3.549,3.549,0,0,1,2.923-2.638,4.813,4.813,0,0,1,1.013-.111c1.049-.012,2.1-.017,3.146,0a4.214,4.214,0,0,1,2.093.591,3.48,3.48,0,0,1,1.781,3.005c.015.323-.011.649.008.972a.685.685,0,0,1-.36.7Zm9.715-1.034c0-.25.013-.472,0-.692a2.435,2.435,0,0,0-1.315-2.084,3.428,3.428,0,0,0-1.637-.438c-1.006-.013-2.012-.026-3.017,0a4.382,4.382,0,0,0-1.25.216,2.549,2.549,0,0,0-1.794,2.994Z"
          transform="translate(-8.988 -285.172)"
          fill="#1b1658"
        />
        <path
          id="Path_423"
          data-name="Path 423"
          d="M47.722,35.488a10.676,10.676,0,0,1,1.8,21.225,16.022,16.022,0,0,1-1.6.133A.509.509,0,1,1,47.9,55.83c.06,0,.119,0,.179-.005a9.271,9.271,0,0,0,5.058-1.569A9.368,9.368,0,0,0,57.5,45.493a9.646,9.646,0,0,0-19.136-.944c-.043.263-.133.483-.42.545a.505.505,0,0,1-.6-.658,12.225,12.225,0,0,1,.472-1.827,10.552,10.552,0,0,1,8.275-6.969,7.382,7.382,0,0,1,.811-.106c.323-.028.647-.037.818-.046"
          transform="translate(-34.779 -33.062)"
          fill="#1b1658"
        />
        <path
          id="Path_424"
          data-name="Path 424"
          d="M34.724,184.222a4.125,4.125,0,1,1,4.117-4.089,4.116,4.116,0,0,1-4.117,4.089M34.7,183.2a3.1,3.1,0,1,0-3.085-3.115A3.091,3.091,0,0,0,34.7,183.2"
          transform="translate(-28.501 -163.941)"
          fill="#1b1658"
        />
        <path
          id="Path_425"
          data-name="Path 425"
          d="M184.1,82.981q0-1.764,0-3.528a.515.515,0,1,1,1.022.006c0,.068,0,.136,0,.2q0,3.2,0,6.392a.353.353,0,0,0,.205.359q1.224.689,2.433,1.405a.963.963,0,0,1,.328.3.44.44,0,0,1-.091.562.477.477,0,0,1-.608.094c-.314-.171-.62-.356-.93-.535-.671-.387-1.34-.778-2.015-1.159a.622.622,0,0,1-.345-.6c.007-1.168,0-2.335,0-3.5"
          transform="translate(-171.51 -73.482)"
          fill="#1b1658"
        />
      </g>
    </svg>
  );
};

export default ManageCalloutIcon;
