import React from 'react';

const DownFillIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8">
      <path id="Path_10359" data-name="Path 10359" d="M5.5,0,11,8H0Z" transform="translate(11 8) rotate(180)" fill="#400286" />
    </svg>
  );
};

export default DownFillIcon;
