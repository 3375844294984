/**
 * @file   src\store\slices\lightDutySlice.ts
 * @brief  This file is responsible for creating lightduty slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageLD } from '../../interfaces/lightdutyInterface';
import { getAllLDUsers, reportLDreason, revertAllLDState, getLdReasons, exportLDReasonList } from '../actions/lightDutyAction';
// import { getAlertMessage } from '../../utils/helper';

// Initial state.
const lightDutyState: IManageLD = {
  isFetchLDuserSuccess: false,
  isFetchLDuserLoading: false,
  fetchLDuserErrorCode: 0,
  fetchLDuserMessage: null,
  isReportLDReasonSuccess: false,
  isReportLDReasonLoading: false,
  reportexportLdReasonErrorCode: 0,
  reportLDReasonMessage: null,
  isExportLdReasonSuccess: false,
  isExportLdReasonLoading: false,
  exportLdReasonErrorCode: 0,
  exportLdReasonMessage: null,
  pdfData: null,
  ldUsers: [],
  total: 0,
  isLdReasonsLoading: false,
  isLdReasonsSuccess: false,
  ldReasonsErrorCode: 0,
  ldReasonsMessage: null,
  ldReasons: [],
};

// Config lightDuty slice.
export const lightDutySlice = createSlice({
  name: 'lightDuty',
  initialState: lightDutyState,
  reducers: {
    resetLDState: () => lightDutyState,
  },
  extraReducers(builder) {
    // ********************* Start of get all LD users listing action state handling. *********************
    // Get all LD users default state.
    builder.addCase(getAllLDUsers.pending, (state) => {
      state.isFetchLDuserSuccess = false;
      state.isFetchLDuserLoading = true;
      state.fetchLDuserErrorCode = 0;
      state.fetchLDuserMessage = '';
    });
    // Get all LD users success state.
    builder.addCase(getAllLDUsers.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchLDuserLoading = false;
      state.isFetchLDuserSuccess = true;
      state.fetchLDuserErrorCode = error;
      state.fetchLDuserMessage = status || '';
      state.ldUsers = info?.LDReasonsList || [];
      state.total = info?.total || 0; // Set total from info
    });
    // Get all LD users error state.
    builder.addCase(getAllLDUsers.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchLDuserSuccess = false;
      state.isFetchLDuserLoading = false;
      state.fetchLDuserErrorCode = errcode;
      state.fetchLDuserMessage = error || '';
    });
    // ********************* End of LD users listing action state handling. *********************
    // ********************* Start of report LD reason action state handling. *********************
    // Report LD reason default state.
    builder.addCase(reportLDreason.pending, (state) => {
      state.isReportLDReasonSuccess = false;
      state.isReportLDReasonLoading = true;
      state.reportexportLdReasonErrorCode = 0;
      state.reportLDReasonMessage = '';
    });
    //  Report LD reason success state.
    builder.addCase(reportLDreason.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isReportLDReasonLoading = false;
      state.isReportLDReasonSuccess = true;
      state.reportexportLdReasonErrorCode = code;
      state.reportLDReasonMessage = status || '';
    });
    // Report LD reason error state.
    builder.addCase(reportLDreason.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isReportLDReasonSuccess = false;
      state.isReportLDReasonLoading = false;
      state.reportexportLdReasonErrorCode = errcode;
      state.reportLDReasonMessage = error || '';
    });
    // ********************* End of report LD reason action state handling. *********************
    // ********************* Start of LD list in pdf action state handling. *********************
    // LD list in pdf default state.
    builder.addCase(exportLDReasonList.pending, (state) => {
      state.isExportLdReasonSuccess = false;
      state.isExportLdReasonLoading = true;
      state.exportLdReasonErrorCode = 0;
      state.exportLdReasonMessage = '';
    });
    //  LD list in pdf success state.
    builder.addCase(exportLDReasonList.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isExportLdReasonLoading = false;
      state.isExportLdReasonSuccess = true;
      state.pdfData = action.payload;
      state.exportLdReasonErrorCode = code;
      state.exportLdReasonMessage = status || '';
    });
    // LD list in pdf error state.
    builder.addCase(exportLDReasonList.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isExportLdReasonSuccess = false;
      state.isExportLdReasonLoading = false;
      state.exportLdReasonErrorCode = errcode;
      state.exportLdReasonMessage = error || '';
    });
    // ********************* End of LD list in pdf action state handling. *********************

    // ********************* Start of fetching ld reasons action state handling. *********************
    // Get all ld reasons default state.
    builder.addCase(getLdReasons.pending, (state) => {
      state.isLdReasonsSuccess = false;
      state.isLdReasonsLoading = true;
      state.ldReasonsErrorCode = 0;
      state.ldReasonsMessage = '';
    });
    //  Get all ld reasons success state.
    builder.addCase(getLdReasons.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isLdReasonsLoading = false;
      state.isLdReasonsSuccess = true;
      state.ldReasonsErrorCode = code;
      state.ldReasonsMessage = status || '';
      state.ldReasons = info;
    });
    //  Get all ld reasons error state.
    builder.addCase(getLdReasons.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isLdReasonsSuccess = false;
      state.isLdReasonsLoading = false;
      state.ldReasonsErrorCode = errcode;
      state.ldReasonsMessage = error || '';
    });
    // ********************* End of fetching decline swap reasons action state handling. *********************

    // ********************* Clear all light duty states to initial sate action. *********************
    builder.addCase(revertAllLDState, (state) => {
      state.isFetchLDuserSuccess = false;
      state.isFetchLDuserLoading = false;
      state.fetchLDuserErrorCode = 0;
      state.fetchLDuserMessage = null;
      state.isReportLDReasonSuccess = false;
      state.isReportLDReasonLoading = false;
      state.reportexportLdReasonErrorCode = 0;
      state.reportLDReasonMessage = null;
      state.isExportLdReasonSuccess = false;
      state.isExportLdReasonLoading = false;
      state.exportLdReasonErrorCode = 0;
      state.exportLdReasonMessage = null;
      state.isLdReasonsLoading = false;
      state.isLdReasonsSuccess = false;
      state.ldReasonsErrorCode = 0;
      state.ldReasonsMessage = null;
    });
  },
});
// Export lightduty actions.
export const { resetLDState } = lightDutySlice.actions;
// Export reducer.
export default lightDutySlice.reducer;
