import React from 'react';

const MobileMenuIcon = () => {
  return (
    <svg id="mobile-menu" xmlns="http://www.w3.org/2000/svg" width="21.671" height="16" viewBox="0 0 21.671 16">
      <path
        id="Path_72"
        data-name="Path 72"
        d="M2,12a1.039,1.039,0,0,1,1.078-1H22.593a1,1,0,1,1,0,1.99H3.078A1.039,1.039,0,0,1,2,12Z"
        transform="translate(-2 -3.994)"
        fill="#fff"
      />
      <path
        id="Path_10352"
        data-name="Path 10352"
        d="M2,12a1.039,1.039,0,0,1,1.078-1H22.593a1,1,0,1,1,0,1.99H3.078A1.039,1.039,0,0,1,2,12Z"
        transform="translate(-2 -11.005)"
        fill="#fff"
      />
      <path
        id="Path_10353"
        data-name="Path 10353"
        d="M2,12a1.039,1.039,0,0,1,1.078-1H22.593a1,1,0,1,1,0,1.99H3.078A1.039,1.039,0,0,1,2,12Z"
        transform="translate(-2 3.005)"
        fill="#fff"
      />
    </svg>
  );
};

export default MobileMenuIcon;
