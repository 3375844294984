import React from 'react';

const MidShiftIcon = () => {
  return (
    <svg id="Component_86_1" data-name="Component 86 – 1" xmlns="http://www.w3.org/2000/svg" width="21.238" height="20.936" viewBox="0 0 21.238 20.936">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_517" data-name="Rectangle 517" width="21.238" height="20.936" fill="none" stroke="#400286" strokeWidth="0.7" />
        </clipPath>
      </defs>
      <g id="Mask_Group_16063" data-name="Mask Group 16063" clipPath="url(#clip-path)">
        <path
          id="Path_10361"
          data-name="Path 10361"
          d="M8.53-.137A8.677,8.677,0,0,1,17.2,8.53v.35H-.137V8.53A8.677,8.677,0,0,1,8.53-.137ZM16.49,8.18a7.967,7.967,0,0,0-15.919,0Z"
          transform="translate(2.089 2.088)"
          fill="#400286"
        />
        <path
          id="Line_132"
          data-name="Line 132"
          d="M14.406.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H14.406a.35.35,0,0,1,.35.35A.35.35,0,0,1,14.406.35Z"
          transform="translate(3.416 14.765)"
          fill="#400286"
        />
        <path
          id="Line_133"
          data-name="Line 133"
          d="M12.4.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H12.4a.35.35,0,0,1,.35.35A.35.35,0,0,1,12.4.35Z"
          transform="translate(4.417 16.147)"
          fill="#400286"
        />
        <path
          id="Line_134"
          data-name="Line 134"
          d="M9.256.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H9.256a.35.35,0,0,1,.35.35A.35.35,0,0,1,9.256.35Z"
          transform="translate(5.99 17.529)"
          fill="#400286"
        />
        <path id="Path_10362" data-name="Path 10362" d="M4.229,11.661a8.349,8.349,0,0,0,5.293-.011Z" transform="translate(3.761 6.851)" fill="#400286" />
        <path
          id="Line_135"
          data-name="Line 135"
          d="M16.387.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H16.387a.35.35,0,0,1,.35.35A.35.35,0,0,1,16.387.35Z"
          transform="translate(2.426 12.001)"
          fill="#400286"
        />
        <path
          id="Line_136"
          data-name="Line 136"
          d="M15.674.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H15.674a.35.35,0,0,1,.35.35A.35.35,0,0,1,15.674.35Z"
          transform="translate(2.782 13.383)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default MidShiftIcon;
