import React from 'react';

const DownArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.317" height="6.902" viewBox="0 0 12.317 6.902">
      <g id="Group_16182" data-name="Group 16182" transform="translate(12.317 6.902) rotate(180)">
        <path
          id="Path_10389"
          data-name="Path 10389"
          d="M6.159,12.317a.744.744,0,0,0,.526-1.27l-4.9-4.9L6.508,1.26A.744.744,0,0,0,5.438.226L.209,5.642A.744.744,0,0,0,.217,6.685L5.633,12.1a.739.739,0,0,0,.526.217"
          transform="translate(0 6.902) rotate(-90)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default DownArrowIcon;
