/**
 * @file   src\components\VerifyMobileModal.tsx
 * @brief  VerificationModal component.
 * @date   JULY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, Modal, Col, useState, useEffect, useCallback } from './ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import VerficationCode from '../assests/images/verification-code.svg';
import KKIOtpInput from './KKIOtpInput';
import { IKKIVerifyOTPProps } from '../interfaces/generalInterface';
import Timer from './Timer';
import { DEFAULT_OTP_TIMER, NUMBER_6 } from '../utils/constants';
import { OTP_REQUIRED } from '../messages/validationMessages';

const VerifyMobileModal = ({ onSubmit, onResend, showOtpTimer, setTimerStatus }: IKKIVerifyOTPProps) => {
  // Initialize component stat variables.
  const [otp, setOtp] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // otp state change side effect handling.
  useEffect(() => {
    if (otp === '') {
      setOtp(otp);
    }
  }, [otp]);

  // OTP input change event.
  const onOtpChange = (val: string) => {
    if (val.split('').map((x) => +x).length === NUMBER_6 && errorMessage) {
      setErrorMessage('');
    }
    setOtp(val);
  };

  // Resend Otp.
  const onOtpResend = () => {
    setOtp('');
    onResend();
  };

  // Submit otp for verification.
  const onOTPSubmit = () => {
    if (otp.trim().length === NUMBER_6) {
      setErrorMessage('');
      onSubmit(otp);
    } else {
      setErrorMessage(OTP_REQUIRED);
    }
  };

  // Bind timer element
  const configureTimer = useCallback(() => {
    return (
      showOtpTimer && (
        <Timer
          timeLimit={process.env.OTP_RESEND_TIME ? Number(process.env.OTP_RESEND_TIME) : DEFAULT_OTP_TIMER}
          isActive={showOtpTimer}
          handleOtpLink={(buttonStatus: boolean) => setTimerStatus(buttonStatus)}
        />
      )
    );
  }, [showOtpTimer]);

  return (
    <Modal.Body className="text-center">
      <div className="lock-icn-wrap text-center">
        <img src={VerficationCode} alt="kki" />
      </div>
      <h6 className="mb-3">{Strings.VerifyMobileModal.HeadingText}</h6>
      <p>{Strings.VerifyMobileModal.ParaText}</p>
      <Col className="d-flex justify-content-center">
        <Col xl={8} md={10}>
          <Col className="verify-otp-sec d-flex mb-4">
            <KKIOtpInput numInputs={6} otp={otp} onOtpChange={onOtpChange} errorMessage={errorMessage} />
          </Col>
          <Col className="px-15">
            <Button variant="primary" type="submit" className="w-100" onClick={() => onOTPSubmit()}>
              {Strings.VerifyMobileModal.PrBtntText}
            </Button>
          </Col>
        </Col>
      </Col>
      <div className="timer-text text-center mt-4">
        {configureTimer()}
        {!showOtpTimer && (
          <a href="#" onClick={() => onOtpResend()}>
            {Strings.VerifyMobileModal.ResendText}
          </a>
        )}
      </div>
    </Modal.Body>
  );
};
export default VerifyMobileModal;
