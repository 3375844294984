import React from 'react';

const TimeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.695" height="14.695" viewBox="0 0 14.695 14.695">
        <path id="schedule_24dp_5F6368_FILL0_wght400_GRAD0_opsz24_2_" data-name="schedule_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 (2)" d="M88.082-872.947v-2.645a.711.711,0,0,0-.211-.523.711.711,0,0,0-.523-.211.711.711,0,0,0-.523.211.711.711,0,0,0-.211.523v2.921a.76.76,0,0,0,.055.285.734.734,0,0,0,.165.248l2.425,2.425a.7.7,0,0,0,.514.2.7.7,0,0,0,.514-.2.7.7,0,0,0,.2-.514.7.7,0,0,0-.2-.514Zm-.735,7.641a7.154,7.154,0,0,1-2.865-.579,7.419,7.419,0,0,1-2.333-1.571,7.419,7.419,0,0,1-1.57-2.333A7.154,7.154,0,0,1,80-872.653a7.154,7.154,0,0,1,.579-2.865,7.42,7.42,0,0,1,1.57-2.333,7.421,7.421,0,0,1,2.333-1.571A7.155,7.155,0,0,1,87.347-880a7.156,7.156,0,0,1,2.865.579,7.421,7.421,0,0,1,2.333,1.571,7.42,7.42,0,0,1,1.571,2.333,7.154,7.154,0,0,1,.579,2.865,7.154,7.154,0,0,1-.579,2.865,7.419,7.419,0,0,1-1.571,2.333,7.419,7.419,0,0,1-2.333,1.571A7.154,7.154,0,0,1,87.347-865.305ZM87.347-872.653Zm0,5.878a5.666,5.666,0,0,0,4.16-1.717,5.666,5.666,0,0,0,1.717-4.16,5.666,5.666,0,0,0-1.717-4.16,5.666,5.666,0,0,0-4.16-1.717,5.666,5.666,0,0,0-4.16,1.717,5.666,5.666,0,0,0-1.717,4.16,5.666,5.666,0,0,0,1.717,4.16A5.666,5.666,0,0,0,87.347-866.775Z" transform="translate(-80 880)" fill="#400286"/>
    </svg>
  );
};

export default TimeIcon;
