/**
 * @file   src\validations\manageHolidaySchema.ts
 * @brief  This file is responsible for defining holiday submission form validation schemas.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import { HOLIDAY_DATE_REQUIRED, HOLIDAY_NAME_REQUIRED, HOLIDAY_NAME_MIN, HOLIDAY_NAME_MAX } from '../messages/validationMessages';

// Holiday submission form validation schema.
export const ADD_HOLIDAY_SCHEMA = yup.object().shape({
  dateTime: yup.string().trim().required(HOLIDAY_DATE_REQUIRED),
  holiday: yup.string().trim().required(HOLIDAY_NAME_REQUIRED).min(3, HOLIDAY_NAME_MIN).max(50, HOLIDAY_NAME_MAX),
});
