import React from 'react';

const PendingIcon = () => {
  return (
    <svg id="approved_and_declined" data-name="approved and declined" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <circle id="Ellipse_109" data-name="Ellipse 109" cx="7" cy="7" r="7" fill="#f1ae3c" />
      <path
        id="Path_380"
        data-name="Path 380"
        d="M4611.205,2382.778l1-1h0"
        transform="translate(-1571.443 -4938.493) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_10397"
        data-name="Path 10397"
        d="M4611.205,2382.778l1-1h0"
        transform="translate(-1567.443 -4938.493) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PendingIcon;
