/**
 * @file   src\containers\ResetPassword.tsx
 * @brief  Reset Password component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, useCallback, useEffect, useNavigate, useState } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import PassowrdIcon from '../assests/icons/PasswordHide';
import PassowrdShow from '../assests/icons/PasswordShow';
import KKIPassword from '../components/KKIPassword';
import { IObject } from '../interfaces/generalInterface';
import { IResetPasswordProps, IResetPasswordRequest } from '../interfaces/commonInterface';
import { RESET_PASSWORD_SCHEMA } from '../validations/userSchema';
import { validateForm } from '../utils/validationHelper';
import { useAppDispatch, useAppSelector } from '../hooks';
import { resetPassword, revertAllCommonState, forgotPasswordOtpResend } from '../store/actions/commonAction';
import { RoutePath } from '../utils/enums';
import KKIInput from '../components/KKIInput';
import { DEFAULT_OTP_TIMER, HTTP_STATUS_200, NUMBER_30, NUMBER_6, PHONE_COUNTRY_CODE, STORAGE_USER } from '../utils/constants';
import { allowIntegerOnly, getDataFromStorage } from '../utils/helper';
import Timer from '../components/Timer';
import { RootState } from '../store';
import { MessageToaster } from '../utils/toastUtils';
import Loader from '../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const ResetPassword = ({ mobile }: IResetPasswordProps) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access role from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  const role = userInfo?.roleId || 0;

  // Access redux state variables.
  const {
    isForgotOtpResendLoading,
    isForgotOtpResendSuccess,
    forgotOtpResendErrorCode,
    forgotOtpResendMessage,
    forgotResendOtp,
    isResetPasswordLoading,
    isResetPasswordSuccess,
    resetPasswordErrorCode,
    resetPasswordMessage,
  } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showOtpTimer, setOtpTimerStatus] = useState<boolean>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllCommonState());
    };
  }, []);

  // Otp sent response state change.
  useEffect(() => {
    if (isForgotOtpResendSuccess && forgotOtpResendErrorCode === HTTP_STATUS_200 && forgotOtpResendMessage) {
      toast.toastSuccess(`${forgotOtpResendMessage}. OTP:- ${forgotResendOtp}`);
      setOtpTimerStatus(true);
    } else if (!isForgotOtpResendSuccess && forgotOtpResendErrorCode > HTTP_STATUS_200 && forgotOtpResendMessage) {
      toast.toastError(forgotOtpResendMessage);
    }
  }, [isForgotOtpResendSuccess, forgotOtpResendErrorCode, forgotOtpResendMessage, forgotResendOtp]);

  // Clear input fields.
  const clearInput = () => {
    setOtp('');
    setPassword('');
    setConfirmPassword('');
  };

  // Reset password api response side effect handling.
  useEffect(() => {
    if (isResetPasswordSuccess && resetPasswordErrorCode === HTTP_STATUS_200 && resetPasswordMessage) {
      toast.toastSuccess(resetPasswordMessage);
      clearInput();
      navigate(RoutePath.Login);
    } else if (!isResetPasswordSuccess && resetPasswordErrorCode > HTTP_STATUS_200 && resetPasswordMessage) {
      toast.toastError(resetPasswordMessage);
    }
  }, [isResetPasswordSuccess, resetPasswordErrorCode, resetPasswordMessage]);

  // Input password element change event handler.
  const onPasswordInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setPassword(value);
  };

  // Input confirm password element change event handler.
  const onConfirmPasswordInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setConfirmPassword(value);
  };

  // Reset password submission event.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const resetPasswordForm = {
      password,
      confirmPassword,
      otp,
    };

    const errorResult = await validateForm(resetPasswordForm, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorResult);

    if (Object.keys(errorResult).length === 0) {
      const resetPasswordRequest: IResetPasswordRequest = {
        secret_key: otp,
        password,
        roldId: Number(role),
      };
      dispatch(resetPassword(resetPasswordRequest));
    }
  };

  // Show/hide password.
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Show/hide confirm password.
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Otp input change event handler.
  const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);
  };

  // Otp resend method.
  const onOtpResend = () => {
    dispatch(forgotPasswordOtpResend({ phone: `${PHONE_COUNTRY_CODE}${mobile}`, isResend: true }));
  };

  // Bind timer element.
  const configureTimer = useCallback(() => {
    return (
      showOtpTimer && (
        <Timer
          timeLimit={process.env.OTP_RESEND_TIME ? Number(process.env.OTP_RESEND_TIME) : DEFAULT_OTP_TIMER}
          isActive={showOtpTimer}
          handleOtpLink={(buttonStatus: boolean) => setOtpTimerStatus(buttonStatus)}
        />
      )
    );
  }, [showOtpTimer]);

  return (
    <>
      <h3 className="text-center">{Strings.HD.ResetPassword}</h3>
      <h6 className="text-center">{Strings.Password.Reset}</h6>
      <form name="resetPasswordForm" onSubmit={onSubmit}>
        <div className="no-margin">
          <KKIInput
            id="OTP"
            name="OTP"
            type="text"
            label={Strings.Password.OTPLabel}
            placeholder={Strings.SetupProfile.EnterCode}
            value={otp}
            maxLength={NUMBER_6}
            onChange={onOtpChange}
            onKeyDown={allowIntegerOnly}
            alert={errorFields?.otp || ''}
          />
        </div>
        <div className="timer-text mb-3 text-end">
          <small>{configureTimer()}</small>
          {!showOtpTimer && (
            <a href="#" onClick={() => onOtpResend()}>
              {Strings.VerifyMobileModal.ResendText}
            </a>
          )}
        </div>

        <KKIPassword
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          icon={<PassowrdIcon />}
          showpass={showPassword ? <PassowrdIcon togglePassword={() => toggleShowPassword()} /> : <PassowrdShow togglePassword={() => toggleShowPassword()} />}
          placeholder={Strings.Password.NewPassword}
          onChange={onPasswordInputChange}
          alert={errorFields?.password || ''}
          maxLength={NUMBER_30}
        />
        <KKIPassword
          id="confirmPassword"
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          icon={<PassowrdIcon />}
          showpass={showConfirmPassword ? <PassowrdIcon togglePassword={() => toggleShowConfirmPassword()} /> : <PassowrdShow togglePassword={() => toggleShowConfirmPassword()} />}
          placeholder={Strings.Password.ConfirmPassword}
          onChange={onConfirmPasswordInputChange}
          alert={errorFields?.confirmPassword || ''}
          maxLength={NUMBER_30}
        />
        <Button variant="primary" type="submit" className="mb-30 w-100">
          {Strings.Button.ResetPassword}
        </Button>
      </form>
      <p className="existing-account-info text-center">
        <a href={void 0} className="info-link" onClick={() => navigate(RoutePath.Login)}>
          {Strings.Button.BackToSignIn}
        </a>
      </p>
      {(isForgotOtpResendLoading || isResetPasswordLoading) && <Loader />}
    </>
  );
};

export default ResetPassword;
