/**
 * @file   src\containers\Scheduler\Schedule\AssignCA.tsx
 * @brief  Assign CA page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { isShiftDateISHoliday, revertAllCommonState } from '../../../store/actions/commonAction';
import { Badge, Row, Col, Breadcrumb, useState, Button, useParams, useNavigate, useEffect, Modal, moment } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import AssignCACard from '../../../components/AssignCACard';
import EmployeeCard from '../../../components/EmployeeCard';
import KKISearch from '../../../components/KKISearch';
import KKISelect from '../../../components/KKISelect';
import { IAssignedOrUnassigedCAListRequest, ICA } from '../../../interfaces/scheduleInterface';
import { ColorGroups, ScheduleTabs, AdminRoutePath, NavigationToEmployeeCardFrom } from '../../../utils/enums';
import { ALL_TEXT, CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_2 } from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import { AT_LEAST_ONE_EMPLOYEE_REQUIRED } from '../../../messages/validationMessages';
import { IObject } from '../../../interfaces/generalInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { assignCA, getShiftDetails, revertAllScheduleState } from '../../../store/actions/manageCAScheduleAction';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { getAssignedOrUnassignedCAList, revertAllAdminScheduleState } from '../../../store/actions/manageAdminScheduleAction';
import { ICADetails } from '../../../interfaces/caInterface';

// Toast object creation.
const toast = new MessageToaster();
const AssignCA = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const { isShiftDetailsLoading, shiftDetails, isAssignShiftLoading, isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage } = useAppSelector(
    (state: RootState) => state.manageCASchedule,
  );

  const { isCaListSuccess, caListErrorCode, isCaListLoading, caList } = useAppSelector((state: RootState) => state.manageAdminSchedule);

  const { isShiftHolidayLoading, isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, isHoliday } = useAppSelector((state: RootState) => state.common);

  // Initialize component stat variables.
  const [selectedEmployees, setSelectedEmployees] = useState<ICA[]>([]);
  const [selectedColorGroup, setSelectedColorGroup] = useState<IObject>({ label: 'All', value: '0' });
  const [assignedCAList, setAssignedCAList] = useState<ICADetails[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [assignButtonPopup, showAssignButtonPopup] = useState<boolean>(false);
  const [holidayConfirmPopup, showHolidayConfirmPopup] = useState<boolean>(false);

  /* Access and set the location url parameters. */
  const shiftId: number = params.shiftId ? Number(params.shiftId) : 0;
  const type: number = params.shiftId ? Number(params.type) : 0;
  const caIdFromUrl: number = params.caId ? Number(params.caId) : 0;

  // Component initial loading.
  useEffect(() => {
    const requestParams =
      type === ScheduleTabs.MySchedule
        ? { userId: caIdFromUrl, shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }
        : { shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) };
    dispatch(getShiftDetails(requestParams));

    return () => {
      dispatch(revertAllAdminScheduleState());
      dispatch(revertAllScheduleState());
    };
  }, [isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200]);

  // Method to get assigned CA list
  const getCAList = () => {
    if (shiftDetails?.shiftId) {
      const requestParams: IAssignedOrUnassigedCAListRequest = {
        shiftId: shiftDetails?.shiftId,
        type: NUMBER_2,
        keyword: searchText,
        colorGroup: Number(selectedColorGroup.value),
      };
      dispatch(getAssignedOrUnassignedCAList(requestParams));
    }
  };

  // get Ca list api calling
  useEffect(() => {
    getCAList(); // Call the assigned CA List Api
  }, [shiftDetails, searchText, selectedColorGroup]);

  // Assigend CA state change side effect handling.
  useEffect(() => {
    if (isCaListSuccess && caListErrorCode === HTTP_STATUS_200 && caList) {
      setAssignedCAList(caList?.caList);
    }
  }, [isCaListSuccess, caListErrorCode, caList]);

  // Assign CA response state change.
  useEffect(() => {
    if (isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      toast.toastSuccess(assignShiftMessage);
      getCAList();
    } else if (!isAssignShiftSuccess && assignShiftErrorCode > HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      toast.toastError(assignShiftMessage);
    }
  }, [isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage]);

  // Shift holiday checking api response state change.
  useEffect(() => {
    if (isShiftHolidaySuccess && shiftHolidayErrorCode === HTTP_STATUS_200 && shiftHolidayMessage) {
      if (isHoliday) {
        showHolidayConfirmPopup(true);
      } else {
        showAssignButtonPopup(true);
      }
      dispatch(revertAllCommonState());
    } else if (!isShiftHolidaySuccess && shiftHolidayErrorCode > HTTP_STATUS_200 && shiftHolidayMessage) {
      toast.toastError(shiftHolidayMessage);
      dispatch(revertAllCommonState());
    }
  }, [isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, isHoliday]);

  // Handles the checkbox change event.
  const handleCheckboxChange = (isChecked: boolean, caDetails: ICADetails) => {
    const { caId, colorGroup, lightDuty } = caDetails;
    if (isChecked) {
      setSelectedEmployees((prevSelected) => [
        ...prevSelected,
        {
          caId,
          ColorCode: Number(colorGroup),
          OnCall: false,
          IsLD: lightDuty ?? false,
        },
      ]);
    } else {
      setSelectedEmployees((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
    }
  };

  //  Handles the "Assign" button click event. Shows an error if no employees are selected.
  const handleAssignClick = (): void => {
    if (selectedEmployees.length === 0) {
      toast.toastError(AT_LEAST_ONE_EMPLOYEE_REQUIRED);
      return;
    }
    if (shiftDetails?.startDate) {
      dispatch(isShiftDateISHoliday({ holidayDate: shiftDetails?.startDate?.split('T')[0] || '' }));
    } else {
      showAssignButtonPopup(true);
    }
  };

  // Handles the assign confirmation
  const onAssignConfirm = () => {
    if (shiftDetails) {
      const assignRequest = {
        Cas: selectedEmployees,
        shiftId: shiftDetails?.shiftId,
        shiftStart: shiftDetails?.shiftFromTime,
        currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
      };
      dispatch(assignCA(assignRequest));
      showAssignButtonPopup(false);
      showHolidayConfirmPopup(false);
    }
  };
  // Handles the onChange event for the color group dropdown.
  const handleColorGroupChange = (val: IObject) => {
    setSelectedColorGroup(val);
  };

  // CA search input field button click event handler.
  const onCASearch = (val: string) => {
    setSearchText(val);
  };

  // Backbutton navigation
  const BackButtonNavigation = () => {
    navigate(
      caIdFromUrl
        ? AdminRoutePath.SCHShiftDetailsByCAId.replace(':shiftId', shiftId.toString())
            .replace(':type', ScheduleTabs.MySchedule.toString())
            .replace(':caId', caIdFromUrl?.toString())
        : AdminRoutePath.SCHShiftDetails.replace(':shiftId', shiftId.toString()).replace(':type', Number(type).toString()),
    );
  };

  const colorGroup = [
    { label: ALL_TEXT, value: NUMBER_0 },
    { label: ColorGroups[ColorGroups.Yellow], value: ColorGroups.Yellow },
    { label: ColorGroups[ColorGroups.Blue], value: ColorGroups.Blue },
    { label: ColorGroups[ColorGroups.Red], value: ColorGroups.Red },
  ];

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href={AdminRoutePath.ManageSchedule}>{Strings.HD.ManageSchedules}</Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => {
              BackButtonNavigation();
            }}
          >
            {Strings.Shift.ShiftDetails.Header}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{Strings.HD.AssignCA}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>{Strings.HD.AssignCA}</h1>
          </Col>
        </Row>
      </div>
      <AssignCACard shiftDetails={shiftDetails} />
      <div className="filter-main mt-3">
        <Row className="justify-content-between">
          <Col xl={7} lg={9} md={11}>
            <Row>
              <Col xs="auto" className="d-flex">
                <Badge bg="secondary" className="badge-secondary">
                  {Strings.Label.Selected}: <span className="ps-1"> {selectedEmployees.length}</span>
                </Badge>
              </Col>
              <Col xs>
                <KKISearch placeholder={Strings.Input.SearchbyNameEmployeeID} onSearch={(param: string) => onCASearch(param)} />
              </Col>
              <Col lg={4} md={4} xs="auto" className="z-2">
                <Row className="d-flex align-items-center">
                  <Col xs="auto" className="pe-0">
                    <span>{Strings.Label.ColorGroup}</span>
                  </Col>
                  <Col className="pe-0">
                    <KKISelect
                      id="signupselect"
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Sort.Select}
                      searchvalue={false}
                      options={colorGroup}
                      value={selectedColorGroup}
                      onSelectChange={handleColorGroupChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg="auto" md={12} className="button-container-right m-md-16 text-end">
            <Button
              variant="outline-primary"
              type="submit"
              onClick={() => {
                BackButtonNavigation();
              }}
            >
              {Strings.Button.Back}
            </Button>

            <Button variant="primary" type="submit" onClick={handleAssignClick}>
              {Strings.Shift.Button.Assign}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        {assignedCAList &&
          assignedCAList?.length > NUMBER_0 &&
          assignedCAList.map((caData: ICADetails) => (
            <Col xl={6} lg={6} md={12} key={caData.caId}>
              <EmployeeCard
                caDetails={caData}
                shiftDetails={shiftDetails}
                isFrom={NavigationToEmployeeCardFrom.CA_Assign_Page}
                onCheckboxChange={handleCheckboxChange}
                selectedEmployees={selectedEmployees}
              />
            </Col>
          ))}
        {!assignedCAList || (assignedCAList && assignedCAList?.length === NUMBER_0 && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>)}
      </Row>

      <Modal show={assignButtonPopup} onHide={() => showAssignButtonPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.AssignCAsPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showAssignButtonPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={holidayConfirmPopup} onHide={() => showHolidayConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.HolidayConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showHolidayConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isShiftDetailsLoading || isCaListLoading || isAssignShiftLoading || isShiftHolidayLoading) && <Loader />}
    </div>
  );
};
export default AssignCA;
