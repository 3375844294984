/**
 * @file   src\validations\authSchema.ts
 * @brief  This file is responsible for defining auth validation schemas.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import 'yup-phone-lite';
import {
  COLOR_GROUP_REQUIRED,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  EMPLOYEE_ID_REQUIRED,
  FIRST_NAME_REQUIRED,
  HIRE_DATE_REQUIRED,
  LAST_NAME_REQUIRED,
  MOBILE_INVALID,
  MOBILE_REQUIRED,
  OLD_PASSWORD_REQUIRED,
  OTP_REQUIRED,
  PASSWORD_MATCH,
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_STRENGTH,
} from '../messages/validationMessages';
import { EMAIL_REGEX, NUMBER_10 } from '../utils/constants';

// Profile setup form validation schema.
export const PROFILE_SETUP_SCHEMA = yup.object({
  firstName: yup.string().trim().required(FIRST_NAME_REQUIRED),
  lastName: yup.string().trim().required(LAST_NAME_REQUIRED),
  email: yup.string().trim().matches(EMAIL_REGEX, EMAIL_INVALID).required(EMAIL_REQUIRED),
  mobile: yup.string().min(NUMBER_10, MOBILE_INVALID).required(MOBILE_REQUIRED),
  employeeId: yup.string().trim().required(EMPLOYEE_ID_REQUIRED),
  hireDate: yup.string().trim().required(HIRE_DATE_REQUIRED),
  colorGroup: yup.number().required(COLOR_GROUP_REQUIRED).min(1, COLOR_GROUP_REQUIRED),
});

// Reset password form validation schema.
export const RESET_PASSWORD_SCHEMA = yup.object({
  oldPassword: yup.string().trim().required(OLD_PASSWORD_REQUIRED),
  password: yup
    .string()
    .trim()
    .required(PASSWORD_REQUIRED)
    .matches(/^(?=.*\d)(?=.*[!@#$%^-_&*?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, PASSWORD_STRENGTH),
  confirmPassword: yup
    .string()
    .trim()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('password'), ''], PASSWORD_MATCH),
  otp: yup.string().trim().required(OTP_REQUIRED),
});

// Phone validation schema.
export const PHONE_VALIDATION_SCHEMA = yup.object({
  mobile: yup.string().min(NUMBER_10, MOBILE_INVALID).required(MOBILE_REQUIRED),
});
