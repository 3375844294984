/**
 * @file   src\store\index.ts
 * @brief  This file is responsible for configuring the redux store.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import manageCAScheduleReducer from './slices/manageCAScheduleSlice';
import manageSwapReducer from './slices/manageSwapSlice';
import manageCALeaveReducer from './slices/manageLeaveSlice';
import manageCAReducer from './slices/manageCASlice';
import manageCallOutReducer from './slices/manageCallOutShiftSlice';
import manageAdminScheduleReducer from './slices/manageAdminScheduleSlice';
import commonReducer from './slices/commonSlice';
import manageHolidayReducer from './slices/manageHolidaySlice';
import lightDutyReducer from './slices/lightDutySlice';
import reportReducer from './slices/reportSlice';

export const store = configureStore({
  reducer: {
    // Add reducer slices.
    auth: authReducer,
    user: userReducer,
    manageCASchedule: manageCAScheduleReducer,
    manageSwap: manageSwapReducer,
    manageLeave: manageCALeaveReducer,
    manageCA: manageCAReducer,
    manageCallOut: manageCallOutReducer,
    manageAdminSchedule: manageAdminScheduleReducer,
    common: commonReducer,
    manageHoliday: manageHolidayReducer,
    lightDuty: lightDutyReducer,
    report: reportReducer,
  },
  // Adding the api middlewares enables caching , invalidation, polling and other useful features of rtk query.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
