/**
 * @file   src\components\KKIInput.ts
 * @brief  Reusable input component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { React, Form, InputGroup, Button, useState, useEffect } from './ThirdPartyComponents';
import '../styles/KKISearch.scss';
import { IKKISearch } from '../interfaces/generalInterface';
import Search from '../assests/icons/Search';

const KKISearch = ({ placeholder, label, alert, onSearch, maxLength, value }: IKKISearch) => {
  // Initialize component state variables.
  const [query, setQuery] = useState<string>('');
  useEffect(() => {
    setQuery(value ?? '');
  }, [value]);
  // Handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    if (onSearch) {
      onSearch(query);
    }
  };

  // Handle Enter key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <div className="search-main">
      {label}
      <InputGroup>
        <Form.Control placeholder={placeholder} value={query} onChange={handleChange} onKeyDown={handleKeyPress} maxLength={maxLength} />
        <Button variant="outline-light" id="button-addon2" onClick={handleSearch}>
          <Search />
        </Button>
      </InputGroup>
      {alert && alert !== '' && <span className="error">{alert}</span>}
    </div>
  );
};
export default KKISearch;
