import React from 'react';

const DeclinedIcon = () => {
  return (
    <svg id="approved_and_declined" data-name="approved and declined" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <circle id="Ellipse_109" data-name="Ellipse 109" cx="7" cy="7" r="7" fill="#ee2d2d" />
      <path
        id="Path_380"
        data-name="Path 380"
        d="M4611.205,2387.528a.75.75,0,0,1-.53-1.28l5-5a.75.75,0,0,1,1.061,1.061l-5,5A.748.748,0,0,1,4611.205,2387.528Z"
        transform="translate(-4606.705 -2377.278)"
        fill="#fff"
      />
      <path
        id="Path_10396"
        data-name="Path 10396"
        d="M4616.205,2387.528a.748.748,0,0,1-.53-.22l-5-5a.75.75,0,0,1,1.061-1.061l5,5a.75.75,0,0,1-.53,1.28Z"
        transform="translate(-4606.705 -2377.278)"
        fill="#fff"
      />
    </svg>
  );
};

export default DeclinedIcon;
