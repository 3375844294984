/**
 * @file   src\validations\manageCASchema.ts
 * @brief  This file is responsible for defining CA form validation schemas.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import {
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  EMPLOYEE_ID_REQUIRED,
  FIRST_NAME_REQUIRED,
  HIRE_DATE_REQUIRED,
  LAST_NAME_REQUIRED,
  MOBILE_INVALID,
  MOBILE_REQUIRED,
  CA_STATUS_REQUIRED,
} from '../messages/validationMessages';
import { EMAIL_REGEX, NUMBER_10 } from '../utils/constants';

// CA form validation schema.
export const CA_EDIT_SCHEMA = yup.object({
  firstName: yup.string().trim().required(FIRST_NAME_REQUIRED),
  lastName: yup.string().trim().required(LAST_NAME_REQUIRED),
  email: yup.string().trim().matches(EMAIL_REGEX, EMAIL_INVALID).required(EMAIL_REQUIRED),
  phone: yup.string().min(NUMBER_10, MOBILE_INVALID).required(MOBILE_REQUIRED),
  empId: yup.string().trim().required(EMPLOYEE_ID_REQUIRED),
  hireDate: yup.string().trim().required(HIRE_DATE_REQUIRED),
  status: yup.number().required(CA_STATUS_REQUIRED).min(1, CA_STATUS_REQUIRED),
});
