/**
 *@file src\components\KKIDatepicker.tsx
 *@brief Reusable datepicker component
 *@date July, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

import DatePicker from 'react-datepicker';
import { IKKIDatepicker } from '../interfaces/generalInterface';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/KKIDatepicker.scss';

const KKIDatepicker = ({
  id,
  label,
  name,
  value,
  placeholder,
  onChangeDatepicker,
  alert,
  minDate,
  maxDate,
  disabled,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  timeIntervals,
}: IKKIDatepicker) => {
  // Handle the date value change
  const handleDateChange = (date: Date | null) => {
    if (date) {
      if (onChangeDatepicker) {
        onChangeDatepicker(date);
      }
      // Remove focus from the currently active element
      setTimeout(() => {
        const activeElement = document.activeElement as HTMLElement | null;
        if (activeElement && typeof activeElement.blur === 'function') {
          activeElement.blur(); // Remove focus if blur method exists
        }
      }, 10);
    }
  };

  return (
    <div className="kki-datepicker">
      <label htmlFor="datelabel" className="date-label">
        {label}
      </label>
      <DatePicker
        id={id}
        name={name}
        selected={value}
        onChange={handleDateChange}
        dateFormat={dateFormat}
        className={alert && alert !== '' ? 'error' : value !== '' ? '' : ''}
        placeholderText={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        onChangeRaw={(e) => {
          e?.preventDefault();
        }}
        onFocus={(e) => e.target.blur()}
      />
      {alert && alert !== '' && <span className="error">{alert}</span>}
    </div>
  );
};

export default KKIDatepicker;
