/**
 * @file   src\store\actions\manageHolidayAction.ts
 * @brief  This file is responsible for creating asynchronous api call for holiday functionalities.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { KKIActionTypes, ManageHolidayApi } from '../../utils/apiUrls';
import { IAddHolidayRequest } from '../../interfaces/holidaysInterface';

// Get holiday list api call
export const getAllHolidayList = createAsyncThunk('/manageHoliday/getAllHolidayList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageHolidayApi.GET_HOLIDAY_LIST}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Add holiday api call
export const addHoliday = createAsyncThunk('manageHoliday/addHoliday', async (holidayData: IAddHolidayRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageHolidayApi.ADD_HOLIDAY, holidayData);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete holiday api call
export const deleteHoliday = createAsyncThunk('manageHoliday/deleteHoliday', async (holidayIdId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${ManageHolidayApi.DELETE_HOLIDAY}/${holidayIdId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Edit holiday api call
export const editHoliday = createAsyncThunk('manageHoliday/editHoliday', async (request: IAddHolidayRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageHolidayApi.UPDATE_HOLIDAY, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all holiday states to initial state action.
export const revertAllHolidayState = createAction(KKIActionTypes.REVERT_ALL_HOLIDAY_STATE);
