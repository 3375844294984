/**
 * @file   src\containers\CA\Schedule\Home.tsx
 * @brief  Dashboard.
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Tabs, Tab, useParams, useState, useNavigate } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import ManageSHifts from './ManageShifts';
import MyRoster from './MyRoster';
import { ScheduleTabs } from '../../../utils/enums';

const Home = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Create route param object to access the route parameters.
  const params = useParams();
  const tabType: number = params.tabType ? Number(params.tabType) : 1;

  // Initialize component state variables.
  const [defaultTab, setDefaultTab] = useState<string>(ScheduleTabs[tabType] || ScheduleTabs[1]);

  // reset values when tabs change
  const resetValues = (key: string | null) => {
    setDefaultTab(key || ScheduleTabs[1]);
    navigate(".", { replace: true, state: null });
  };

  return (
    <div>
      <div className="pageheader">
        <h1>{Strings.HD.ManageSchedules}</h1>
      </div>
      <Tabs defaultActiveKey={defaultTab} id="uncontrolled-tab-example" className="mb-3" fill onSelect={(key) => resetValues(key)}>
        <Tab eventKey={ScheduleTabs[1]} title={Strings.HD.FullSchedule}>
          {defaultTab === ScheduleTabs[1] && <ManageSHifts />}
        </Tab>

        <Tab eventKey={ScheduleTabs[2]} title={Strings.HD.MySchedule}>
          {defaultTab === ScheduleTabs[2] && <MyRoster />}
        </Tab>
      </Tabs>
    </div>
  );
};
export default Home;
