import React from 'react';

const NightShiftIcon = () => {
  return (
    <svg id="Group_16066" data-name="Group 16066" xmlns="http://www.w3.org/2000/svg" width="17.813" height="18.77" viewBox="0 0 17.813 18.77">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_519" data-name="Rectangle 519" width="17.813" height="18.77" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_16065" data-name="Group 16065" clipPath="url(#clip-path)">
        <path
          id="Path_10363"
          data-name="Path 10363"
          d="M8.274-.21l.854.193A8.48,8.48,0,1,1,.18,12.941L-.3,12.213l.852.193a7.846,7.846,0,0,0,1.726.2,7.79,7.79,0,0,0,7.781-7.782,7.738,7.738,0,0,0-1.3-4.3ZM7.246,16.033A7.782,7.782,0,0,0,9.8.9a8.442,8.442,0,0,1,.959,3.919A8.491,8.491,0,0,1,2.275,13.3a8.374,8.374,0,0,1-1-.06A7.766,7.766,0,0,0,7.246,16.033Z"
          transform="translate(1.154 1.105)"
          fill="#400286"
        />
        <path
          id="Path_10364"
          data-name="Path 10364"
          d="M5.112,3.628a5.839,5.839,0,0,0,.975-.11c-.015-.028-.4-.28-.83-.557l.211-.968-.713.645-.728-.463.316.835-.8.725s.5-.031,1.054-.069l.325.862Z"
          transform="translate(2.154 1.649)"
          fill="#400286"
        />
        <path
          id="Path_10365"
          data-name="Path 10365"
          d="M6.657,7.865c-.015-.045-.556-.557-1.164-1.123l.7-1.394-1.415.733c-.56-.516-1.032-.947-1.032-.947l.215,1.371-1.6.828s.833.123,1.765.252L4.352,9l.655-1.3a10.286,10.286,0,0,0,1.65.162"
          transform="translate(1.772 2.674)"
          fill="#400286"
        />
        <path
          id="Path_10366"
          data-name="Path 10366"
          d="M1.278,3.406,1.047,4.3c-.438.041-.8.078-.8.078l.672.436L.654,5.82s.375-.312.789-.662l.694.451-.305-.782a6.133,6.133,0,0,0,.707-.647c-.025-.011-.455.024-.934.068Z"
          transform="translate(1.079 2.11)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default NightShiftIcon;
