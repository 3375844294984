/**
 * @file   src\validations\manageLdSchema.ts
 * @brief  This file is responsible for defining ld submission form validation schemas.
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import {
  EMPLOYEE_ID_REQUIRED,
  LD_FOLLOWUP_DATE_REQUIRED,
  LD_INJURY_TYPE_REQUIRED,
  LD_REASON_REQUIRED,
  LD_REASON_TYPE_REQUIRED,
  LD_REPORTING_DATE_REQUIRED,
} from '../messages/validationMessages';
import { NUMBER_10, NUMBER_9 } from '../utils/constants';

// Ld reasons form validation schema.
export const LD_REASON_SCHEMA = yup.object({
  empId: yup.number().required(EMPLOYEE_ID_REQUIRED),
  reportingDate: yup.string().trim().required(LD_REPORTING_DATE_REQUIRED),
  followUpDate: yup.string().trim().required(LD_FOLLOWUP_DATE_REQUIRED),
  reasonId: yup.number().required(LD_REASON_TYPE_REQUIRED).min(1, LD_REASON_TYPE_REQUIRED),
  reasonSubType: yup.number().when('reasonId', {
    is: NUMBER_9,
    then: (schema) => schema.required(LD_INJURY_TYPE_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if reasonId is false.
  }),
  description: yup.string().when('reasonId', {
    is: NUMBER_10,
    then: (schema) => schema.required(LD_REASON_REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  }),
});
