import React from 'react';

const DayShiftIcon = () => {
  return (
    <svg id="Group_16068" data-name="Group 16068" xmlns="http://www.w3.org/2000/svg" width="19.574" height="13.048" viewBox="0 0 19.574 13.048">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_520" data-name="Rectangle 520" width="19.574" height="13.048" fill="none" stroke="#400286" strokeWidth="0.7" />
        </clipPath>
      </defs>
      <g id="Group_16067" data-name="Group 16067" clipPath="url(#clip-path)">
        <path
          id="Path_10367"
          data-name="Path 10367"
          d="M15.1,11.711H4.414L4.38,11.4a5.543,5.543,0,0,1-.034-.6,5.413,5.413,0,0,1,10.825,0,5.543,5.543,0,0,1-.034.6Zm-10.052-.7h9.415c0-.072.005-.143.005-.213a4.712,4.712,0,0,0-9.425,0C5.046,10.868,5.048,10.939,5.051,11.011Z"
          transform="translate(0 0)"
          fill="#400286"
        />
        <path
          id="Line_137"
          data-name="Line 137"
          d="M16.615.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H16.615a.35.35,0,0,1,.35.35A.35.35,0,0,1,16.615.35Z"
          transform="translate(1.436 11.361)"
          fill="#400286"
        />
        <path
          id="Line_138"
          data-name="Line 138"
          d="M12.868.35H0A.35.35,0,0,1-.35,0,.35.35,0,0,1,0-.35H12.868a.35.35,0,0,1,.35.35A.35.35,0,0,1,12.868.35Z"
          transform="translate(2.793 12.835)"
          fill="#400286"
        />
        <path
          id="Line_139"
          data-name="Line 139"
          d="M2.979.749a.353.353,0,0,1-.047,0L-.046.347a.35.35,0,0,1-.3-.393.35.35,0,0,1,.393-.3l2.979.4a.35.35,0,0,1-.046.7Z"
          transform="translate(0.458 9.119)"
          fill="#400286"
        />
        <path
          id="Line_140"
          data-name="Line 140"
          d="M3.993,2.653a.348.348,0,0,1-.175-.047L-.175.3A.35.35,0,0,1-.3-.175.35.35,0,0,1,.175-.3L4.168,2a.35.35,0,0,1-.175.653Z"
          transform="translate(0.213 5.096)"
          fill="#400286"
        />
        <path
          id="Line_141"
          data-name="Line 141"
          d="M2.488,3.017a.349.349,0,0,1-.256-.111L-.256.239A.35.35,0,0,1-.239-.256a.35.35,0,0,1,.495.017L2.744,2.428a.35.35,0,0,1-.256.589Z"
          transform="translate(3.161 3.069)"
          fill="#400286"
        />
        <path
          id="Line_142"
          data-name="Line 142"
          d="M1.689,4.9a.35.35,0,0,1-.328-.228L-.328.122a.35.35,0,0,1,.206-.45.35.35,0,0,1,.45.206L2.017,4.423a.35.35,0,0,1-.328.472Z"
          transform="translate(5.925 0.213)"
          fill="#400286"
        />
        <path
          id="Line_143"
          data-name="Line 143"
          d="M0,.749a.35.35,0,0,1-.346-.3.35.35,0,0,1,.3-.393l2.979-.4a.35.35,0,0,1,.393.3.35.35,0,0,1-.3.393L.046.746A.353.353,0,0,1,0,.749Z"
          transform="translate(16.137 9.119)"
          fill="#400286"
        />
        <path
          id="Line_144"
          data-name="Line 144"
          d="M0,2.653a.35.35,0,0,1-.3-.175A.35.35,0,0,1-.175,2L3.818-.3A.35.35,0,0,1,4.3-.175.35.35,0,0,1,4.168.3L.175,2.606A.348.348,0,0,1,0,2.653Z"
          transform="translate(15.369 5.096)"
          fill="#400286"
        />
        <path
          id="Line_145"
          data-name="Line 145"
          d="M0,3.017a.349.349,0,0,1-.239-.094.35.35,0,0,1-.017-.495L2.232-.239a.35.35,0,0,1,.495-.017.35.35,0,0,1,.017.495L.256,2.906A.349.349,0,0,1,0,3.017Z"
          transform="translate(13.925 3.069)"
          fill="#400286"
        />
        <path
          id="Line_146"
          data-name="Line 146"
          d="M0,4.9a.349.349,0,0,1-.122-.022.35.35,0,0,1-.206-.45L1.361-.122a.35.35,0,0,1,.45-.206.35.35,0,0,1,.206.45L.328,4.667A.35.35,0,0,1,0,4.9Z"
          transform="translate(11.96 0.213)"
          fill="#400286"
        />
        <path
          id="Line_147"
          data-name="Line 147"
          d="M0,3.68a.35.35,0,0,1-.35-.35V0A.35.35,0,0,1,0-.35.35.35,0,0,1,.35,0V3.33A.35.35,0,0,1,0,3.68Z"
          transform="translate(9.759 1.073)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default DayShiftIcon;
