/**
 * @file   src\components\KKIInputMask.tsx
 * @brief  Reusable input mask component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import InputMask from 'react-input-mask';
import { InputPhoneMask } from '../interfaces/generalInterface';
import '../styles/KKIInputMask.scss';

const KKIInputMask = ({ onChange, type, alert, id, name, value, mask, placeholder, disabled, verified, label }: InputPhoneMask) => {
  const onInputMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  return (
    <div className="kki-inputmask">
      <label htmlFor="phone label">{label}</label>
      <InputMask
        className={alert && alert !== '' ? 'form-control error' : value !== '' ? `form-control${verified ? ' verified' : ''}` : 'form-control'}
        id={id}
        name={name}
        type={type}
        value={value}
        mask={mask}
        maskChar=""
        placeholder={placeholder}
        disabled={disabled}
        onChange={onInputMaskChange}
        autoComplete="off"
      />
      {alert && alert !== '' && <span className="error">{alert}</span>}
    </div>
  );
};

export default KKIInputMask;
