import React from 'react';

const PasswordIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
      <g id="lock" transform="translate(-2.5 -1.5)">
        <path
          id="Rectangle_541"
          data-name="Rectangle 541"
          d="M2-.5H13A2.5,2.5,0,0,1,15.5,2V7A2.5,2.5,0,0,1,13,9.5H2A2.5,2.5,0,0,1-.5,7V2A2.5,2.5,0,0,1,2-.5Zm11,9A1.5,1.5,0,0,0,14.5,7V2A1.5,1.5,0,0,0,13,.5H2A1.5,1.5,0,0,0,.5,2V7A1.5,1.5,0,0,0,2,8.5Z"
          transform="translate(3 9)"
          fill="#400286"
        />
        <path
          id="Path_10375"
          data-name="Path 10375"
          d="M15.4,9.322a.491.491,0,0,1-.5-.481V5.792a3.533,3.533,0,0,0-3.7-3.33,3.533,3.533,0,0,0-3.7,3.33V8.841a.5.5,0,0,1-1,0V5.792A4.515,4.515,0,0,1,11.2,1.5a4.515,4.515,0,0,1,4.7,4.292V8.841A.491.491,0,0,1,15.4,9.322Z"
          transform="translate(-0.83)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default PasswordIcon;
