/**
 * @file   src\containers\CA\ManageSwap\ManageSwap.tsx
 * @brief  filter and search section for manage swap page
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Tab, Tabs, useState, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import MyRequests from './MyRequests';
import IncomingRequests from './IncomingRequests';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { NUMBER_1, NUMBER_2, DEBOUNCE_DELAY, NUMBER_0, HTTP_STATUS_200 } from '../../../utils/constants';
import { getSwapRequests } from '../../../store/actions/manageSwapAction';
import { useDebounce } from '../../../hooks/useDebounce';
import { IObject } from '../../../interfaces/generalInterface';
import { RootState } from '../../../store';
import { MessageToaster } from '../../../utils/toastUtils';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const ManageSwap = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access the swap requests state and loading status from Redux store.
  const {
    swapRequests,
    isFetchSwapRequestLoading,
    isFetchSwapRequestSuccess,
    fetchSwapRequestErrorCode,
    fetchSwapRequestMessage,
    isSwapRequestStatusUpdateLoading,
    isSwapRequestStatusUpdateSuccess,
    swapRequestStatusUpdateErrorCode,
    swapRequestStatusUpdateMessage,
    isDeleteSwapRequestLoading,
    isDeleteSwapRequestSuccess,
    deleteSwapRequestErrorCode,
    deleteSwapRequestMessage,
    isResendSwapRequestLoading,
    isResendSwapRequestSuccess,
    resendSwapRequestErrorCode,
    resendSwapRequestMessage,
  } = useAppSelector((state: RootState) => state.manageSwap);

  // Initialize component state variables.
  const [activeTab, setActiveTab] = useState<string>('MyRequests');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<IObject | null>(null);

  // Debounce the search value
  const debouncedSearchValue = useDebounce(searchValue, DEBOUNCE_DELAY);

  // Fetch swap request api response state side effect handling.
  useEffect(() => {
    if (!isFetchSwapRequestSuccess && fetchSwapRequestErrorCode > HTTP_STATUS_200 && fetchSwapRequestMessage) {
      toast.toastError(fetchSwapRequestMessage);
    }
  }, [isFetchSwapRequestSuccess, fetchSwapRequestErrorCode, fetchSwapRequestMessage]);

  // Unified function to fetch swap requests
  const fetchSwapRequests = (searchText: string, filter: IObject | null) => {
    const type = activeTab === 'MyRequests' ? NUMBER_2 : NUMBER_1;
    const status = filter ? Number(filter.value) : NUMBER_0;
    dispatch(getSwapRequests({ type, status, searchText }));
  };

  // Swap status update api response state change.
  useEffect(() => {
    if (isSwapRequestStatusUpdateSuccess && swapRequestStatusUpdateErrorCode === HTTP_STATUS_200 && swapRequestStatusUpdateMessage) {
      fetchSwapRequests(debouncedSearchValue, filterStatus);
      toast.toastSuccess(swapRequestStatusUpdateMessage);
    } else if (!isSwapRequestStatusUpdateSuccess && swapRequestStatusUpdateErrorCode > HTTP_STATUS_200 && swapRequestStatusUpdateMessage) {
      toast.toastError(swapRequestStatusUpdateMessage);
    }
  }, [isSwapRequestStatusUpdateSuccess, swapRequestStatusUpdateErrorCode, swapRequestStatusUpdateMessage]);

  // Swap request delete api response state change.
  useEffect(() => {
    if (isDeleteSwapRequestSuccess && deleteSwapRequestErrorCode === HTTP_STATUS_200 && deleteSwapRequestMessage) {
      fetchSwapRequests(debouncedSearchValue, filterStatus);
      toast.toastSuccess(deleteSwapRequestMessage);
    } else if (!isDeleteSwapRequestSuccess && deleteSwapRequestErrorCode > HTTP_STATUS_200 && deleteSwapRequestMessage) {
      toast.toastError(deleteSwapRequestMessage);
    }
  }, [isDeleteSwapRequestSuccess, deleteSwapRequestErrorCode, deleteSwapRequestMessage]);

  // Swap request resend api response state change.
  useEffect(() => {
    if (isResendSwapRequestSuccess && resendSwapRequestErrorCode === HTTP_STATUS_200 && resendSwapRequestMessage) {
      fetchSwapRequests(debouncedSearchValue, filterStatus);
      toast.toastSuccess(resendSwapRequestMessage);
    } else if (!isResendSwapRequestSuccess && resendSwapRequestErrorCode > HTTP_STATUS_200 && resendSwapRequestMessage) {
      toast.toastError(resendSwapRequestMessage);
    }
  }, [isResendSwapRequestSuccess, resendSwapRequestErrorCode, resendSwapRequestMessage]);

  // Handle tab change
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  // Handle search input change
  const handleSearch = (query: string) => {
    setSearchValue(query);
  };

  // Handle filter change
  const handleApplyFilter = (statusFilterValue: IObject | null) => {
    setFilterStatus(statusFilterValue);
  };

  // Handle filter clear
  const handleResetFilter = () => {
    setFilterStatus(null);
  };

  // Effect to fetch data when debounced SearchValue changes
  useEffect(() => {
    fetchSwapRequests(debouncedSearchValue, filterStatus);
  }, [debouncedSearchValue, filterStatus, activeTab]);

  return (
    <div>
      <div className="pageheader">
        <h1>{Strings.HD.ManageSwaps}</h1>
      </div>
      <Tabs activeKey={activeTab} id="uncontrolled-tab-example" className="mb-3" fill onSelect={(key) => handleTabChange(key as string)}>
        <Tab eventKey="MyRequests" title={Strings.HD.MyRequests}>
          {activeTab === 'MyRequests' && (
            <MyRequests
              searchValue={searchValue}
              filterStatus={filterStatus}
              onSearch={handleSearch}
              onApplyFilter={handleApplyFilter}
              onResetFilter={handleResetFilter}
              swapRequests={swapRequests || []}
            />
          )}
        </Tab>
        <Tab eventKey="IncomingRequests" title={Strings.HD.IncomingRequests}>
          {activeTab === 'IncomingRequests' && (
            <IncomingRequests
              searchValue={searchValue}
              filterStatus={filterStatus}
              onSearch={handleSearch}
              onApplyFilter={handleApplyFilter}
              onResetFilter={handleResetFilter}
              swapRequests={swapRequests || []}
            />
          )}
        </Tab>
      </Tabs>
      {(isFetchSwapRequestLoading || isSwapRequestStatusUpdateLoading || isDeleteSwapRequestLoading || isResendSwapRequestLoading) && <Loader />}
    </div>
  );
};
export default ManageSwap;
