import React from 'react';

const ManageCAIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.887" height="26.774" viewBox="0 0 25.887 26.774">
      <g id="Group_16106" data-name="Group 16106" transform="translate(-50.112 -255)">
        <path
          id="Path_10360"
          data-name="Path 10360"
          d="M8794.68,280.344l-2.989-3.858.148-.284,1.885-3.6-2.446-4.553-5.876,5.876c-.959.959-1.4,1.842-1.266,2.552a2.022,2.022,0,0,0,1.032,1.266h5.531v1h-5.758l-.1-.043a3.08,3.08,0,0,1-1.691-2.041c-.2-1.064.322-2.222,1.542-3.441l6.827-6.827,2.763,5.141,2.612-4.991,4.289,2.294-.473.882-3.4-1.818-2.458,4.7,2.067,3.848Zm-1.806-3.963,1.683,2.171,1.223-2.127-1.483-2.761Z"
          transform="translate(-8733)"
          fill="#1b1658"
        />
        <path
          id="Ellipse_110"
          data-name="Ellipse 110"
          d="M5.5,1A4.5,4.5,0,1,0,10,5.5,4.505,4.505,0,0,0,5.5,1m0-1A5.5,5.5,0,1,1,0,5.5,5.5,5.5,0,0,1,5.5,0Z"
          transform="translate(56 255)"
          fill="#1b1658"
        />
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M-8725.72,11.774h-2.56l-.248-1.891a3.785,3.785,0,0,1-.71-.3,3.51,3.51,0,0,1-.651-.434l-1.832.741L-8733,7.767l1.585-1.137c-.02-.105-.035-.218-.051-.364s-.021-.264-.021-.366a3.315,3.315,0,0,1,.021-.346c.015-.134.031-.272.051-.41L-8733,4.006l1.28-2.1,1.819.728a4.592,4.592,0,0,1,.664-.438,3.974,3.974,0,0,1,.7-.3l.261-1.891h2.56l.25,1.9a4.149,4.149,0,0,1,.7.3,4.848,4.848,0,0,1,.621.427l1.872-.728,1.277,2.1-1.633,1.175a1.824,1.824,0,0,1,.063.373l0,.126c0,.07,0,.139,0,.207,0,.1-.008.211-.016.322a3.94,3.94,0,0,1-.06.41l1.609,1.148-1.282,2.126-1.839-.754a5.2,5.2,0,0,1-.645.441,3.335,3.335,0,0,1-.675.289l-.25,1.9ZM-8727,2a4,4,0,0,0-4,4,4,4,0,0,0,4,4,4,4,0,0,0,4-4A4,4,0,0,0-8727,2Z"
          transform="translate(8797 270)"
          fill="#1b1658"
        />
      </g>
    </svg>
  );
};

export default ManageCAIcon;
