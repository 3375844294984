/**
 * @file   src\index.tsx
 * @brief  This file is used to mount/render your main react component onto root element.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Provider, ReactDOM, ToastContainer } from './components/ThirdPartyComponents';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource-variable/inter';
import '@fontsource-variable/inter/wght.css';

import './styles/main.scss';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
);
