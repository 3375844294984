/**
 * @file   src\validations\authSchema.ts
 * @brief  This file is responsible for defining auth validation schemas.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import 'yup-phone-lite';
import { MOBILE_INVALID, MOBILE_REQUIRED, PASSWORD_STRENGTH, PASSWORD_MATCH, PASSWORD_REQUIRED, ROLE_REQUIRED, SUBSCRIBE_REQUIRED } from '../messages/validationMessages';
import { NUMBER_1, NUMBER_10 } from '../utils/constants';

// SignIn form validation schema.
export const SIGNIN_SCHEMA = yup.object({
  roleId: yup.number().required(ROLE_REQUIRED).min(NUMBER_1, ROLE_REQUIRED),
  phone: yup.string().min(NUMBER_10, MOBILE_INVALID).required(MOBILE_REQUIRED),
  password: yup.string().trim().required(PASSWORD_REQUIRED),
});

// SignUp form validation schema.
export const SIGNUP_SCHEMA = yup.object({
  role: yup.number().required(ROLE_REQUIRED).min(NUMBER_1, ROLE_REQUIRED),
  phone: yup.string().min(NUMBER_10, MOBILE_INVALID).required(MOBILE_REQUIRED),
  password: yup
    .string()
    .trim()
    .required(PASSWORD_REQUIRED)
    .matches(/^(?=.*\d)(?=.*[!@#$%^-_&*?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, PASSWORD_STRENGTH),
  confirmPassword: yup
    .string()
    .trim()
    .required(PASSWORD_REQUIRED)
    .oneOf([yup.ref('password'), ''], PASSWORD_MATCH),
  subscribe: yup
    .boolean()
    .oneOf([true], SUBSCRIBE_REQUIRED)
    .required(SUBSCRIBE_REQUIRED)
});
