/**
 * @file   src\containers\Signup.tsx
 * @brief  Signup page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Button, useState, Modal, useNavigate, useEffect, Row } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import KKISelect from '../components/KKISelect';
import KKIInputMask from '../components/KKIInputMask';
import KKIPassword from '../components/KKIPassword';
import KKICheckbox from '../components/KKICheckbox';
import PassowrdIcon from '../assests/icons/PasswordHide';
import PassowrdShow from '../assests/icons/PasswordShow';
import TopLogo from '../assests/images/logo.svg';
import VerifyMobileNoModal from '../components/VerifyMobileModal';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RoleTypes, RoutePath } from '../utils/enums';
import { IObject } from '../interfaces/generalInterface';
import { IUserSignUpRequest } from '../interfaces/authInterface';
import { IForgotPasswordOtpSendRequest } from '../interfaces/commonInterface';
import { SIGNUP_SCHEMA } from '../validations/authSchema';
import { validateForm } from '../utils/validationHelper';
import { adminSignUp, revertAllAuthState, signUp } from '../store/actions/authAction';
import { revertAllCommonState, sendOtp, verifyOtp } from '../store/actions/commonAction';
import { RootState } from '../store';
import { setStorageData } from '../utils/helper';
import { HTTP_STATUS_200, NUMBER_30, PHONE_COUNTRY_CODE, STORAGE_ROLE, STORAGE_USER } from '../utils/constants';
import { MessageToaster } from '../utils/toastUtils';

// Toast object creation.
const toast = new MessageToaster();

const Signup = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const { isSignUpSucess, signUpErrorCode, signUpMessage, signUpInfo } = useAppSelector((state: RootState) => state.auth);
  const { isOtpSendSuccess, otpSendErrorCode, otpSendMessage, isVerifyOtpSuccess, verifyOtpErrorCode, verifyOtpMessage } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [role, setRole] = useState<IObject | null>({ label: RoleTypes[RoleTypes.CA], value: RoleTypes.CA.toString() });
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject>({});
  const [showVerifyMobileModal, setShowVerifyMobileModal] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [showOtpTimer, setOtpTimerStatus] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(false);

  // Otp sent response state change.
  useEffect(() => {
    if (isOtpSendSuccess && otpSendErrorCode === HTTP_STATUS_200 && otpSendMessage) {
      toast.toastSuccess(otpSendMessage);
      setShowVerifyMobileModal(true);
      setOtpTimerStatus(true);
    } else if (!isOtpSendSuccess && otpSendErrorCode > HTTP_STATUS_200 && otpSendMessage) {
      toast.toastError(otpSendMessage);
    }
  }, [isOtpSendSuccess, otpSendErrorCode, otpSendMessage]);

  // Otp verify api response state side effect handling.
  useEffect(() => {
    if (isVerifyOtpSuccess && verifyOtpErrorCode === HTTP_STATUS_200 && verifyOtpMessage) {
      toast.toastSuccess(verifyOtpMessage);
      setShowVerifyMobileModal(false);
      setIsVerified(true);
      setShowSaveButton(true);
    } else if (!isVerifyOtpSuccess && verifyOtpErrorCode > HTTP_STATUS_200 && verifyOtpMessage) {
      toast.toastError(verifyOtpMessage);
    }
  }, [isVerifyOtpSuccess, verifyOtpErrorCode, verifyOtpMessage]);

  // Effect hook to handle navigation and toast notifications based on CA/ Scheduler signup state.
  useEffect(() => {
    if (isSignUpSucess && signUpErrorCode === HTTP_STATUS_200 && signUpMessage && signUpInfo) {
      const updatedSignUpInfo = { ...signUpInfo, is_profile_completed: true };
      localStorage.setItem(STORAGE_USER, JSON.stringify(updatedSignUpInfo));
      navigate(RoutePath.SetupProfile);
    } else if (!isSignUpSucess && signUpErrorCode > HTTP_STATUS_200 && signUpMessage) {
      toast.toastError(signUpMessage);
    }
  }, [isSignUpSucess, signUpErrorCode, signUpMessage, signUpInfo, navigate]);

  // Component unmound loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllCommonState());
      dispatch(revertAllAuthState());
    };
  }, []);

  // Role select change event handler.
  const onRoleSelectChange = async (val: IObject) => {
    const errorresult = await validateForm({ role: val?.value ? Number(val.value) : RoleTypes.Role }, SIGNUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setStorageData(STORAGE_ROLE, JSON.stringify(val.value));
    setRole(val);
  };

  // Input password element change event handler.
  const onPasswordInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ password: value }, SIGNUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setPassword(value);
  };

  // Input confirm password element change event handler.
  const onConfirmPasswordInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ password, confirmPassword: value }, SIGNUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setConfirmPassword(value);
  };

  // Input mobile element change event handler.
  const onMobileInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    const errorresult = await validateForm({ phone: inputVal }, SIGNUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setPhone(inputVal);
    setIsVerified(false);
    setShowSaveButton(false);
  };

  // SignUp form submission handler.
  const onFormSubmission = async (evt: any) => {
    evt.preventDefault();
    if (showSaveButton) {
      setShowVerifyMobileModal(false);
      const signUpRequest: IUserSignUpRequest = {
        roleId: role?.value ? Number(role.value) : RoleTypes.Role,
        phone: `${PHONE_COUNTRY_CODE}${phone}`,
        password,
        isSubscribed: subscribed
      };
      if (Number(role?.value) === RoleTypes.CA) {
        dispatch(signUp(signUpRequest));
      } else {
        dispatch(adminSignUp(signUpRequest));
      }
    } else {
      evt.preventDefault();
      const signUpForm = {
        phone,
        role: role?.value ? Number(role.value) : RoleTypes.Role,
        password,
        confirmPassword,
        subscribe: subscribed
      };
      const errorResult = await validateForm(signUpForm, SIGNUP_SCHEMA, errorFields);
      setErrorFields(errorResult);
      if (Object.keys(errorResult).length === 0) {
        const sendOtpRequest: IForgotPasswordOtpSendRequest = {
          phone: `${PHONE_COUNTRY_CODE}${phone}`,
        };
        dispatch(sendOtp(sendOtpRequest));
      }
    }
  };

  // Otp verification submission event.
  const onVerifyOtpSubmit = (otp: string) => {
    dispatch(verifyOtp({ otp, phone: `${PHONE_COUNTRY_CODE}${phone}` }));
  };

  // Otp resend method.
  const onResend = () => dispatch(sendOtp({ phone: `${PHONE_COUNTRY_CODE}${phone}`, isResend: true }));

  // Show/hide password.
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Show/hide confirm password.
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Subscription checkbox change event handler.
  const onSubscriptionCheckChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const errorresult = await validateForm({ subscribe: checked }, SIGNUP_SCHEMA, errorFields);
    console.log(errorresult)
    setErrorFields(errorresult);
    setSubscribed(checked);
  };

  const roles = [
    { label: RoleTypes[RoleTypes.CA], value: RoleTypes.CA },
    { label: RoleTypes[RoleTypes.Scheduler], value: RoleTypes.Scheduler },
  ];

  return (
    <>
      <Col className="cs-form-sec" xl={8} lg={8} md={10} sm={11} xs={10}>
        <div className="kki-logo-wrap">
          <img src={TopLogo} alt="kki logo" />
        </div>
        <h3 className="text-center">{Strings.CsSignup.Signup}</h3>
        <form name="signupform" onSubmit={onFormSubmission}>
          <KKISelect
            id="signupselect"
            name="signupselect"
            className="custom-select"
            onSelectChange={onRoleSelectChange}
            placeholder={Strings.CsSignup.PlaceHolder}
            searchvalue={false}
            options={roles}
            value={role}
            alert={errorFields?.role || ''}
          />
          <KKIPassword
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            icon={<PassowrdIcon />}
            showpass={showPassword ? <PassowrdIcon togglePassword={() => toggleShowPassword()} /> : <PassowrdShow togglePassword={() => toggleShowPassword()} />}
            placeholder={Strings.Password.NewPassword}
            onChange={onPasswordInputChange}
            alert={errorFields?.password || ''}
            autocomplete="new-password"
            maxLength={NUMBER_30}
          />
          <KKIPassword
            id="confirmPassword"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            icon={<PassowrdIcon />}
            showpass={
              showConfirmPassword ? <PassowrdIcon togglePassword={() => toggleShowConfirmPassword()} /> : <PassowrdShow togglePassword={() => toggleShowConfirmPassword()} />
            }
            placeholder={Strings.CsSignup.ConfirmPassword}
            onChange={onConfirmPasswordInputChange}
            alert={errorFields?.confirmPassword || ''}
            autocomplete="new-password"
            maxLength={NUMBER_30}
          />
          <KKIInputMask
            id="mobnumber"
            name="phone"
            type="text"
            mask="999-999-9999"
            placeholder={Strings.CsSignup.MobileNumber}
            onChange={onMobileInputChange}
            alert={errorFields?.phone || ''}
            verified={isVerified}
          />
          <KKICheckbox id="signup" name="subscribe" checkBoxType="checkbox" label="I agree to KKI Terms & Conditions and Privacy Policy. I also consent to receive KKI scheduling related SMS notifications." checked={subscribed} onChange={onSubscriptionCheckChange} error={errorFields?.subscribe ? true : false} />

          <Row className="mt-3">
            <Col xs={5} className="pe-0">
              <Button variant="outline-primary" type="button" className="mb-30 w-100" onClick={() => navigate(RoutePath.Login)}>
                {Strings.CsSignup.Button.SrBtntText}
              </Button>
            </Col>
            <Col>
              {!showSaveButton && (
                <Button variant="primary" type="submit" className="mb-30 w-100">
                  {Strings.CsSignup.Button.PrBtntText}
                </Button>
              )}
              {showSaveButton && (
                <Button variant="primary" type="submit" className="mb-30 w-100">
                  {Strings.CsSignup.Button.SaveAndContinue}
                </Button>
              )}
            </Col>
          </Row>
        </form>
        <p className="existing-account-info text-center">
          {Strings.CsSignup.AccountPara}{' '}
          <a href={void 0} className="info-link" onClick={() => navigate(RoutePath.Login)}>
            {Strings.CsSignup.AccountLink}
          </a>
        </p>
      </Col>

      <Modal show={showVerifyMobileModal} onHide={() => setShowVerifyMobileModal(false)} centered className="verify-mob-modal" backdrop="static">
        <Modal.Header closeButton className="bg-transparent" />
        <VerifyMobileNoModal onSubmit={(otp) => onVerifyOtpSubmit(otp)} onResend={onResend} showOtpTimer={showOtpTimer} setTimerStatus={(val) => setOtpTimerStatus(val)} />
      </Modal>
    </>
  );
};

export default Signup;
