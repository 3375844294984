/**
 * @file   src\store\actions\lightDutyAction.ts
 * @brief  This file is responsible for creating asynchronous api call for lightDuty slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { ILDListRequest, ILDreasonRequest } from '../../interfaces/lightdutyInterface';
import { ManageLDApi, KKIActionTypes } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Get all light duty users api call
export const getAllLDUsers = createAsyncThunk('/lightDuty/getAllLDUsers', async (request: ILDListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageLDApi.GET_ALL_LD_USERS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all light duty reasons api call
export const getLdReasons = createAsyncThunk('/lightDuty/getLdReasons', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ManageLDApi.GET_LD_REASONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Report light duty reason api call.
export const reportLDreason = createAsyncThunk('/lightDuty/reportLDreason', async (request: ILDreasonRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageLDApi.REPORT_LD_REASON, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Export ld reasons list api call
export const exportLDReasonList = createAsyncThunk('/lightDuty/exportLDReasonList', async (request: ILDListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageLDApi.EXPORT_LD_REASON_LIST}?${buildQueryString(request)}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all light duty states to initial state action.
export const revertAllLDState = createAction(KKIActionTypes.REVERT_ALL_LD_STATE);
