/**
 * @file   src\containers\NotFound.tsx
 * @brief  Page not found page.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Button, useNavigate } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import NotFound from '../assests/images/not-found.svg';
import { HTTP_STATUS_404, STORAGE_USER } from '../utils/constants';
import { getDataFromStorage } from '../utils/helper';
import { CARoutePath, RoleTypes, RoutePath, AdminRoutePath } from '../utils/enums';

const PageNotFound = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  const userInfo = getDataFromStorage(STORAGE_USER);
  const isAuthenticated = userInfo?.access_token !== '' && userInfo?.is_profile_completed === true;

  return (
    <Col className="not-found-main">
      <div className="text-center">
        <img src={NotFound} alt="kki" width={200} />
        <h1>{HTTP_STATUS_404}</h1>
        <h4>{Strings.PageNotFound.Header}</h4>
        <h6>{Strings.PageNotFound.Message}</h6>
        {isAuthenticated && (
          <Button
            variant="primary"
            onClick={() => {
              navigate(userInfo.roleId === RoleTypes.CA ? CARoutePath.Home : AdminRoutePath.ManageSchedule);
            }}
          >
            {Strings.PageNotFound.Button.Home}
          </Button>
        )}
        {!isAuthenticated && (
          <Button variant="primary" onClick={() => navigate(RoutePath.Login)}>
            {Strings.PageNotFound.Button.Login}
          </Button>
        )}
      </div>
    </Col>
  );
};

export default PageNotFound;
