import React from 'react';

const PublishIcon = () => {
  return (
    <svg id="approved_and_declined" data-name="approved and declined" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <circle id="Ellipse_109" data-name="Ellipse 109" cx="7" cy="7" r="7" fill="#5fb321" />
      <path
        id="Path_380"
        data-name="Path 380"
        d="M4609.2,2383.568l2.005,2.006,3.8-3.8"
        transform="translate(-4605.09 -2376.676)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PublishIcon;
