/**
 * @file   src\hooks\useDebounce.ts
 * @brief  This debounce hook to delay the update of a value until after a specified delay.
 * @date   Jul, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { useState, useEffect } from '../components/ThirdPartyComponents';

export const useDebounce = (value: any, delay: number) => {
  // Initialize component state variables.
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Set a timeout to update the debounced value after the specified delay.
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
