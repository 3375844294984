import React from 'react';

const PDFIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
      <g id="_9055322_bxs_file_pdf_icon" data-name="9055322_bxs_file_pdf_icon" transform="translate(-4 -2)">
        <path
          id="Path_10423"
          data-name="Path 10423"
          d="M8.267,14.68a1.5,1.5,0,0,0-.372.036v1.178a1.362,1.362,0,0,0,.3.023c.479,0,.774-.242.774-.651C8.971,14.9,8.717,14.68,8.267,14.68Zm3.487.012a1.845,1.845,0,0,0-.407.036v2.61a1.633,1.633,0,0,0,.313.018,1.236,1.236,0,0,0,1.349-1.4,1.144,1.144,0,0,0-1.255-1.268Z"
          fill="#400286"
        />
        <path id="Path_10424" data-name="Path 10424" d="M14,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8Zm0,7H13V4l5,5Z" fill="#400286" />
      </g>
    </svg>
  );
};

export default PDFIcon;
