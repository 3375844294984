/**
 * @file   src\containers\Manager\Report\AttendanceReport.tsx
 * @brief  Attendance Report page
 * @date   Oct, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Table, Button, useState, moment, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import PDFIcon from '../../../assests/icons/PDF';
import Sort from '../../../assests/icons/Sort';
import KKIDatepicker from '../../../components/KKIDatepicker';
import Pagination from '../../../components/Paginate';
import { MessageToaster } from '../../../utils/toastUtils';
import { DATE_END, DATE_FORMAT_DD_MMM_YYYY_HHMMA, DATE_FORMAT_YYYY_MM_DD, DATE_START, HTTP_STATUS_200, NUMBER_0, NUMBER_1, PAGE_SIZE } from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { exportAttendance, getCaAttendanceReport, revertAllReportState } from '../../../store/actions/reportAction';
import { IAttendanceReportRequest, IAttendanceRportList } from '../../../interfaces/reportInterface';
import { convertFloatToTime, getEnumKeyByValue, stringFormat } from '../../../utils/helper';
import SortDownIcon from '../../../assests/icons/SortDown';
import SortUpIcon from '../../../assests/icons/SortUp';
import { AttendanceReportSortField, NoShow, SortOrderType } from '../../../utils/enums';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();
// Define item count to be displayed per page.
const pageItemCount: number = PAGE_SIZE;

const AttendanceReport = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    isFetchAttendanceReportLoading,
    attendanceData,
    totalAttendance,
    isExportAttendanceLoading,
    isExportAttendanceSuccess,
    exportAttendanceErrorCode,
    exportAttendanceMessage,
    pdfData
  } = useAppSelector((state: RootState) => state.report);

  // Initialize component state variables.
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(moment().toDate());
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(NUMBER_1);
  const [sortBy, setSortBy] = useState<string>(AttendanceReportSortField[AttendanceReportSortField.caName]);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);

  // Method to fetch attendance report.
  const fetchAttendanceReportList = () => {
    const attendanceReportRequest: IAttendanceReportRequest = {
      startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      page: pageIndex,
      limit: pageSize,
      sort: sortBy,
      order: SortOrderType[sortOrder],
    };
    dispatch(getCaAttendanceReport(attendanceReportRequest));
  };

  // Fetch attendance report when the dependent field changes.
  useEffect(() => {
    // Get attendance report list.
    fetchAttendanceReportList();
  }, [pageIndex, sortBy, sortOrder]);

  // Export pdf api response state change handling.
  useEffect(() => {
    if (isExportAttendanceSuccess && pdfData) {
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element, set download attributes, and trigger a click
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'AttendanceReport.pdf'); // Set the PDF filename
      document.body.appendChild(link);
      link.click();
      toast.toastSuccess(Strings.Text.AttendanceExportSuccessText);
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      dispatch(revertAllReportState());
    }
    else if (!isExportAttendanceSuccess && exportAttendanceErrorCode > HTTP_STATUS_200 && exportAttendanceMessage) {
      toast.toastError(exportAttendanceMessage);
      dispatch(revertAllReportState());
    }
  }, [isExportAttendanceSuccess, exportAttendanceErrorCode, exportAttendanceMessage, pdfData]);

  // Start/End date filter input select event handler.
  const handleDateChange = (date: Date | null, type: string) => {
    if (type === DATE_START) {
      setStartDateSelected(date);
      if (endDateSelected && date && date > endDateSelected) {
        setEndDateSelected(date);
      }
    } else {
      setEndDateSelected(date);
    }
  };

  // Sort header change event.
  const handleSort = (field: string) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortBy) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    console.log(field);
    console.log(newSortOrder);
    setSortBy(field);
    setSortOrder(newSortOrder);
  };

  // Generate report button click handler.
  const onGenerateReport = () => {
    fetchAttendanceReportList();
  }

  // Handle the "Export as PDF" button click event.
  const handleExportPDFClick = (): void => {
    if (startDateSelected && endDateSelected) {
      dispatch(exportAttendance({
        startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
        endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      }));
    }
  };

  return (
    <div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.CAAttendanceReport}</h1>
          </Col>
        </Row>
      </div>
      <div className="filter-main mb-3">
        <Row className="d-flex justify-content-between">
          <Col xl={6} md={7} sm={12}>
            <Row className="align-items-center">
              <Col xs="auto" className="px-3">
                {Strings.Schedule.DateFrom}
              </Col>
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="startDate"
                  name="startDate"
                  placeholder={Strings.Label.StartDate}
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_START)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker
                  id="endDate"
                  name="endDate"
                  placeholder={Strings.Label.EndDate}
                  value={endDateSelected}
                  dateFormat="dd MMM yyyy"
                  minDate={startDateSelected || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_END)}
                />
              </Col>
              <Col md="auto" className="ps-0">
                <Button variant="primary" type='submit' onClick={onGenerateReport}>{Strings.Button.GenerateReport}</Button>
              </Col>
            </Row>
          </Col>
          <Col md="auto">
            <Button variant="outline-primary" type="button" onClick={handleExportPDFClick}>
              <PDFIcon />
              {Strings.Button.ExportasPDF}
            </Button>
          </Col>
        </Row>
      </div>

      <div className="table-border">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => handleSort(AttendanceReportSortField[AttendanceReportSortField.caName])} style={{ cursor: 'pointer' }}>
                {Strings.Shift.CA}
                <span className="ms-1">
                  {sortBy !== AttendanceReportSortField[AttendanceReportSortField.caName] && <Sort />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.caName] && sortOrder === SortOrderType.DESC && <SortDownIcon />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.caName] && sortOrder === SortOrderType.ASC && <SortUpIcon />}
                </span>
              </th>
              <th onClick={() => handleSort(AttendanceReportSortField[AttendanceReportSortField.isNoShow])} style={{ cursor: 'pointer' }}>
                {Strings.Label.AttendanceType}
                <span className="ms-1">
                  {sortBy !== AttendanceReportSortField[AttendanceReportSortField.isNoShow] && <Sort />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.isNoShow] && sortOrder === SortOrderType.DESC && <SortDownIcon />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.isNoShow] && sortOrder === SortOrderType.ASC && <SortUpIcon />}
                </span>
              </th>
              <th onClick={() => handleSort(AttendanceReportSortField[AttendanceReportSortField.shiftName])} style={{ cursor: 'pointer' }}>
                {Strings.Label.Attendance}
                <span className="ms-1">
                  {sortBy !== AttendanceReportSortField[AttendanceReportSortField.shiftName] && <Sort />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.shiftName] && sortOrder === SortOrderType.DESC && <SortDownIcon />}
                  {sortBy === AttendanceReportSortField[AttendanceReportSortField.shiftName] && sortOrder === SortOrderType.ASC && <SortUpIcon />}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {attendanceData && attendanceData.length > NUMBER_0 && attendanceData.map((att: IAttendanceRportList) => <tr>
              <td>{att.caName}</td>
              <td>{`${getEnumKeyByValue(NoShow, att.isNoShow)} (${convertFloatToTime(att.noShowHours || NUMBER_0)} Hrs)`}</td>
              <td>{`${att.shiftName} (${att.shortName}) ${(att.checkInTime && att.checkOutTime) ? `${moment(att.checkInTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)} - ${moment(att.checkOutTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)}` : ''}`}</td>
            </tr>)}
            {!attendanceData ||
              (attendanceData && attendanceData.length === NUMBER_0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    {Strings.Text.NoDataFound}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <span>{attendanceData && attendanceData.length > NUMBER_0 && totalAttendance > 0 ? stringFormat(Strings.Text.AttendanceReportPaginationText, attendanceData.length > NUMBER_0 ? ((totalAttendance === NUMBER_1 ? NUMBER_1 : pageIndex) - NUMBER_1) * pageSize + NUMBER_1 : NUMBER_0,
          ((totalAttendance === NUMBER_1 ? NUMBER_1 : pageIndex) - NUMBER_1) * pageSize + attendanceData.length,
          totalAttendance) : ''}</span>
        {attendanceData && attendanceData.length > NUMBER_0 && totalAttendance > pageSize && (
          <Pagination totalCount={totalAttendance} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - NUMBER_1} prevPage={-1} />
        )}
      </div>
      {isFetchAttendanceReportLoading || isExportAttendanceLoading && <Loader />}
    </div>
  );
};
export default AttendanceReport;
