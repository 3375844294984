/**
 * @file   src\containers\CA\Leave\ManageLeave.tsx
 * @brief  Leave status and Leave request page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { useState } from 'react';
import { Col, Row, Card, Button, Filter, Modal, moment, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import KKISelect from '../../../components/KKISelect';
import KKIInput from '../../../components/KKIInput';
import LeaveCard from '../../../components/LeaveCard';
import Close from '../../../assests/icons/Close';
import KKIDatepicker from '../../../components/KKIDatepicker';
import { IApplyLeaveRequest, IGetAllLeaveRequest, ILeaveDetails } from '../../../interfaces/leaveInterface';
import { LeaveStatus, LeaveTypes, RoleTypes } from '../../../utils/enums';
import { NUMBER_0, ALL_TEXT, NUMBER_1, DATE_START, DATE_FORMAT_YYYY_MM_DD, TIME_STARTS_FORMAT, DATE_END, HTTP_STATUS_200, NUMBER_250 } from '../../../utils/constants';
import { IObject } from '../../../interfaces/generalInterface';
import { getDateDifference } from '../../../utils/helper';
import { validateForm } from '../../../utils/validationHelper';
import { LEAVE_SUBMISSION_SCHEMA } from '../../../validations/manageLeaveSchema';
import { applyLeave, getAllLeaves, revertAllLeaveState } from '../../../store/actions/manageLeaveAction';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { MessageToaster } from '../../../utils/toastUtils';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const ManageLeave = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLeaveLoading, leaveListResponse, isApplyLeaveLoading, isApplyLeaveSuccess, applyLeaveErrorCode, applyLeaveMessage } = useAppSelector(
    (state: RootState) => state.manageLeave,
  );

  // Initialize component state variables.
  const [show, setShow] = useState<boolean>(false);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<IObject | null>({ label: LeaveTypes[LeaveTypes.Sick].replace(/_/g, ' '), value: LeaveTypes.Sick.toString() });
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(moment().toDate());
  const [leaveDays, setLeaveDays] = useState<number>(NUMBER_1);
  const [reason, setReason] = useState<string>('');
  const [leaveStatus, setLeaveStatus] = useState<IObject | null>({ label: ALL_TEXT, value: NUMBER_0.toString() });
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [pageSize] = useState<number>(NUMBER_0);
  const [pageIndex] = useState<number>(NUMBER_0);

  // Method to fetch leaves.
  const fetchLeaves = () => {
    const leaveRequest: IGetAllLeaveRequest = {
      userType: RoleTypes.CA,
      leaveType: NUMBER_0,
      status: leaveStatus?.value ? Number(leaveStatus?.value) : NUMBER_0,
      page: pageIndex,
      limit: pageSize,
    };
    dispatch(getAllLeaves(leaveRequest));
  };

  // Clear leave submission form.
  const clearLeaveForm = () => {
    setLeaveType({ label: LeaveTypes[LeaveTypes.Sick].replace(/_/g, ' '), value: LeaveTypes.Sick.toString() });
    setStartDateSelected(moment().toDate());
    setEndDateSelected(moment().toDate());
    setLeaveDays(NUMBER_1);
    setReason('');
    setErrorFields(null);
  };

  // Component initial loading side effect handler.
  useEffect(() => {
    fetchLeaves();
    return () => {
      dispatch(revertAllLeaveState());
      clearLeaveForm();
    };
  }, []);

  // Apply leave api response state side effect handling.
  useEffect(() => {
    if (isApplyLeaveSuccess && applyLeaveErrorCode === HTTP_STATUS_200 && applyLeaveMessage) {
      dispatch(revertAllLeaveState());
      toast.toastSuccess(applyLeaveMessage);
      clearLeaveForm();
      fetchLeaves();
    } else if (!isApplyLeaveSuccess && applyLeaveErrorCode > HTTP_STATUS_200 && applyLeaveMessage) {
      toast.toastError(applyLeaveMessage);
    }
  }, [isApplyLeaveSuccess, applyLeaveErrorCode, applyLeaveMessage]);

  // Leave status filter options.
  const status = [
    { label: ALL_TEXT, value: NUMBER_0 },
    { label: LeaveStatus[LeaveStatus.Approved], value: LeaveStatus.Approved },
    { label: LeaveStatus[LeaveStatus.Declined], value: LeaveStatus.Declined },
  ];

  // Leave types filter options.
  const leaveTypes = [
    { label: LeaveTypes[LeaveTypes.Sick].replace(/_/g, ' '), value: LeaveTypes.Sick.toString() },
    { label: LeaveTypes[LeaveTypes.Bereavement].replace(/_/g, ' '), value: LeaveTypes.Bereavement.toString() },
    { label: LeaveTypes[LeaveTypes.Vacation_Day].replace(/_/g, ' '), value: LeaveTypes.Vacation_Day.toString() },
    { label: LeaveTypes[LeaveTypes.Floating_Holiday].replace(/_/g, ' '), value: LeaveTypes.Floating_Holiday.toString() },
    { label: LeaveTypes[LeaveTypes.Free_Days].replace(/_/g, ' '), value: LeaveTypes.Free_Days.toString() },
  ];

  // Filter change handler
  const handleFilterChange = (state: { isOpen: boolean }) => {
    setFilterOpen(state.isOpen);
  };

  // Leave status filter dropdown select change handler.
  const onLeaveStatusSelect = (val: IObject) => {
    setLeaveStatus(val);
  };

  // Leave type dropdown select change handler.
  const onLeaveTypeSelect = (val: IObject) => {
    setLeaveType(val);
  };

  // Reason input box change event handler.
  const onReasonChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setReason(value);
  };

  // Start/end date input select event handler.
  const handleDateChange = (date: Date | null, type: string) => {
    let days = NUMBER_1;
    if (type === DATE_START) {
      days = getDateDifference(moment(date).format(DATE_FORMAT_YYYY_MM_DD), moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD));
      if (days < 0) {
        setStartDateSelected(date);
        setEndDateSelected(date);
        setLeaveDays(NUMBER_1);
      } else {
        setLeaveDays(days + NUMBER_1);
        setStartDateSelected(date);
      }
    } else {
      days = getDateDifference(moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD), moment(date).format(DATE_FORMAT_YYYY_MM_DD));
      setLeaveDays(days + NUMBER_1);
      setEndDateSelected(date);
    }
  };

  // Leave submission form popup close event.
  const handleClose = () => {
    clearLeaveForm();
    setErrorFields(null);
    setShow(false);
  };

  // Leave submission form popup open event.
  const handleShow = () => {
    setShow(true);
  };

  // Leave form submission event.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    let fromDate = '';
    let toDate = '';
    if (startDateSelected && endDateSelected) {
      const fromDateString = moment(new Date(startDateSelected)).format(DATE_FORMAT_YYYY_MM_DD);
      fromDate = `${fromDateString}T${TIME_STARTS_FORMAT}.000Z`;
      const toDateString = moment(new Date(endDateSelected)).format(DATE_FORMAT_YYYY_MM_DD);
      toDate = `${toDateString}T${TIME_STARTS_FORMAT}.000Z`;
    }
    const leaveRequest: IApplyLeaveRequest = {
      leaveType: leaveType?.value ? Number(leaveType.value) : NUMBER_0,
      leaveStartDate: fromDate,
      leaveEndDate: toDate,
      noOfLeaves: leaveDays,
      leaveReason: reason,
    };
    const errorresult = await validateForm(leaveRequest, LEAVE_SUBMISSION_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      dispatch(applyLeave(leaveRequest));
      handleClose();
    }
  };

  // Apply filter button click handler.
  const onFilterApply = () => {
    fetchLeaves();
    setFilterOpen(false);
  };

  // Reset leave filter button click event handler.
  const resetFilter = () => {
    setLeaveStatus({ label: ALL_TEXT, value: NUMBER_0.toString() });
  };

  return (
    <div>
      <div className="pageheader">
        <h1>{Strings.HD.ManageLeave}</h1>
      </div>
      <div className="shiftcard-main">
        <Card>
          <Card.Body className="shift-details p-3">
            <Row className="align-items-center">
              <Col>{Strings.Leave.TotalLeave}</Col>
              <Col xs="auto" className="leave-count">
                {leaveListResponse?.totalLeaveAvailed || 0}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <Row className="align-items-center pt-3">
        <Col>
          <Button variant="primary" onClick={handleShow}>
            {Strings.Button.ApplyLeave}
          </Button>
        </Col>
        <Col xs="auto" className="btn-container d-flex ps-0">
          <Filter
            width={310}
            right
            pageWrapId="filter-wrapper"
            outerContainerId="outer-container"
            isOpen={isFilterOpen}
            onStateChange={handleFilterChange}
            customCrossIcon={<Close />}
          >
            <div id="filter-wrapper" className="filter-overlay">
              <div className="overlay-hd p-3 border-bottom">
                <h1>{Strings.Filter.Filter}</h1>
              </div>
              <div className="overlay-filter-content">
                <div>
                  <Col>
                    <KKISelect
                      id="Status"
                      label="Status"
                      name="Status"
                      className="custom-select"
                      value={leaveStatus}
                      placeholder={Strings.Sort.Select}
                      onSelectChange={onLeaveStatusSelect}
                      searchvalue={false}
                      options={status}
                    />
                  </Col>
                </div>
              </div>

              <Col className="overlay-button-container">
                <Button variant="outline-primary" onClick={() => resetFilter()}>
                  {Strings.Button.Reset}
                </Button>
                <Button variant="primary" onClick={() => onFilterApply()}>
                  {Strings.Button.Apply}
                </Button>
              </Col>
            </div>
          </Filter>
        </Col>
      </Row>
      <Col className="mb-3">
        {leaveListResponse && leaveListResponse.leaves?.length > 0 && leaveListResponse.leaves.map((leave: ILeaveDetails) => <LeaveCard key={leave.leaveRequestId} data={leave} />)}
        {(!leaveListResponse || (leaveListResponse && leaveListResponse.leaves?.length === 0)) && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>}
      </Col>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.HD.ApplyLeave}</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body>
            <Col className="mb-3">
              <KKISelect
                id="leaveType"
                label={Strings.Label.LeaveType}
                name="leaveType"
                className="custom-select"
                placeholder={Strings.Sort.Select}
                value={leaveType}
                onSelectChange={onLeaveTypeSelect}
                searchvalue={false}
                alert={errorFields?.leaveType || ''}
                options={leaveTypes}
              />
            </Col>
            <Row className="align-items-center mb-3">
              <label htmlFor="Date Range" className="form-label">
                {Strings.Label.DateRange}
              </label>
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder="placeholder"
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  minDate={moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_START)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker
                  id="kkiEnddatepicker"
                  name="kkiEnddatepicker"
                  placeholder="placeholder"
                  value={endDateSelected}
                  dateFormat="dd MMM yyyy"
                  minDate={startDateSelected || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_END)}
                />
              </Col>
            </Row>
            <Col className="mb-3" xs={6}>
              <KKIInput id="leaveDays" label={Strings.Label.LeaveDays} name="leaveDays" type="text" value={leaveDays} disabled />
            </Col>
            <Col>
              <KKIInput
                id="reason"
                label={Strings.Label.Reason}
                name="reason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Input.ReasonHere}
                value={reason}
                onChange={onReasonChange}
                maxLength={NUMBER_250}
                alert={errorFields?.leaveReason || ''}
              />
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" type="submit">
              {Strings.Button.Submit}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {(isLeaveLoading || isApplyLeaveLoading) && <Loader />}
    </div>
  );
};
export default ManageLeave;
