import React from 'react';

const ManageSwapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_16177" data-name="Group 16177" transform="translate(-139 -291)">
        <path
          id="Ellipse_114"
          data-name="Ellipse 114"
          d="M14,1A13,13,0,0,0,4.808,23.192,13,13,0,0,0,23.192,4.808,12.915,12.915,0,0,0,14,1m0-1A14,14,0,1,1,0,14,14,14,0,0,1,14,0Z"
          transform="translate(139 291)"
          fill="#1b1658"
        />
        <g id="Group_16176" data-name="Group 16176" transform="translate(-0.111 -1.467)">
          <path
            id="Path_10384"
            data-name="Path 10384"
            d="M1078.327-3670.478h-11.172a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h11.172a.5.5,0,0,1,.5.5A.5.5,0,0,1,1078.327-3670.478Z"
            transform="translate(-920.044 3972.935)"
            fill="#1b1658"
          />
          <path
            id="Path_10385"
            data-name="Path 10385"
            d="M1072.885-3665.956a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707l3.185-3.185-3.185-3.186a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0l3.539,3.539a.5.5,0,0,1,.146.354.5.5,0,0,1-.146.354l-3.539,3.539A.5.5,0,0,1,1072.885-3665.956Z"
            transform="translate(-917.948 3972)"
            fill="#1b1658"
          />
          <g id="Group_16175" data-name="Group 16175" transform="translate(147.111 306.467)">
            <path
              id="Path_10387"
              data-name="Path 10387"
              d="M1078.327-3670.478h-11.172a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h11.172a.5.5,0,0,1,.5.5A.5.5,0,0,1,1078.327-3670.478Z"
              transform="translate(-1066.962 3674.468)"
              fill="#1b1658"
            />
            <path
              id="Path_10386"
              data-name="Path 10386"
              d="M1076.424-3665.956a.5.5,0,0,1-.354-.146l-3.539-3.539a.5.5,0,0,1-.146-.354.5.5,0,0,1,.146-.354l3.539-3.539a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707l-3.185,3.186,3.185,3.185a.5.5,0,0,1,0,.707A.5.5,0,0,1,1076.424-3665.956Z"
              transform="translate(-1072.885 3673.533)"
              fill="#1b1658"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ManageSwapIcon;
