import React from 'react';

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="17" viewBox="0 0 16.999 17">
      <g id="edit" transform="translate(-1.5 -1.379)">
        <path
          id="Path_10379"
          data-name="Path 10379"
          d="M14.817,18.919H3.6a2.1,2.1,0,0,1-2.1-2.1V5.6A2.1,2.1,0,0,1,3.6,3.5H9.209a.5.5,0,0,1,0,1H3.6A1.1,1.1,0,0,0,2.5,5.6V16.817a1.1,1.1,0,0,0,1.1,1.1H14.817a1.1,1.1,0,0,0,1.1-1.1V11.209a.5.5,0,0,1,1,0v5.607A2.1,2.1,0,0,1,14.817,18.919Z"
          transform="translate(0 -0.54)"
          fill="#400286"
        />
        <path
          id="Path_10380"
          data-name="Path 10380"
          d="M16.946,1.379a2.081,2.081,0,0,1,1.472,3.553l-7.082,7.082a.5.5,0,0,1-.232.132l-2.982.745a.5.5,0,0,1-.606-.606L8.26,9.3a.5.5,0,0,1,.132-.232l7.082-7.082A2.068,2.068,0,0,1,16.946,1.379Zm-6.219,9.83L17.71,4.225A1.081,1.081,0,0,0,16.181,2.7L9.2,9.68l-.51,2.039Z"
          transform="translate(-0.527)"
          fill="#400286"
        />
      </g>
    </svg>
  );
};

export default EditIcon;
