/**
 * @file   src\components\Header.tsx
 * @brief  Header section.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { useNavigate, Nav, NavDropdown, Link, Modal, Button, useState, Col } from './ThirdPartyComponents';
import '../styles/Header.scss';
import Strings from '../assests/strings/Strings.json';
import Logo from '../assests/images/logo-mobile.svg';
import Profile from '../assests/icons/User';
import Password from '../assests/icons/Password';
import Signout from '../assests/icons/Logout';
import { CARoutePath, RoleTypes, RoutePath, AdminRoutePath } from '../utils/enums';
import { getDataFromStorage } from '../utils/helper';
import { STORAGE_USER } from '../utils/constants';
import { useAppDispatch } from '../hooks';
import { resetAuthState } from '../store/slices/authSlice';

const Header = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access role from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  const role = userInfo?.roleId || 0;

  // initialize Component state.
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  // Handle the logout
  const logoutAction = () => {
    localStorage.clear();
    setShowLogoutModal(false);
    dispatch(resetAuthState());
    navigate(RoutePath.Login);
  };

  return (
    <div className="header-main position-fixed d-flex justify-content-between">
      <div className="logo-container">
        <Link to="/home" className="mobile-logo">
          <img src={Logo} alt="" />
        </Link>
      </div>
      <Nav>
        <NavDropdown
          align="end"
          className="profile-menu"
          id="nav-dropdown-dark-example"
          title={
            <div className="profile-dropdown">
              <span className="profile-img"> </span>
            </div>
          }
        >
          <NavDropdown.Item href="#" onClick={() => navigate(Number(role) === RoleTypes.CA ? CARoutePath.Profile : AdminRoutePath.SCHManageProfile)}>
            <Profile />
            {Strings.Header.Profile}
          </NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={() => navigate(Number(role) === RoleTypes.CA ? CARoutePath.ChangePassword : AdminRoutePath.ChangePassword)}>
            <Password />
            {Strings.Header.ChangePassword}
          </NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={() => setShowLogoutModal(true)}>
            <Signout />
            {Strings.Header.SignOut}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered>
        <Modal.Body className="text-center py-5">
          <p className="text-center">{Strings.Text.LogoutConfirmation}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setShowLogoutModal(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" className="ms-3" onClick={() => logoutAction()}>
              {Strings.Button.OK}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Header;
