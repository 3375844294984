/**
 * @file   src\components\Loader.tsx
 * @brief  This component is responsible for displaying loding indicator.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import LoaderImg from '../assests/images/loader.gif';

const Loader = () => {
  return (
    <div className="loader-outer">
      <div className="p-3 text-center">
        <img src={LoaderImg} alt="loading" />
      </div>
    </div>
  );
};
export default Loader;
