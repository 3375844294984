/**
 * @file   src\components\CallOutCard.tsx
 * @brief  CallOut shift card.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, Button, useAccordionButton, useNavigate, moment } from './ThirdPartyComponents';
import { CustomToggleProps, ICalloutCard } from '../interfaces/generalInterface';
import Arrow from '../assests/icons/DownArrow';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import { AdminRoutePath, ShiftTypes } from '../utils/enums';
import { DATE_FORMAT_DD_MMM_YYYY, DATE_FORMAT_DD_MMM_YYYY_HHMMA, DATE_FORMAT_HHMMA } from '../utils/constants';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};

const CallOutCard = ({ shiftDetails, calloutFilterType }: ICalloutCard) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Determine what to show for color group based on required values
  const renderOpenSlots = () => {
    const { yellowRequired, blueRequired, redRequired, yellowCount, blueCount, redCount } = shiftDetails || {};

    // Check if all required values are zero
    if (yellowRequired === 0 && blueRequired === 0 && redRequired === 0) {
      return <span>None</span>;
    }

    return (
      <>
        {yellowRequired !== 0 && (
          <span className="openslot yellow">
            {yellowCount}/{yellowRequired}
          </span>
        )}
        {blueRequired !== 0 && (
          <span className="openslot blue">
            {blueCount}/{blueRequired}
          </span>
        )}
        {redRequired !== 0 && (
          <span className="openslot red">
            {redCount}/{redRequired}
          </span>
        )}
      </>
    );
  };

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main">
      <Card>
        <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
          <Col className="d-flex align-items-center">
            {`${shiftDetails.shiftName}(${shiftDetails.shortName})`}
            <div>
              <label htmlFor="shift date and time" className="shift-date-mobile">
                {shiftDetails?.shiftType === ShiftTypes.NightShift
                  ? `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_DD_MMM_YYYY)}`
                  : `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)}`}
              </label>
            </div>
          </Col>
          <div>
            <Col xs="auto" className="d-flex cardheader-right">
              <label htmlFor="shift dateand time" className="shift-date-web">
                {shiftDetails?.shiftType === ShiftTypes.NightShift
                  ? `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)}`
                  : `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)}`}
                {shiftDetails?.shiftType !== ShiftTypes.NightShift ? (
                  <span className="shift-time-web">
                    {moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - {moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA)}
                  </span>
                ) : (
                  ''
                )}
              </label>
            </Col>
            <CustomToggle eventKey="0" callback={toggleCollapse}>
              <Arrow />
            </CustomToggle>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              <Row>
                <Col xs={7} lg={6} xl={6} xxl className="shift-time-mob">
                  {Strings.Schedule.Time}
                  <span>
                    {' '}
                    {moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - {moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA)}
                  </span>
                </Col>
                <Col xs={5} lg={6} xl={6} xxl className="shift-count text-start">
                  {Strings.Schedule.CACount}:{' '}
                  <span>
                    {shiftDetails.assignedCACount}/{shiftDetails.totalCACount}
                  </span>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl className="openslot-main text-start">
                  <span className="lg-ps-2">{Strings.Schedule.OpenSlot}</span>
                  <label htmlFor="Open slot">{renderOpenSlots()}</label>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl className="shift-openslot">
                  <span className="lg-ps-2">{Strings.Schedule.LDOpenslot}</span>
                  <span>
                    {shiftDetails?.lightDutyCount}/{shiftDetails?.lightDutyRequired}
                  </span>
                </Col>
              </Row>
            </Col>
            <Row className="align-items-center">
              <Col>
                {Strings.Label.CallOuts}:<span className="fw-sb"> {shiftDetails.callOutCount}</span>
              </Col>
              <Col xs="auto">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    navigate({
                      pathname:
                        AdminRoutePath.SCHCAResource.replace(':shiftId', shiftDetails.shiftId.toString())
                          .replace(':shiftFromTime', shiftDetails.shiftFromTime || '-')
                          .replace(':shiftDate', shiftDetails.startDate || '-')
                    }, { state: { calloutFilterType } }
                    );
                  }}
                >
                  {Strings.Button.CAList}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default CallOutCard;
