/**
 *@file src\components\ConfirmationModal.tsx
 *@brief Reusable checkbox component
 *@date July, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

import { Col, Button, Modal } from './ThirdPartyComponents';
import { IConfirmationModalProps } from '../interfaces/generalInterface';

const ConfirmationModal = ({ show, message, onConfirm, onCancel, confirmButtonText = 'Yes', cancelButtonText = 'No' }: IConfirmationModalProps) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Body className="text-center py-5">
        <p>{message}</p>
        <Col className="mt-4">
          <Button variant="outline-primary" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button variant="primary" onClick={onConfirm} className="ms-3">
            {confirmButtonText}
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
