/**
 * @file   src\containers\ForgotPassword.tsx
 * @brief  Forgot Password page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, useState } from '../components/ThirdPartyComponents';
import ForgotPasswordIcn from '../assests/images/forgot-password.svg';
import MobileVerification from './MobileVerification';
import ResetPassword from './ResetPassword';

const ForgotPassword = () => {
  // Initialize component state variables.
  const [mobile, setMobile] = useState<string>('');

  return (
    <Col className="cs-form-sec" xl={8} lg={8} md={10} sm={11} xs={10}>
      <div className="kki-logo-wrap">
        <img src={ForgotPasswordIcn} alt="kki" width={149} height={130} />
      </div>
      {mobile === '' && <MobileVerification setMobileNumber={(val: string) => setMobile(val)} />}
      {mobile !== '' && <ResetPassword mobile={mobile} />}
    </Col>
  );
};

export default ForgotPassword;
