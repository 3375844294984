import React from 'react';

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.716" height="15.716" viewBox="0 0 15.716 15.716">
      <path
        id="search_24dp_FILL0_wght300_GRAD0_opsz24"
        d="M150.775-808.894l-4.316-4.317a5.973,5.973,0,0,1-1.8,1.013,6.191,6.191,0,0,1-2.107.366,6.168,6.168,0,0,1-4.534-1.855,6.165,6.165,0,0,1-1.855-4.532,6.172,6.172,0,0,1,1.855-4.534,6.163,6.163,0,0,1,4.532-1.856,6.171,6.171,0,0,1,4.534,1.855,6.166,6.166,0,0,1,1.856,4.534,6.129,6.129,0,0,1-.376,2.138,5.976,5.976,0,0,1-1,1.772L151.876-810Zm-8.226-4.505a4.653,4.653,0,0,0,3.42-1.4,4.653,4.653,0,0,0,1.4-3.42,4.653,4.653,0,0,0-1.4-3.42,4.653,4.653,0,0,0-3.42-1.4,4.653,4.653,0,0,0-3.42,1.4,4.653,4.653,0,0,0-1.4,3.42,4.653,4.653,0,0,0,1.4,3.42A4.653,4.653,0,0,0,142.549-813.4Z"
        transform="translate(-136.16 824.61)"
        fill="#400286"
      />
    </svg>
  );
};

export default SearchIcon;
