/**
 * @file   src\validations\manageSwapSchema.ts
 * @brief  This file is responsible for defining swap submission form validation schemas.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import { DECLINE_REASON_REQUIRED, DECLINE_REASON_TYPE_REQUIRED } from '../messages/validationMessages';
import { NUMBER_0 } from '../utils/constants';

// Swap decline form validation schema.
export const SWAP_DECLINE_SCHEMA = yup.object({
  swapReasonType: yup.number().required(DECLINE_REASON_TYPE_REQUIRED),
  reason: yup.string().when('swapReasonType', {
    is: NUMBER_0,
    then: (schema) => schema.required(DECLINE_REASON_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if swapReasonType is not other.
  }),
});
