import React from 'react';

const RequestedIcon = () => {
  return (
    <svg id="Requested-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g id="Group_16250" data-name="Group 16250" transform="translate(-610 -654)">
        <circle id="Ellipse_109" data-name="Ellipse 109" cx="7" cy="7" r="7" transform="translate(610 654)" fill="#838688" />
      </g>
      <path
        id="question_mark_24dp_5F6368_FILL0_wght500_GRAD0_opsz24"
        d="M277.451-851.393a.8.8,0,0,0-.256-.621.957.957,0,0,0-.675-.236,1.037,1.037,0,0,0-.476.109,1.051,1.051,0,0,0-.371.32.671.671,0,0,1-.472.273.593.593,0,0,1-.494-.152.5.5,0,0,1-.157-.348.611.611,0,0,1,.116-.391,2.181,2.181,0,0,1,.8-.7,2.264,2.264,0,0,1,1.056-.248,2.18,2.18,0,0,1,1.537.542,1.839,1.839,0,0,1,.589,1.419,1.728,1.728,0,0,1-.2.818,3.771,3.771,0,0,1-.7.867,3.225,3.225,0,0,0-.419.464,1.15,1.15,0,0,0-.169.4.793.793,0,0,1-.234.393.569.569,0,0,1-.392.152.581.581,0,0,1-.41-.16.517.517,0,0,1-.171-.394,1.519,1.519,0,0,1,.173-.717,2.3,2.3,0,0,1,.557-.657,3.534,3.534,0,0,0,.611-.619A.893.893,0,0,0,277.451-851.393Zm-.93,5.43a.8.8,0,0,1-.585-.242.8.8,0,0,1-.242-.585.8.8,0,0,1,.242-.586.8.8,0,0,1,.585-.243.8.8,0,0,1,.586.243.8.8,0,0,1,.244.586.8.8,0,0,1-.244.585A.8.8,0,0,1,276.521-845.963Z"
        transform="translate(-269.551 856.39)"
        fill="#fff"
      />
    </svg>
  );
};

export default RequestedIcon;
