import React from 'react';

const DraftIcon = () => {
  return (
    <svg id="Group_16170" data-name="Group 16170" xmlns="http://www.w3.org/2000/svg" width="12.938" height="16" viewBox="0 0 12.938 16">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_549" data-name="Rectangle 549" width="12.938" height="16" fill="#894edc" />
        </clipPath>
      </defs>
      <g id="Group_16169" data-name="Group 16169" clipPath="url(#clip-path)">
        <path
          id="Path_10382"
          data-name="Path 10382"
          d="M12.567,4.2,8.68.359A1.246,1.246,0,0,0,7.8,0H1.243A1.244,1.244,0,0,0,0,1.246V14.754A1.244,1.244,0,0,0,1.243,16H11.691a1.246,1.246,0,0,0,1.246-1.246V5.09a1.248,1.248,0,0,0-.37-.887M3.7,6.21H6.88a.823.823,0,0,1,0,1.646H3.7a.823.823,0,1,1,0-1.646m6.365,5.5H3.837a.823.823,0,1,1,0-1.646h6.225a.823.823,0,1,1,0,1.646"
          transform="translate(0.001 -0.001)"
          fill="#894edc"
        />
      </g>
    </svg>
  );
};

export default DraftIcon;
