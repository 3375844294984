/**
 * @file   src\store\actions\manageSwapAction.ts
 * @brief  This file is responsible for creating asynchronous api call for Clinical assistants(CA) shift swap management slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import {
  IApproveSwapByAdminRequest,
  IDeclineSwapByAdminRequest,
  IDeleteSwapRequest,
  IGetCASwapByAdminRequest,
  IGetSwapRequest,
  IResendSwapRequest,
  ISwapShiftRequest,
  IUpdateSwapRequestStatus,
} from '../../interfaces/swapInterface';
import { KKIActionTypes, ManageSwapApi } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Mock data
// import mySwapRequest from '../../containers/CA/ManageSwap/mySwapRequest.json';
// import IncommingSwapRequest from '../../containers/CA/ManageSwap/IncommingSwapRequest.json';

// Swap shift api call.
export const swapShift = createAsyncThunk('/manageSwap/swapShift', async (request: ISwapShiftRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.SWAP_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get swap requests api call.
export const getSwapRequests = createAsyncThunk('/manageSwap/getSwapRequests', async (request: IGetSwapRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageSwapApi.GET_SHIFT_REQUEST}?${buildQueryString(request)}`);
    // Load mock data
    // const data: any = request?.type === 1 ? mySwapRequest : IncommingSwapRequest;
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete swap requests api call.
export const deleteSwapReuest = createAsyncThunk('/manageSwap/deleteSwapReuest', async (request: IDeleteSwapRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${ManageSwapApi.DELETE_SWAP_REQUEST}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete swap requests api call.
export const updateSwapRequestStatus = createAsyncThunk('/manageSwap/updateSwapRequestStatus', async (request: IUpdateSwapRequestStatus, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.UPDATE_SWAP_REQUEST_STATUS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Resend swap requests api call.
export const resendSwapRequest = createAsyncThunk('/manageSwap/resendSwapRequest', async (request: IResendSwapRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.RESEND_SWAP_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get CA swap requests api call.
export const getCASwapRequestByAdmin = createAsyncThunk('/manageSwap/getCASwapRequestByAdmin', async (request: IGetCASwapByAdminRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageSwapApi.GET_SWAP_REQUEST_BY_ADMIN}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Approve/decline swap requests by admin api call.
export const updateSwapRequestStatusByAdmin = createAsyncThunk('/manageSwap/updateSwapRequestStatusByAdmin', async (request: IUpdateSwapRequestStatus, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.APPROVE_DECLINE_SWAP_BY_ADMIN, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Approve swap request by admin api call.
export const approveSwapRequestByAdmin = createAsyncThunk('/manageSwap/approveSwapRequestByAdmin', async (request: IApproveSwapByAdminRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.APPROVE_SWAP_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Decline swap request by admin api call.
export const declineSwapRequestByAdmin = createAsyncThunk('/manageSwap/declineSwapRequestByAdmin', async (request: IDeclineSwapByAdminRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageSwapApi.DECLINE_SWAP_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get swap decline reasons api call.
export const getDeclineSwapReasons = createAsyncThunk('/manageSwap/getDeclineSwapReasons', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ManageSwapApi.GET_DECLINE_SWAP_REASONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all swap states to initial sate action.
export const revertAllSwapState = createAction(KKIActionTypes.REVERT_ALL_SWAP_STATE);
