/**
 * @file   src\assets\icons\Password.tsx
 * @brief  Password icon component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { IPasswordIconInterface } from '../../interfaces/generalInterface';

const PasswordIcon = ({ togglePassword }: IPasswordIconInterface) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13.715" viewBox="0 0 20 13.715" onClick={() => togglePassword?.(true)}>
      <path
        id="visibility_FILL1_wght500_GRAD0_opsz48"
        d="M44.5,261.173a3.8,3.8,0,1,0-2.7-1.111A3.674,3.674,0,0,0,44.5,261.173Zm-.007-1.388a2.415,2.415,0,1,1,1.723-.705A2.332,2.332,0,0,1,44.5,259.786Zm0,4.429a10.133,10.133,0,0,1-6-1.9,11.577,11.577,0,0,1-4-4.957,11.576,11.576,0,0,1,4-4.957,10.424,10.424,0,0,1,12,0,11.576,11.576,0,0,1,4,4.957,11.577,11.577,0,0,1-4,4.957A10.133,10.133,0,0,1,44.5,264.215Z"
        transform="translate(-34.5 -250.5)"
        fill="#878db0"
      />
    </svg>
  );
};

export default PasswordIcon;
