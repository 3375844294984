/**
 * @file   src\store\actions\manageCAScheduleAction.ts
 * @brief  This file is responsible for creating asynchronous api call for managing Clinical assistants(CA) schedule slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { IGetAllShiftsRequest, ICallOutShiftRequest, IAssignCARequest, IUnassignCARequest, IShiftDetailsRequest, IGetUserShiftsRequest } from '../../interfaces/scheduleInterface';
import { KKIActionTypes, ManageScheduleApi } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';
import { QUERY_STRING_ARRAY_FORMAT } from '../../utils/constants';

// Get all shift api call.
export const getAllShifts = createAsyncThunk('/manageSchedule/getAllShifts', async (request: IGetAllShiftsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageScheduleApi.GET_ALL_SHIFTS}?${buildQueryString(request, QUERY_STRING_ARRAY_FORMAT)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all shift types api call.
export const getAllShiftTypes = createAsyncThunk('/manageSchedule/getAllShiftTypes', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ManageScheduleApi.GET_ALL_SHIFT_TYPES);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get shift details by id api call.
export const getShiftDetails = createAsyncThunk('/manageSchedule/getShiftDetails', async (request: IShiftDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageScheduleApi.GET_SHIFT_DETAILS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Assign CA to a shift api call.
export const assignCA = createAsyncThunk('/manageSchedule/assignCA', async (request: IAssignCARequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageScheduleApi.ASSIGN_CA, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Unassign CA from a shift api call.
export const unassignCA = createAsyncThunk('/manageSchedule/unassignCA', async (request: IUnassignCARequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageScheduleApi.UNASSIGN_CA, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Call out shift api call.
export const callOutShift = createAsyncThunk('/manageSchedule/callOutShift', async (request: ICallOutShiftRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageScheduleApi.CALL_OUT_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get user shift api call.
export const getUserShifts = createAsyncThunk('/manageSchedule/getUserShifts', async (request: IGetUserShiftsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageScheduleApi.GET_USER_SHIFTS}?${buildQueryString(request, QUERY_STRING_ARRAY_FORMAT)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all schedule states to initial sate action.
export const revertAllScheduleState = createAction(KKIActionTypes.REVERT_ALL_SCHEDULE_STATE);
