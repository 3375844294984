/**
 * @file   src\components\OuterLayout.tsx
 * @brief  Before login page layout.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col, Container } from './ThirdPartyComponents';

const OuterLayout = () => {
  return (
    <Container fluid className="m-0 p-0">
      <Row className="cs-login-wrap m-0 p-0">
        <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center m-0 p-0 logo-wrap cs-login-bg d-none d-lg-flex">
          &nbsp;
        </Col>
        <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center cs-form-container m-0 p-0">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
export default OuterLayout;
