/**
 * @file   src\store\slices\userSlice.ts
 * @brief  This file is responsible for creating user slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../interfaces/userInterface';
import {
  getProfile,
  revertAllUserState,
  sendProfileEditOtp,
  setUpAdminProfile,
  setUpProfile,
  updateAdminProfile,
  updateProfile,
  userLogout,
  getAdminProfile,
  getAllUsers,
  getSchedulerDetails,
  updateSchedulerDetails,
  activateOrDeactivateScheduler,
} from '../actions/userAction';
import { getAlertMessage } from '../../utils/helper';

// Initial state.
const userState: IUser = {
  isSuccess: false,
  isLoading: false,
  errorCode: 0,
  message: null,
  isProfileSetupSuccess: false,
  isProfileSetupLoading: false,
  profileSetupErrorCode: 0,
  profileSetupMessage: null,
  profileSetupResponse: null,
  isProfileUpdateSuccess: false,
  isProfileUpdateLoading: false,
  profileUpdateErrorCode: 0,
  profileUpdateMessage: null,
  isProfileFetchSuccess: false,
  isProfileFetchLoading: false,
  profileFetchErrorCode: 0,
  profileFtechMessage: null,
  profileInfo: null,
  isAdminProfileUpdateSuccess: false,
  isAdminProfileUpdateLoading: false,
  adminProfileUpdateErrorCode: 0,
  adminProfileUpdateMessage: null,
  isProfileEditOtpLoading: false,
  isProfileEditOtpSuccess: false,
  profileEditOtpErrorCode: 0,
  profileEditOtpMessage: null,
  profileEditOtp: '',
  isAdminProfileFetchSuccess: false,
  isAdminProfileFetchLoading: false,
  adminProfileFetchErrorCode: 0,
  adminProfileFetchMessage: null,

  userInfo: [],
  total: 0,
  limit: 10,
  isFetchUsersSuccess: false,
  isFetchUsersLoading: false,
  fetchUsersErrorCode: 0,
  fetchUsersMessage: null,
  schedulerInfo: null,
  isFetchSchedulerDetailsSuccess: false,
  isFetchSchedulerDetailsLoading: false,
  fetchSchedulerDetailsErrorCode: 0,
  fetchSchedulerDetailsMessage: null,
  isUpdateSchedulerDetailsSuccess: false,
  isUpdateSchedulerDetailsLoading: false,
  updateSchedulerDetailsErrorCode: 0,
  updateSchedulerDetailsMessage: null,
  isUpdateSchedulerStatusLoading: false,
  isUpdateSchedulerStatusSuccess: false,
  updateSchedulerStatusErrorCode: 0,
  updateSchedulerStatusMessage: null,
};

// Config user slice.
export const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    resetUserState: () => userState,
  },
  extraReducers(builder) {
    // ********************* Start of setup profile action state handling. *********************
    // Setup profile default state.
    builder.addCase(setUpProfile.pending, (state) => {
      state.isProfileSetupSuccess = false;
      state.isProfileSetupLoading = true;
      state.profileSetupErrorCode = 0;
      state.profileSetupMessage = '';
    });
    // Setup profile success state.
    builder.addCase(setUpProfile.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isProfileSetupLoading = false;
      state.isProfileSetupSuccess = true;
      state.profileSetupResponse = info;
      state.profileSetupErrorCode = code;
      state.profileSetupMessage = status || '';
    });
    // Setup profile error state.
    builder.addCase(setUpProfile.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isProfileSetupSuccess = false;
      state.isProfileSetupLoading = false;
      state.profileSetupErrorCode = errcode;
      state.profileSetupMessage = error || '';
    });
    // ********************* End of setup profile action state handling. *********************

    // ********************* Start of update profile action state handling. *********************
    // Update profile default state.
    builder.addCase(updateProfile.pending, (state) => {
      state.isProfileUpdateSuccess = false;
      state.isProfileUpdateLoading = true;
      state.profileUpdateErrorCode = 0;
      state.profileUpdateMessage = '';
    });
    // Update profile success state.
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const { status, code } = action.payload;
      state.isProfileUpdateLoading = false;
      state.isProfileUpdateSuccess = true;
      state.profileUpdateErrorCode = code;
      state.profileUpdateMessage = status || '';
    });
    // Update profile error state.
    builder.addCase(updateProfile.rejected, (state, action: any) => {
      const { error, errcode } = action.payload ?? {};
      state.isProfileUpdateSuccess = false;
      state.isProfileUpdateLoading = false;
      state.profileUpdateErrorCode = errcode;
      state.profileUpdateMessage = error || '';
    });
    // ********************* End of update profile action state handling. *********************

    // ********************* Start of setup admin profile action state handling. *********************
    // Setup profile default state.
    builder.addCase(setUpAdminProfile.pending, (state) => {
      state.isProfileSetupSuccess = false;
      state.isProfileSetupLoading = true;
      state.profileSetupErrorCode = 0;
      state.profileSetupMessage = '';
    });
    // Setup profile success state.
    builder.addCase(setUpAdminProfile.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isProfileSetupLoading = false;
      state.isProfileSetupSuccess = true;
      state.profileSetupResponse = info;
      state.profileSetupErrorCode = code;
      state.profileSetupMessage = status || '';
    });
    // Setup profile error state.
    builder.addCase(setUpAdminProfile.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isProfileSetupSuccess = false;
      state.isProfileSetupLoading = false;
      state.profileSetupErrorCode = errcode;
      state.profileSetupMessage = error || '';
    });
    // ********************* End of setup admin profile action state handling. *********************

    // ********************* Start of logout action state handling. *********************
    // Logout default state.
    builder.addCase(userLogout.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorCode = 0;
      state.message = '';
    });
    // Logout success state.
    builder.addCase(userLogout.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.message = getAlertMessage(error !== 0 ? error.toString() : '');
    });
    // Logout error state.
    builder.addCase(userLogout.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = error;
      state.message = getAlertMessage(error && error !== 0 ? error.toString() : '');
    });
    // ********************* End of logout action state handling. *********************

    // ********************* Start of get profile action state handling. *********************
    // Get profile default state.
    builder.addCase(getProfile.pending, (state) => {
      state.isProfileFetchSuccess = false;
      state.isProfileFetchLoading = true;
      state.profileFetchErrorCode = 0;
      state.profileFtechMessage = '';
    });
    // Get profile success state.
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isProfileFetchLoading = false;
      state.isProfileFetchSuccess = true;
      state.profileInfo = info;
      state.profileFetchErrorCode = code;
      state.profileFtechMessage = status || '';
    });
    // Get profile error state.
    builder.addCase(getProfile.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isProfileFetchSuccess = false;
      state.isProfileFetchLoading = false;
      state.profileFetchErrorCode = errcode;
      state.profileFtechMessage = error || '';
    });
    // ********************* End of get profile action state handling. *********************

    // ********************* Start of update admin user profile action state handling. *********************
    // Update admin user profile default state.
    builder.addCase(updateAdminProfile.pending, (state) => {
      state.isAdminProfileUpdateSuccess = false;
      state.isAdminProfileUpdateLoading = true;
      state.adminProfileUpdateErrorCode = 0;
      state.adminProfileUpdateMessage = '';
    });
    // Update admin user profile success state.
    builder.addCase(updateAdminProfile.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isAdminProfileUpdateLoading = false;
      state.isAdminProfileUpdateSuccess = true;
      state.adminProfileUpdateErrorCode = code;
      state.adminProfileUpdateMessage = status || '';
    });
    // Update admin user profile error state.
    builder.addCase(updateAdminProfile.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isAdminProfileUpdateSuccess = false;
      state.isAdminProfileUpdateLoading = false;
      state.adminProfileUpdateErrorCode = errcode;
      state.adminProfileUpdateMessage = error || '';
    });
    // ********************* End of update admin user profile action state handling. *********************

    // ********************* Start of profile edit sent otp action state handling. *********************
    // Sent profile edit otp default state.
    builder.addCase(sendProfileEditOtp.pending, (state) => {
      state.isProfileEditOtpSuccess = false;
      state.isProfileEditOtpLoading = true;
      state.profileEditOtpErrorCode = 0;
      state.profileEditOtpMessage = '';
    });
    // Sent profile edit otp success state.
    builder.addCase(sendProfileEditOtp.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isProfileEditOtpLoading = false;
      state.isProfileEditOtpSuccess = true;
      state.profileEditOtp = info?.otp || '';
      state.profileEditOtpErrorCode = code;
      state.profileEditOtpMessage = status || '';
    });
    // Sent profile edit otp error state.
    builder.addCase(sendProfileEditOtp.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isProfileEditOtpSuccess = false;
      state.isProfileEditOtpLoading = false;
      state.profileEditOtpErrorCode = errcode;
      state.profileEditOtpMessage = error || '';
    });
    // ********************* End of sent profile edit otp action state handling. *********************

    // ********************* Start of get admin profile action state handling. *********************
    // Get admin profile default state.
    builder.addCase(getAdminProfile.pending, (state) => {
      state.isAdminProfileFetchSuccess = false;
      state.isAdminProfileFetchLoading = true;
      state.adminProfileFetchErrorCode = 0;
      state.adminProfileFetchMessage = '';
    });
    // Get admin profile success state.
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isAdminProfileFetchLoading = false;
      state.isAdminProfileFetchSuccess = true;
      state.profileInfo = info;
      state.adminProfileFetchErrorCode = code;
      state.adminProfileFetchMessage = status || '';
    });
    // Get admin profile error state.
    builder.addCase(getAdminProfile.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isAdminProfileFetchSuccess = false;
      state.isAdminProfileFetchLoading = false;
      state.adminProfileFetchErrorCode = errcode;
      state.adminProfileFetchMessage = error || '';
    });
    // ********************* End of get admin profile action state handling. ******************

    // ********************* Start of all CA/Scheduler listing action state handling. *********************
    // Get all Users default state.
    builder.addCase(getAllUsers.pending, (state) => {
      state.isFetchUsersSuccess = false;
      state.isFetchUsersLoading = true;
      state.fetchUsersErrorCode = 0;
      state.fetchUsersMessage = '';
    });
    // Get all users success state.
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchUsersLoading = false;
      state.isFetchUsersSuccess = true;
      state.fetchUsersErrorCode = error;
      state.fetchUsersMessage = status || '';
      state.userInfo = info?.users || [];
      state.total = info?.total || 0;
      state.limit = info?.limit || 0;
    });
    // //  Get all users error state.
    builder.addCase(getAllUsers.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchUsersSuccess = false;
      state.isFetchUsersLoading = false;
      state.fetchUsersErrorCode = errcode;
      state.fetchUsersMessage = error || '';
    });
    // ********************* End of all CA/Scheduler listing action state handling. *********************
    // ********************* Start of get Scheduler details action state handling. *********************
    // Get Scheduler details default state.
    builder.addCase(getSchedulerDetails.pending, (state) => {
      state.isFetchSchedulerDetailsSuccess = false;
      state.isFetchSchedulerDetailsLoading = true;
      state.fetchSchedulerDetailsErrorCode = 0;
      state.fetchSchedulerDetailsMessage = '';
    });
    //  Get Scheduler details success state.
    builder.addCase(getSchedulerDetails.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchSchedulerDetailsLoading = false;
      state.isFetchSchedulerDetailsSuccess = true;
      state.fetchSchedulerDetailsErrorCode = error;
      state.fetchSchedulerDetailsMessage = status || '';
      state.schedulerInfo = info;
    });
    //  Get Scheduler details error state.
    builder.addCase(getSchedulerDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isFetchSchedulerDetailsSuccess = false;
      state.isFetchSchedulerDetailsLoading = false;
      state.fetchSchedulerDetailsErrorCode = error;
      state.fetchSchedulerDetailsMessage = error || '';
    });
    // ********************* End of  get Scheduler details action state handling. *********************
    // ********************* Start of update Scheduler details action state handling. *********************
    // Update Scheduler details default state.
    builder.addCase(updateSchedulerDetails.pending, (state) => {
      state.isUpdateSchedulerDetailsSuccess = false;
      state.isUpdateSchedulerDetailsLoading = true;
      state.updateSchedulerDetailsErrorCode = 0;
      state.updateSchedulerDetailsMessage = '';
    });
    //  Update Scheduler details success state.
    builder.addCase(updateSchedulerDetails.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUpdateSchedulerDetailsLoading = false;
      state.isUpdateSchedulerDetailsSuccess = true;
      state.updateSchedulerDetailsErrorCode = code;
      state.updateSchedulerDetailsMessage = status || '';
    });
    // Update Scheduler details error state.
    builder.addCase(updateSchedulerDetails.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUpdateSchedulerDetailsSuccess = false;
      state.isUpdateSchedulerDetailsLoading = false;
      state.updateSchedulerDetailsErrorCode = errcode;
      state.updateSchedulerDetailsMessage = error || '';
    });
    // ********************* End of update Scheduler details action state handling. *********************
    // ********************* Start of scheduler status update action state handling. *********************
    // Update scheduler status default state.
    builder.addCase(activateOrDeactivateScheduler.pending, (state) => {
      state.isUpdateSchedulerStatusSuccess = false;
      state.isUpdateSchedulerStatusLoading = true;
      state.updateSchedulerStatusErrorCode = 0;
      state.updateSchedulerStatusMessage = '';
    });
    //  Update scheduler status success state.
    builder.addCase(activateOrDeactivateScheduler.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUpdateSchedulerStatusLoading = false;
      state.isUpdateSchedulerStatusSuccess = true;
      state.updateSchedulerStatusErrorCode = code;
      state.updateSchedulerStatusMessage = status || '';
    });
    // Update scheduler status error state.
    builder.addCase(activateOrDeactivateScheduler.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isUpdateSchedulerStatusSuccess = false;
      state.isUpdateSchedulerStatusLoading = false;
      state.updateSchedulerStatusErrorCode = errcode;
      state.updateSchedulerStatusMessage = error || '';
    });
    // ********************* End of scheduler status update action state handling. *********************
    // ********************* Clear all user states to initial state action. *********************
    builder.addCase(revertAllUserState, () => userState);
  },
});
// Export user actions.
export const { resetUserState } = userSlice.actions;
// Export reducer.
export default userSlice.reducer;
