/**
 * @file   src\validations\manageLeaveSchema.ts
 * @brief  This file is responsible for defining leave submission form validation schemas.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import {
  DECLINE_REASON_REQUIRED,
  DECLINE_REASON_TYPE_REQUIRED,
  LEAVE_DATE_FROM_REQUIRED,
  LEAVE_DATE_TO_REQUIRED,
  LEAVE_DAYS_REQUIRED,
  LEAVE_REASON_REQUIRED,
  LEAVE_TYPE_REQUIRED,
} from '../messages/validationMessages';
import { NUMBER_0 } from '../utils/constants';

// Leave submission form validation schema.
export const LEAVE_SUBMISSION_SCHEMA = yup.object({
  leaveType: yup.number().required(LEAVE_TYPE_REQUIRED).min(1, LEAVE_TYPE_REQUIRED),
  leaveStartDate: yup.string().trim().required(LEAVE_DATE_FROM_REQUIRED),
  leaveEndDate: yup.string().trim().required(LEAVE_DATE_TO_REQUIRED),
  noOfLeaves: yup.number().required(LEAVE_DAYS_REQUIRED).min(1, LEAVE_DAYS_REQUIRED),
  leaveReason: yup.string().trim().required(LEAVE_REASON_REQUIRED),
});

// Leave decline form validation schema.
export const LEAVE_DECLINE_SCHEMA = yup.object({
  leaveReasonType: yup.number().required(DECLINE_REASON_TYPE_REQUIRED),
  reason: yup.string().when('leaveReasonType', {
    is: NUMBER_0,
    then: (schema) => schema.required(DECLINE_REASON_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if leaveReasonType is not other.
  }),
});
