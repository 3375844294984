/**
 * @file   src\utils\validationHelper.ts
 * @brief  This file contains helper methods for validating the forms.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

function isValidForm(schema: any, body: any) {
  return schema.isValid(body, { abortEarly: false }).then((valid: any) => {
    return valid;
  });
}

function validationPathErrors(schema: any, body: any) {
  return schema
    .validate(body, { abortEarly: false })
    .then(() => {
      return true;
    })
    .catch((err: any) => {
      const fields: any = [];
      err.inner.forEach((element: any) => {
        fields.push(element);
      });
      return [fields]; // fields repeats when having multiple valiadtion added
    });
}

function sanitiseError(array: any) {
  const errors: any = {};
  array[0].forEach((element: any) => {
    const isObjectInfo = typeof element === 'object';
    const field = isObjectInfo ? element.path : element;
    errors[field] = {
      field,
      message: element.message.charAt(0).toUpperCase() + element.message.slice(1), // Converting First letter of error msg to caps
    };
  });
  return errors;
}

export async function validationChecks(schema: any, object: any) {
  const isValid = await isValidForm(schema, object);
  if (isValid) return { isValid: true };
  return {
    errors: sanitiseError(await validationPathErrors(schema, object)),
    isValid: false,
  };
}

const EMPTY = '';
const ERROR = 'Error';

export const generateErrorMessage = (errors: any, name: any) => {
  let errorMessage = EMPTY;
  const errorName = name + ERROR;
  if (errors === undefined) {
    errorMessage = EMPTY;
  } else if (errors[name]) {
    errorMessage = errors[name].message;
  }
  return { errorMessage, errorName };
};

// Generate validation error object.
export const generateErrors = (errors: any, fields: any, errFields: any) => {
  let errorFields: any = { ...errFields };
  if (errors !== undefined) {
    Object.entries(fields).forEach(([key]) => {
      if (errors[key] !== undefined) {
        errorFields[key] = errors[key].message;
      } else {
        delete errorFields[key];
      }
    });
  } else {
    errorFields = {};
  }
  return { errorFields };
};

export const isFormValid = (errorFields: any) => {
  const errorKeys = Object.keys(errorFields);
  let errors = 0;
  errorKeys.map((key: string) => {
    if (errorFields[key].length > 0) {
      errors += 1;
    }
    return null;
  });
  if (errors >= 1) {
    return false;
  }
  return true;
};

// Validate form and set Errors.
export const validateForm = async (requestObj: any, schema: any, errFields: any) => {
  const { errors } = await validationChecks(schema, requestObj);
  const { errorFields } = generateErrors(errors, requestObj, errFields);
  return errorFields;
};
