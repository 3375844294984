/**
 * @file   src\containers\MobileVerification.tsx
 * @brief  Mobile verification component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, useEffect, useNavigate, useState, Row, Col } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import KKIInputMask from '../components/KKIInputMask';
import { RoutePath } from '../utils/enums';
import { IObject } from '../interfaces/generalInterface';
import { IMobileVerificationProps } from '../interfaces/userInterface';
import { useAppDispatch, useAppSelector } from '../hooks';
import { forgotPassword, revertAllCommonState } from '../store/actions/commonAction';
import { IForgotPasswordOtpSendRequest } from '../interfaces/commonInterface';
import { RootState } from '../store';
import { HTTP_STATUS_200, PHONE_COUNTRY_CODE } from '../utils/constants';
import { MessageToaster } from '../utils/toastUtils';
import Loader from '../components/Loader';
import { validateForm } from '../utils/validationHelper';
import { PHONE_VALIDATION_SCHEMA } from '../validations/userSchema';

// Toast object creation.
const toast = new MessageToaster();

const MobileVerification = ({ setMobileNumber }: IMobileVerificationProps) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isForgotOtpSendLoading, isForgotOtpSendSuccess, forgotOtpSendErrorCode, forgotOtpSendMessage, forgotOtp } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [mobile, setMobile] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllCommonState());
    };
  }, []);

  // Forgot password api response side effect handling.
  useEffect(() => {
    if (isForgotOtpSendSuccess && forgotOtpSendErrorCode === HTTP_STATUS_200 && forgotOtpSendMessage) {
      toast.toastSuccess(`${forgotOtpSendMessage}. OTP:- ${forgotOtp}`);
      setMobileNumber(mobile);
    } else if (!isForgotOtpSendSuccess && forgotOtpSendErrorCode > HTTP_STATUS_200 && forgotOtpSendMessage) {
      toast.toastError(forgotOtpSendMessage);
    }
  }, [isForgotOtpSendSuccess, forgotOtpSendErrorCode, forgotOtpSendMessage, forgotOtp]);

  // Input mobile element change event handler.
  const onMobileInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    const errorresult = await validateForm({ mobile: inputVal }, PHONE_VALIDATION_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setMobile(inputVal);
  };

  // Forgot password form submission event.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const errorresult = await validateForm({ mobile }, PHONE_VALIDATION_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      dispatch(forgotPassword({ phone: `${PHONE_COUNTRY_CODE}${mobile}` } as IForgotPasswordOtpSendRequest));
    }
  };

  return (
    <>
      <h3 className="text-center">{Strings.HD.ForgotPassword}</h3>
      <h6 className="text-center">{Strings.Password.Forgot}</h6>
      <form onSubmit={onSubmit}>
        <KKIInputMask
          id="mobnumber"
          name="mobile"
          type="text"
          mask="999-999-9999"
          placeholder={Strings.CsLogin.MobileNumber}
          onChange={onMobileInputChange}
          value={mobile}
          alert={errorFields?.mobile || ''}
          verified={false}
        />
        <Row>
          <Col>
            <Button variant="outline-primary" type="button" className="mb-30 w-100" onClick={() => navigate(RoutePath.Login)}>
              {Strings.Button.Back}
            </Button>
          </Col>
          <Col>
            <Button variant="primary" type="submit" className="mb-30 w-100">
              {Strings.Button.Continue}
            </Button>
          </Col>
        </Row>
      </form>
      <p className="existing-account-info text-center">
        {Strings.CsLogin.SignUpConfirmationText}{' '}
        <a href={void 0} className="info-link" onClick={() => navigate(RoutePath.Signup)}>
          {Strings.CsLogin.CreateAccount}
        </a>
      </p>
      {isForgotOtpSendLoading && <Loader />}
    </>
  );
};

export default MobileVerification;
