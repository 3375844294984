import React from 'react';

const SortUpIcon = () => {
  return (
    <svg id="SortUp" xmlns="http://www.w3.org/2000/svg" width="6.707" height="10.161" viewBox="0 0 6.707 10.161">
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M9.542,13.925l2.536-2.538a.477.477,0,0,1,.677,0,.483.483,0,0,1,0,.679L9.882,14.942a.478.478,0,0,1-.661.014L6.327,12.068A.479.479,0,1,1,7,11.389Z"
        transform="translate(-6.187 -4.92)"
        fill="#1b1658"
        opacity="0.3"
      />
      <path
        id="Icon_ionic-ios-arrow-down-2"
        data-name="Icon ionic-ios-arrow-down"
        d="M9.542,12.4l2.536,2.538a.477.477,0,0,0,.677,0,.483.483,0,0,0,0-.679L9.882,11.386a.478.478,0,0,0-.661-.014L6.327,14.26A.479.479,0,0,0,7,14.939Z"
        transform="translate(-6.187 -11.246)"
        fill="#1b1658"
      />
    </svg>
  );
};

export default SortUpIcon;
