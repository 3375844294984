/**
 * @file   src\store\slices\manageHolidaySlice.ts
 * @brief  This file is responsible for creating holiday functionalities slices to call actions and state management.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getAllHolidayList, addHoliday, revertAllHolidayState, deleteHoliday, editHoliday } from '../actions/manageHolidayAction';
import { IHoliday } from '../../interfaces/holidaysInterface';
// Initial state.
const holidayState: IHoliday = {
  isHolidayListLoading: false,
  isHolidayListSuccess: false,
  holidayList: [],
  holidayListErrorCode: 0,
  holidayListMessage: null,

  isAddNewHolidaySuccess: false,
  isAddNewHolidayLoading: false,
  addNewHolidayErrorCode: 0,
  addNewHolidayMessage: null,

  isDeleteHolidaySuccess: false,
  isDeleteHolidayLoading: false,
  deleteHolidayRequestErrorCode: 0,
  deleteHolidayRequestMessage: null,

  isEditHolidaySuccess: false,
  isEditHolidayLoading: false,
  editHolidayErrorCode: 0,
  editHolidayMessage: null,
};

// Config holiday slice.
export const manageHolidaySlice = createSlice({
  name: 'manageHoliday',
  initialState: holidayState,
  reducers: {
    resetHolidayState: () => holidayState,
  },
  extraReducers(builder) {
    // ********************* start of fetch Holiday list action state handling. *********************

    // Fetch Holiday list default state.
    builder.addCase(getAllHolidayList.pending, (state) => {
      state.isHolidayListLoading = true;
      state.isHolidayListSuccess = false;
      state.holidayListErrorCode = 0;
      state.holidayListMessage = '';
    });
    // Fetch Holiday list success state.
    builder.addCase(getAllHolidayList.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isHolidayListLoading = false;
      state.isHolidayListSuccess = true;
      state.holidayList = info || [];
      state.holidayListErrorCode = code;
      state.holidayListMessage = status || '';
    });
    // Fetch Holiday list error state.
    builder.addCase(getAllHolidayList.rejected, (state, action: any) => {
      const { error, errcode } = action.payload;
      state.isHolidayListLoading = false;
      state.isHolidayListSuccess = false;
      state.holidayListErrorCode = errcode;
      state.holidayListMessage = error || '';
    });
    // ********************* End of Holiday list action state handling. *********************

    // ********************* start of holiday add action state handling. *********************

    // Add Holiday default state.
    builder.addCase(addHoliday.pending, (state) => {
      state.isAddNewHolidayLoading = true;
      state.isAddNewHolidaySuccess = false;
      state.addNewHolidayErrorCode = 0;
      state.addNewHolidayMessage = '';
    });
    // Add Holiday success state.
    builder.addCase(addHoliday.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isAddNewHolidayLoading = false;
      state.isAddNewHolidaySuccess = true;
      state.addNewHolidayErrorCode = code;
      state.addNewHolidayMessage = status || '';
    });
    // Add Holiday error state.
    builder.addCase(addHoliday.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isAddNewHolidayLoading = false;
      state.isAddNewHolidaySuccess = false;
      state.addNewHolidayErrorCode = errcode;
      state.addNewHolidayMessage = error || '';
    });
    // ********************* End of Holiday add action state handling. *********************

    // ********************* start of holiday delete action state handling. *********************

    // Delete Holiday default state.
    builder.addCase(deleteHoliday.pending, (state) => {
      state.isDeleteHolidayLoading = true;
      state.isDeleteHolidaySuccess = false;
      state.deleteHolidayRequestErrorCode = 0;
      state.deleteHolidayRequestMessage = '';
    });
    // Delete Holiday success state.
    builder.addCase(deleteHoliday.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeleteHolidayLoading = false;
      state.isDeleteHolidaySuccess = true;
      state.deleteHolidayRequestErrorCode = code;
      state.deleteHolidayRequestMessage = status || '';
    });
    // Delete Holiday error state.
    builder.addCase(deleteHoliday.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isDeleteHolidayLoading = false;
      state.isDeleteHolidaySuccess = false;
      state.deleteHolidayRequestErrorCode = errcode;
      state.deleteHolidayRequestMessage = error || '';
    });
    // ********************* End of Holiday delete action state handling. *********************

    // ********************* start of holiday edit action state handling. *********************

    // Edit Holiday default state.
    builder.addCase(editHoliday.pending, (state) => {
      state.isEditHolidayLoading = true;
      state.isEditHolidaySuccess = false;
      state.editHolidayErrorCode = 0;
      state.editHolidayMessage = '';
    });
    // Edit Holiday success state.
    builder.addCase(editHoliday.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isEditHolidayLoading = false;
      state.isEditHolidaySuccess = true;
      state.editHolidayErrorCode = code;
      state.editHolidayMessage = status || '';
    });
    // Edit Holiday error state.
    builder.addCase(editHoliday.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isEditHolidayLoading = false;
      state.isEditHolidaySuccess = false;
      state.editHolidayErrorCode = errcode;
      state.editHolidayMessage = error || '';
    });
    // ********************* End of Holiday edit action state handling. *********************

    // ********************* Clear all holiday states to initial state action. *********************
    builder.addCase(revertAllHolidayState, () => holidayState);
  },
});
// Export holiday actions.
export const { resetHolidayState } = manageHolidaySlice.actions;
// Export reducer.
export default manageHolidaySlice.reducer;
