/**
 * @file   src\store\actions\manageLeaveAction.ts
 * @brief  This file is responsible for creating asynchronous api call for leave management slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IApplyLeaveRequest, IApproveLeaveRequest, IDeclineLeaveRequest, IDeleteLeaveRequest, IGetAllLeaveRequest, IGetLeaveDetailsRequest } from '../../interfaces/leaveInterface';
import axios from '../../utils/apiConfig';
import { KKIActionTypes, ManageLeaveApi } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Get all requested leaves api call.
export const getAllLeaves = createAsyncThunk('/manageLeave/getAllLeaves', async (request: IGetAllLeaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageLeaveApi.GET_ALL_LEAVE_REQUEST}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Apply leave api call.
export const applyLeave = createAsyncThunk('/manageLeave/applyLeave', async (request: IApplyLeaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageLeaveApi.APPLY_LEAVE_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete leave requests api call.
export const deleteLeaveReuest = createAsyncThunk('/manageLeave/deleteLeaveReuest', async (request: IDeleteLeaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${ManageLeaveApi.DELETE_LEAVE_REQUEST}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Approve leave requests api call.
export const approveLeave = createAsyncThunk('/manageLeave/approveLeave', async (request: IApproveLeaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageLeaveApi.APPROVE_LEAVE_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Decline leave requests api call.
export const declineLeave = createAsyncThunk('/manageLeave/declineLeave', async (request: IDeclineLeaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageLeaveApi.DECLINE_LEAVE_REQUEST, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get leave details api call.
export const getLeavesDetails = createAsyncThunk('/manageLeave/getLeavesDetails', async (request: IGetLeaveDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageLeaveApi.GET_LEAVE_DETAILS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get leave decline reasons api call.
export const getDeclineLeaveReasons = createAsyncThunk('/manageLeave/getDeclineLeaveReasons', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ManageLeaveApi.GET_DECLINE_LEAVE_REASONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all leave states to initial sate action.
export const revertAllLeaveState = createAction(KKIActionTypes.REVERT_ALL_LEAVE_STATE);
