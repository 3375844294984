/**
 * @file   src\containers\CA\Profile\EditProfile.tsx
 * @brief  Edit Profile page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import { Col, Row, Link, Button, moment, useState, useNavigate, useEffect, Modal } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Arrow from '../../../assests/icons/DownArrow';
import KKIInput from '../../../components/KKIInput';
import KKIInputMask from '../../../components/KKIInputMask';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKICheckbox from '../../../components/KKICheckbox';
import { CARoutePath, ColorGroups } from '../../../utils/enums';
import { IProfileUpdateRequest } from '../../../interfaces/userInterface';
import { validateForm } from '../../../utils/validationHelper';
import { PROFILE_SETUP_SCHEMA } from '../../../validations/userSchema';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProfile, revertAllUserState, sendProfileEditOtp, updateProfile } from '../../../store/actions/userAction';
import { IObject } from '../../../interfaces/generalInterface';
import { RootState } from '../../../store';
import { HTTP_STATUS_200, NUMBER_10, NUMBER_150, NUMBER_50, PHONE_COUNTRY_CODE } from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import VerifyOtpForm from '../../../components/VerifyMobileModal';
import { revertAllCommonState, verifyOtp } from '../../../store/actions/commonAction';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const EditProfile = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const {
    isProfileFetchLoading,
    profileInfo,
    isProfileEditOtpSuccess,
    profileEditOtpErrorCode,
    profileEditOtpMessage,
    profileEditOtp,
    isProfileUpdateSuccess,
    profileUpdateErrorCode,
    profileUpdateMessage,
  } = useAppSelector((state: RootState) => state.user);
  const { isVerifyOtpLoading, isVerifyOtpSuccess, verifyOtpErrorCode, verifyOtpMessage } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [currentMobile, setCurrentMobile] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [hireDate, setHireDate] = useState<Date | null>(moment().toDate());
  const [colorGroup, setColorGroup] = useState<string | null>(null);
  const [isVolunteer, setIsVolunteer] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [showVerifyMobileModal, setShowVerifyMobileModal] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [showOtpTimer, setOtpTimerStatus] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(false);

  // Component initial loading sideEffect to fetch profile api.
  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(revertAllUserState());
      dispatch(revertAllCommonState());
    };
  }, []);

  // Profile api response state change side effect handler.
  useEffect(() => {
    if (profileInfo) {
      const phone = profileInfo?.phone ? profileInfo?.phone.substring(profileInfo.phone.length - NUMBER_10) : '';
      setFirstName(profileInfo?.firstName || '');
      setLastName(profileInfo?.lastName || '');
      setEmail(profileInfo?.email || '');
      setMobile(phone);
      setCurrentMobile(phone);
      setEmployeeId(profileInfo?.empId || '');
      setHireDate(profileInfo?.hireDate ? moment(profileInfo?.hireDate).local().toDate() : null);
      setColorGroup(ColorGroups[profileInfo?.colorGroup]);
      setIsVolunteer(profileInfo?.volunteer);
      setSubscribed(profileInfo?.isSubscribed || false);
    }
  }, [profileInfo]);

  // Otp send api response state side effect handling.
  useEffect(() => {
    if (isProfileEditOtpSuccess && profileEditOtpErrorCode === HTTP_STATUS_200 && profileEditOtpMessage) {
      setShowVerifyMobileModal(true);
      setOtpTimerStatus(true);
      toast.toastSuccess(`${profileEditOtpMessage}. OTP:- ${profileEditOtp}`);
    } else if (!isProfileEditOtpSuccess && profileEditOtpErrorCode > HTTP_STATUS_200 && profileEditOtpMessage) {
      toast.toastError(profileEditOtpMessage);
    }
  }, [isProfileEditOtpSuccess, profileEditOtpErrorCode, profileEditOtpMessage, profileEditOtp]);

  // Otp verify api response state side effect handling.
  useEffect(() => {
    if (isVerifyOtpSuccess && verifyOtpErrorCode === HTTP_STATUS_200 && verifyOtpMessage) {
      toast.toastSuccess(verifyOtpMessage);
      setShowVerifyMobileModal(false);
      setIsVerified(true);
    } else if (!isVerifyOtpSuccess && verifyOtpErrorCode > HTTP_STATUS_200 && verifyOtpMessage) {
      toast.toastError(verifyOtpMessage);
    }
  }, [isVerifyOtpSuccess, verifyOtpErrorCode, verifyOtpMessage]);

  // Update profile details api response state side effect handling.
  useEffect(() => {
    if (isProfileUpdateSuccess && profileUpdateErrorCode === HTTP_STATUS_200 && profileUpdateMessage) {
      toast.toastSuccess(profileUpdateMessage);
      setErrorFields(null);
      navigate(CARoutePath.Profile);
    } else if (!isProfileUpdateSuccess && profileUpdateErrorCode > HTTP_STATUS_200 && profileUpdateMessage) {
      toast.toastError(profileUpdateMessage);
    }
  }, [isProfileUpdateSuccess, profileUpdateErrorCode, profileUpdateMessage]);

  // Input first name element change event handler.
  const onFirstNameInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const profileSetupForm = {
      firstName: value,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setFirstName(value);
  };

  // Input last name element change event handler.
  const onLastNameInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const profileSetupForm = {
      lastName: value,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setLastName(value);
  };

  // Input email element change event handler.
  const onEmailInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const profileSetupForm = {
      email: value,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setEmail(value);
  };

  // Input mobile element change event handler.
  const onMobileInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    const profileSetupForm = {
      mobile: inputVal,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setMobile(inputVal);
  };

  // IsVoluntary checkbox change event handler.
  const onIsVoluntaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsVolunteer(checked);
  };

  // Otp verification submission event.
  const onVerifyOtpSubmit = (otp: string) => {
    dispatch(verifyOtp({ otp, phone: `${PHONE_COUNTRY_CODE}${mobile}` }));
  };

  // Subscription checkbox change event handler.
  const onSubscriptionCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setSubscribed(name === Strings.SetupProfile.Subscribed.toLocaleLowerCase() ? true : false);
  };

  // Otp resend method.
  const onResend = () => dispatch(sendProfileEditOtp({ phone: `${PHONE_COUNTRY_CODE}${mobile}`, isResend: true }));

  // Edit profile form submit button event handler.
  const onSubmit = async (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    const profileSetupForm = {
      firstName,
      lastName,
      email,
      mobile,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length === 0) {
      if (currentMobile !== mobile && !isVerified) {
        dispatch(sendProfileEditOtp({ phone: `${PHONE_COUNTRY_CODE}${mobile}` }));
      } else {
        const profileUpdateRequest: IProfileUpdateRequest = {
          firstName,
          lastName,
          email,
          phone: `${PHONE_COUNTRY_CODE}${mobile}`,
          volunteer: isVolunteer,
          isSubscribed: subscribed
        };
        dispatch(updateProfile(profileUpdateRequest));
      }
    }
  };

  return (
    <div>
      <div className="pageheader d-flex justify-content-between">
        <h1>
          <Link to={CARoutePath.Profile} className="back-btn">
            <Arrow />
          </Link>
          {Strings.HD.EditProfile}
        </h1>
      </div>
      <div className="profile-main">
        <Row>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <KKIInput
              id="firstname"
              label={Strings.SetupProfile.FirstName}
              name="firstName"
              type="text"
              value={firstName}
              placeholder={Strings.SetupProfile.FirstName}
              onChange={onFirstNameInputChange}
              alert={errorFields?.firstName || ''}
              maxLength={NUMBER_50}
            />
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <KKIInput
              id="lastname"
              label={Strings.SetupProfile.LastName}
              name="lastName"
              type="text"
              value={lastName}
              placeholder={Strings.SetupProfile.LastName}
              onChange={onLastNameInputChange}
              alert={errorFields?.lastName || ''}
              maxLength={NUMBER_50}
            />
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <KKIInput
              id="email"
              label={Strings.SetupProfile.Email}
              placeholder={Strings.SetupProfile.Email}
              name="email"
              type="text"
              value={email}
              onChange={onEmailInputChange}
              alert={errorFields?.email || ''}
              maxLength={NUMBER_150}
            />
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <KKIInputMask
              id="mobnumber"
              label={Strings.SetupProfile.MobileNumber}
              name="mobile"
              type="text"
              mask="999-999-9999"
              value={mobile}
              placeholder={Strings.CsLogin.MobileNumber}
              onChange={onMobileInputChange}
              alert={errorFields?.mobile || ''}
              verified={isVerified}
            />
          </Col>
          <Col className="profile-edit">
            <Row>
              <Col>
                <KKIInput
                  id="employeeID"
                  label={Strings.SetupProfile.EmpId}
                  placeholder={Strings.SetupProfile.EmpId}
                  name="employeeId"
                  type="text"
                  value={employeeId}
                  alert={errorFields?.employeeId || ''}
                  disabled
                />
              </Col>
              <Col>
                <KKIDatepicker
                  label={Strings.SetupProfile.HireDate}
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder={Strings.SetupProfile.HireDate}
                  dateFormat="dd MMM yyyy"
                  value={hireDate}
                  disabled
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <KKIInput id="ColorGroup" label={Strings.Label.ColorGroup} placeholder={Strings.SetupProfile.ColorGroup} name="ColorGroup" type="text" value={colorGroup} disabled />
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-edit">
            <label className="form-label">{Strings.SetupProfile.ReceiveSMS}</label>
            <Col>
              <KKICheckbox id="signup" name="subscribed" checkBoxType="radio" label={Strings.SetupProfile.Subscribe} inline checked={subscribed} onChange={onSubscriptionCheckChange} />
              <KKICheckbox id="signup" name="unsubscribed" checkBoxType="radio" label={Strings.SetupProfile.unSubscribe} inline checked={!subscribed} onChange={onSubscriptionCheckChange} />
            </Col>
          </Col>
          <Col xl={12}>
            <label htmlFor="KeyIndicator" className="form-label">
              {Strings.Label.KeyIndicators}
            </label>
            <KKICheckbox id="p2" key={2} name="publishStatus" checkBoxType="checkbox" label="Volunteer" checked={isVolunteer} onChange={onIsVoluntaryChange} />
          </Col>
          <Col className="my-3">
            <Button variant="primary" onClick={onSubmit}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Row>
      </div>
      <Modal show={showVerifyMobileModal} onHide={() => setShowVerifyMobileModal(false)} centered className="verify-mob-modal" backdrop="static">
        <Modal.Header closeButton className="bg-transparent" />
        <VerifyOtpForm onSubmit={(otp: string) => onVerifyOtpSubmit(otp)} onResend={onResend} showOtpTimer={showOtpTimer} setTimerStatus={(val) => setOtpTimerStatus(val)} />
      </Modal>
      {(isProfileFetchLoading || isVerifyOtpLoading) && <Loader />}
    </div>
  );
};
export default EditProfile;
