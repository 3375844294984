/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for creating asynchronous api call for auth slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { IAuthRequest, IUserSignUpRequest } from '../../interfaces/authInterface';
import { AuthApi, KKIActionTypes } from '../../utils/apiUrls';

// User authentication api call.
export const validateUser = createAsyncThunk('/auth/validateUser', async (request: IAuthRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post(AuthApi.LOGIN, request);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// User signup api call.
export const signUp = createAsyncThunk('/auth/signUp', async (request: IUserSignUpRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post(AuthApi.SIGNUP, request);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Admin authentication api call.
export const validateAdminUser = createAsyncThunk('/auth/validateAdminUser', async (request: IAuthRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.ADMIN_LOGIN, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Admin user authentication api call.
export const adminSignUp = createAsyncThunk('/auth/adminSignUp', async (request: IUserSignUpRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.ADMIN_SIGNUP, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all auth states to initial sate action.
export const revertAllAuthState = createAction(KKIActionTypes.REVERT_ALL_AUTH_STATE);
