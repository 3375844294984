/**
 * @file   src\containers\CA\ManageSwap\MyRequests.tsx
 * @brief  Component for swap my requests tab data.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Col, Row, useEffect } from '../../../components/ThirdPartyComponents';
import SwapCard from '../../../components/SwapCard';
import SwapFilter from './SwapFilter';
import { ISwapRequestsProps } from '../../../interfaces/generalInterface';
import { ISwapRequests } from '../../../interfaces/swapInterface';
import { useAppDispatch } from '../../../hooks';
import { revertAllSwapState } from '../../../store/actions/manageSwapAction';
import Strings from '../../../assests/strings/Strings.json';

const MyRequests = ({ onSearch, onApplyFilter, onResetFilter, swapRequests }: ISwapRequestsProps) => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Component initial state side effect.
  useEffect(() => {
    return () => {
      dispatch(revertAllSwapState());
    };
  }, []);

  return (
    <>
      <SwapFilter onApplyFilter={onApplyFilter} onResetFilter={onResetFilter} onSearch={onSearch} />
      <Row>
        {swapRequests &&
          swapRequests?.map((swapItem: ISwapRequests) => (
            <Col key={swapItem.swapRequestId} xl={6} md={12} sm={12}>
              <SwapCard myrequest swapRequests={swapItem} />
            </Col>
          ))}
        {(!swapRequests || (swapRequests && swapRequests.length === 0)) && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>}
      </Row>
    </>
  );
};
export default MyRequests;
