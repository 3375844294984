import React from 'react';

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        id="Union_7"
        data-name="Union 7"
        d="M8,8,0,16,8,8,0,0,8,8l8-8L8,8l8,8Z"
        transform="translate(1 1)"
        fill="none"
        stroke="#400286"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CloseIcon;
