import React from 'react';

const ManageScheduleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.352" height="28" viewBox="0 0 25.352 28">
      <g id="Group_16180" data-name="Group 16180" transform="translate(-160.91 -163.001)">
        <path
          id="Union_8"
          data-name="Union 8"
          d="M11.471,21.155a6.893,6.893,0,0,1,6.94-6.845,7.027,7.027,0,0,1,1.734.216q-.126-.032-.252-.059V10.237H1.264v9.977a1.593,1.593,0,0,0,1.579,1.6H11.5a6.709,6.709,0,0,0,.24,1.242h-8.9A2.848,2.848,0,0,1,0,20.214V4.649A2.847,2.847,0,0,1,2.843,1.8H5.525V.624a.632.632,0,0,1,1.264,0V1.8h7.578V.624a.631.631,0,0,1,1.261,0V1.8h2.686a2.847,2.847,0,0,1,2.841,2.846V14.866l-.028-.012a6.868,6.868,0,1,1-9.655,6.3Zm1.261,0a5.679,5.679,0,1,0,5.679-5.6A5.645,5.645,0,0,0,12.732,21.155ZM21.1,14.842l-.031-.013ZM21,14.8h0Zm-.626-.215h0Zm-.075-.022-.038-.01Zm-.078-.021-.076-.02Zm-18.96-9.9V8.994h18.63V4.649a1.593,1.593,0,0,0-1.579-1.6H15.628V4.2a.631.631,0,0,1-1.261,0V3.047H6.789V4.2a.632.632,0,0,1-1.264,0V3.047H2.843A1.593,1.593,0,0,0,1.264,4.649ZM17.781,22.863V17.558a.631.631,0,0,1,1.262,0v4.061h3.168a.623.623,0,1,1,0,1.245Z"
          transform="translate(160.91 163.001)"
          fill="#1b1658"
        />
      </g>
    </svg>
  );
};

export default ManageScheduleIcon;
