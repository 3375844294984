/**
 * @file   src\components\ProtectedRouteCA.tsx
 * @brief  This component used to manage and protect unauthorized/permission based url access for CA.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Navigate, useLocation, matchPath } from './ThirdPartyComponents';
import { ProtectedRouteProps } from '../interfaces/generalInterface';
import { getDataFromStorage } from '../utils/helper';
import { STORAGE_USER } from '../utils/constants';
import { CARoutePath, RoleTypes, RoutePath } from '../utils/enums';

const ProtectedRouteCA = ({ children }: ProtectedRouteProps) => {
  /* Create location object to access location info. */
  const location = useLocation();

  const userInfo = getDataFromStorage(STORAGE_USER);
  const isAuthenticated = userInfo?.access_token !== '' && userInfo?.is_profile_completed === true && userInfo.roleId === RoleTypes.CA;

  /* Check whether the user is authenticated or not and redirected to login page. */
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const cARoutePaths: string[] = Object.values(CARoutePath);
  if (!cARoutePaths.some((url) => matchPath(url, location?.pathname))) {
    return <Navigate to={CARoutePath.Schedule} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRouteCA;
