/**
 * @file   src\utils\apiConfig.ts
 * @brief  This file is responsible for configure axios client.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import axios, { AxiosRequestConfig } from 'axios';
import { HTTP_STATUS_401, API_TIMEOUT, API_MAXCONTENTLENGTH, API_REDIRECTS, STORAGE_USER, API_REFRESH_TOKEN_ERROR, SESSION_TYPE_TEXT } from './constants';
import { CommonApi } from './apiUrls';
import { getDataFromStorage, setStorageData } from './helper';
import { RoutePath } from './enums';

// Craete axios instance.
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Set axios headers.
const headers: any = {};
// headers['Access-Control-Allow-Origin'] = '*';
// headers['Access-Control-Allow-Headers'] = '*';
// headers['Access-Control-Allow-Credentials'] = true;
// headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'application/json';
headers.Accept = 'application/json';
headers['Cache-Control'] = 'no-cache';
headers.ApiKey = process.env.REACT_APP_API_API_KEY;
// headers['x-app-type'] = '';

// Assign default values.
// axiosClient.defaults.baseURL = '';
axiosClient.defaults.headers = headers;
axiosClient.defaults.timeout = API_TIMEOUT;
axiosClient.defaults.maxContentLength = API_MAXCONTENTLENGTH;
axiosClient.defaults.maxRedirects = API_REDIRECTS;
axiosClient.defaults.withCredentials = true;

// Renew access token using refresh token.
export const renewToken = async () => {
  try {
    const { data } = await axiosClient.get(CommonApi.REFRESH_TOKEN);
    return data;
  } catch (error: any) {
    return Promise.reject(error.message);
  }
};

// Define the structure of a retry queue item.
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue.
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests.
let isRefreshing = false;

// Handle interceptor request.
axiosClient.interceptors.request.use(
  async (config: any) => {
    const userInfo = getDataFromStorage(STORAGE_USER);
    let token = userInfo?.access_token;
    if (config.url === CommonApi.REFRESH_TOKEN) {
      token = userInfo?.refresh_token;
    }
    // Authorization header with the Bearer token.
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Handle interceptor response.
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === HTTP_STATUS_401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          const res = await renewToken();
          const newAccessToken = res?.info?.access_token || '';
          // Update the request headers with the new access token
          error.config.headers.Authorization = `Bearer ${newAccessToken}`;
          const storageData = getDataFromStorage(STORAGE_USER);
          storageData.access_token = newAccessToken;
          localStorage.removeItem(STORAGE_USER);
          setStorageData(STORAGE_USER, JSON.stringify(storageData));
          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosClient
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosClient(originalRequest);
          // eslint-disable-next-line no-useless-catch
        } catch (refreshError) {
          localStorage.clear();
          window.location.href = `${RoutePath.Login}?type=${SESSION_TYPE_TEXT}`;
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    if (error.response && error.response.status === API_REFRESH_TOKEN_ERROR) {
      localStorage.clear();
      window.location.href = `${RoutePath.Login}?type=${SESSION_TYPE_TEXT}`;
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error.response);
  },
);

// Export default methods.
export default {
  get: axiosClient.get,
  post: axiosClient.post,
  put: axiosClient.put,
  patch: axiosClient.patch,
  delete: axiosClient.delete,
};
