/**
 * @file   src\assets\icons\Password.tsx
 * @brief  Password icon component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { IPasswordIconInterface } from '../../interfaces/generalInterface';

const PasswordShowIcon = ({ togglePassword }: IPasswordIconInterface) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.508" height="17.574" viewBox="0 0 20.508 17.574" onClick={() => togglePassword?.(true)}>
      <path
        id="eye-off"
        d="M12.506,8.2a4.649,4.649,0,0,1,4.664,4.627,4.451,4.451,0,0,1-.334,1.693l2.723,2.7a10.941,10.941,0,0,0,3.2-4.394A11.044,11.044,0,0,0,8.785,6.532l2.014,2A4.611,4.611,0,0,1,12.506,8.2ZM3.184,5.676l2.128,2.11.43.426A10.889,10.889,0,0,0,2.25,12.825a11.061,11.061,0,0,0,14.339,6.16l.394.389,2.732,2.7L20.9,20.9,4.364,4.5Zm5.153,5.112,1.446,1.432a2.622,2.622,0,0,0-.073.6,2.782,2.782,0,0,0,2.8,2.773,2.63,2.63,0,0,0,.6-.073l1.446,1.432a4.639,4.639,0,0,1-6.714-4.137A4.572,4.572,0,0,1,8.337,10.788Zm4.018-.719,2.938,2.915.018-.146a2.782,2.782,0,0,0-2.8-2.773Z"
        transform="translate(-2.25 -4.5)"
        fill="#878db0"
      />
    </svg>
  );
};

export default PasswordShowIcon;
