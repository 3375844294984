/**
 * @file   src\utils\enums.ts
 * @brief  This file is responsible for defining enums.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

// Swap Request Status enum.
export enum SwapRequestStatus {
  Accepted = 1,
  Declined = 2,
  Pending_Supervisor_Approval = 3,
}

// Common root path.
export enum RoutePath {
  Login = '/',
  Signup = '/signup',
  SetupProfile = '/setup-profile',
  ForgotPassword = '/forgot-reset-password',
  ResetPassword = '/reset-password',
  NotFound = '/not-found',
}

// CA Route path.
export enum CARoutePath {
  Home = '/home',
  Schedule = '/home/:tabType',
  ShiftDetails = '/shift-details/:shiftId/:type',
  CreateSwapRequest = '/swap-request/:shiftId',
  ManageSwap = '/manage-swap',
  ManageLeave = '/manage-leave',
  Profile = '/profile',
  EditProfile = '/editprofile',
  ChangePassword = '/change-password',
}

// Scheduler Route path.
export enum AdminRoutePath {
  ManageSchedule = '/sch-manage-schedules',
  SCHShiftDetails = '/sch-shift-details/:shiftId/:type',
  SCHShiftDetailsByCAId = '/sch-shift-details/:shiftId/:type/:caId',
  SCHAssignCA = '/sch-assign-ca/:shiftId/:type',
  SCHAssignCAByCAId = '/sch-assign-ca/:shiftId/:type/:caId',
  SCHViewSchedules = '/sch-view-schedules',
  SCHCASchedules = '/sch-view-schedules/:caId',
  SCHManageCA = '/sch-manage-ca',
  SCHCADetails = '/sch-ca-details/:caId',
  SCHCallOut = '/sch-callout',
  SCHCAResource = '/sch-caresource/:shiftId/:shiftFromTime/:shiftDate',
  SCHManageSwap = '/sch-manage-swap',
  SCHManageLeave = '/sch-manage-leave',
  SCHManageHolidays = '/sch-holidays',
  SCHManageProfile = '/sch-profile',
  SCHEditProfile = '/sch-edit-profile',
  ChangePassword = '/sch-change-password',
}

// Manager Route path.
export enum ManagerRoutePath {
  MNGRReport = '/manager-report',
  MNGRUserList = '/manager-userlist',
  MNGRUserDetails = '/manager-userview/:schedulerId',
  TrackLDReason = '/track-ld',
}

// Role Types.
export enum RoleTypes {
  Role = 0,
  CA = 3,
  Scheduler = 2,
  Manager = 1,
}
// Mapping object for Roletypes
export const RoleTypeNames: { [key in RoleTypes]: string } = {
  [RoleTypes.Role]: 'Role',
  [RoleTypes.CA]: 'CA',
  [RoleTypes.Scheduler]: 'Scheduler',
  [RoleTypes.Manager]: 'Manager',
};
// Use status Types.
export enum UserStatusTypes {
  Inactive = 0,
  Active = 1,
}

// Call out shift types
export enum CallOutShiftTypes {
  All = 0,
  Less_Than_a_Shift_Away = 1,
  Less_Than_90_minutes_Away = 2,
  Less_Than_60_Minutes_Away = 3,
}

// Leave status enum.
export enum LeaveStatus {
  Pending_Approval = 1,
  Approved = 2,
  Declined = 3,
}

// CA assign to a shift status enum.
export enum CAAssignStatus {
  Assign = 1,
  Unassign = 2,
}

// Color grops.
export enum ColorGroups {
  None = 0,
  Yellow = 1,
  Red = 2,
  Blue = 3,
}

// Date time manipulating types.
export enum DateTimeCalculatedTypes {
  Days = 1,
  Week = 2,
  Month = 3,
  Year = 4,
  Hour = 5,
  Minute = 6,
  Second = 7,
}

// MAthematical operation types.
export enum OperationTypes {
  Add = 1,
  Subtract = 2,
}

// Previous, Next enum types.
export enum PrevNextTypes {
  Prev = 1,
  Next = 2,
}

// Leave types.
export enum LeaveTypes {
  Sick = 1,
  Bereavement = 2,
  Vacation_Day = 3,
  Floating_Holiday = 4,
  Free_Days = 5,
}

// Shift types.
export enum ShiftTypes {
  DayShift = 1,
  EveningShift = 2,
  NightShift = 3,
}

// Shift assigned by types.
export enum ShiftAssignedBy {
  Self = 1,
  Scheduler = 2,
}

// Schedule tab types.
export enum ScheduleTabs {
  FullSchedule = 1,
  MySchedule = 2,
}

// My schedule cell action types.
export enum MyScheduleActions {
  Unassign = 1,
  Callout = 2,
  DeleteLeave = 3,
  LeaveDetails = 4,
  DeleteShift = 5,
  Publish = 6,
  Assign = 7,
  AssignAsOnCall = 8,
}

// My schedule cell action types.
export enum ScheduleSortBy {
  Shift_Timing = 0,
  Open_Slot = 1,
  LD_Open_Slot = 2,
}

// Create shift select types.
export enum CreateShiftTypes {
  OneTime = 1,
  Recurring = 2,
}

// Shift Current status types.
export enum ShiftStatus {
  Draft = 0,
  Published = 1,
}

// CA Key indicators.
export enum KeyIndicators {
  Student = 1,
  Voluntary = 2,
  LightDuty = 3,
  RestrictedOT = 4,
  PartTime = 5,
}

// Key Indicators For CALLout CA   List.
export enum KeyIndicatorsForCALLoutCAList {
  Volunteer = 'Volunteer',
  PartTime24 = 'Part Time - 24 Hrs',
  PartTime36 = 'Part Time - 36 Hrs',
  fullTime = 'Full Time',
  LightDuty = 'Light Duty',
  RestrictedOT = 'Restricted OT',
  FloatStaff = 'Float',
  OnPrem = 'On-Prem',
  Student = 'Student',
}

// Call Out Type Text.
export enum CallOutTypeText {
  LESS_THAN_SHIFT_AWAY = 'Less than a shift away',
  LESS_THAN_90_MINS_AWAY = 'Less than 90 mins. away',
  LESS_THAN_60_MINS_AWAY = 'Less than 60 mins. away',
}

// Leave Request Status enum.
export enum LeaveRequestStatus {
  Pending_Approval = 1,
  Accepted = 2,
  Declined = 3,
}

// Swap status enum.
export enum SwapStatus {
  Pending_Partner_Approval = 1,
  Partner_Declined = 2,
  Pending_Supervisor_Approval = 3,
  Supervisor_Approved = 4,
  Supervisor_Declined = 5,
}

// Admin swap status view enum.
export enum SwapStatusByAdmin {
  Pending_Supervisor_Approval = 3,
  Approved = 4,
  Declined = 5,
}

// Swap request status types.
export enum SwapRequestStatusTypes {
  Pending_Partner_Approval = 1,
  Pending_My_Approval = 2,
}

// Swap actions.
export enum SwapActions {
  Accept = 1,
  Decline = 2,
  Resend = 3,
  Delete = 4,
}

// Mapping object for ColorGroups
export const ColorGroupNames: { [key in ColorGroups]: string } = {
  [ColorGroups.None]: 'None',
  [ColorGroups.Yellow]: 'Yellow',
  [ColorGroups.Red]: 'Red',
  [ColorGroups.Blue]: 'Blue',
};

// No show types
export enum NoShow {
  Show = 1,
  No_Show = 2,
  Left_Early = 3,
  Show_Late = 4,
}

// Navigation to Employee card from page types.
export enum NavigationToEmployeeCardFrom {
  Shift_Details_Page = 1,
  CA_Assign_Page = 2,
  Callout_Page = 3,
}

// Sort order type enum.
export enum SortOrderType {
  'ASC' = 1,
  'DESC' = 2,
}

// Attendance report sort fields enum.
export enum AttendanceReportSortField {
  caName = 1,
  shiftName = 2,
  isNoShow = 3,
}

// Ld reasons sort fields enum.
export enum LdReasonsSortField {
  name = 1,
  reportedDate = 2,
}

// Injury sub types.
export enum InjurySubTypes {
  Work_Injury = 1,
  Non_Work_Injury = 2,
}
