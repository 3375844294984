/**
 * @file   src\components\CAMenu.tsx
 * @brief  This component used to create CA menu.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { MenuItem, useNavigate, useLocation } from './ThirdPartyComponents';
import ManageScheduleIcon from '../assests/icons/ManageSchedule';
import ManageSwapIcon from '../assests/icons/ManageSwap';
import ManageLeaveIcon from '../assests/icons/ManageLeave';
import { CARoutePath } from '../utils/enums';
import Strings from '../assests/strings/Strings.json';
import { checkPathMatched } from '../utils/helper';
import { IMenuItemProp } from '../interfaces/generalInterface';

const CAMenu = ({ toggleMenu }: IMenuItemProp) => {
  // Navigate object creation.
  const navigate = useNavigate();

  /* Create location object to access location info. */
  const location = useLocation();

  // Menu navigation event.
  const navigateUrl = (url: string) => {
    navigate(url);
    toggleMenu(false);
  };

  return (
    <>
      <MenuItem
        active={checkPathMatched([CARoutePath.Home, CARoutePath.Schedule, CARoutePath.ShiftDetails], location?.pathname)}
        icon={<ManageScheduleIcon />}
        onClick={() => navigateUrl(CARoutePath.Home)}
      >
        {Strings.Menu.ManageSchedules}
      </MenuItem>
      <MenuItem
        active={checkPathMatched([CARoutePath.ManageSwap, CARoutePath.CreateSwapRequest], location?.pathname)}
        onClick={() => navigateUrl(CARoutePath.ManageSwap)}
        icon={<ManageSwapIcon />}
      >
        {Strings.Menu.ManageSwaps}
      </MenuItem>
      <MenuItem active={checkPathMatched([CARoutePath.ManageLeave], location?.pathname)} onClick={() => navigateUrl(CARoutePath.ManageLeave)} icon={<ManageLeaveIcon />}>
        {Strings.Menu.ManageLeave}
      </MenuItem>
    </>
  );
};

export default CAMenu;
