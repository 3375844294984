/**
 * @file   src\containers\Scheduler\Schedule\ManageSchedules.tsx
 * @brief  All schedules list page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, Row, Col, useState, Dropdown, moment, useNavigate, useEffect, Modal } from '../../../components/ThirdPartyComponents';
import CreateShiftComponent from './CreateShift';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKISelect from '../../../components/KKISelect';
import Strings from '../../../assests/strings/Strings.json';
import ShiftTypes from '../../../components/commonComponents/ShiftTypes';
import ShiftCard from '../../../components/ShiftCard';
import { IGetAllShiftsRequest, ISelectOptions, IShift, IShiftTypes } from '../../../interfaces/scheduleInterface';
import { IObject } from '../../../interfaces/generalInterface';
import { getCalculatedDateTime, getDataFromStorage, setStorageData } from '../../../utils/helper';
import { DateTimeCalculatedTypes, OperationTypes, ScheduleSortBy, AdminRoutePath, RoleTypes } from '../../../utils/enums';
import { DATE_FORMAT_YYYY_MM_DD, DEAULT_TIME_FORMAT, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_6, STORAGE_MANAGE_SCHEDULE_FILTER, STORAGE_USER } from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import { AT_LEAST_ONE_SHIFT_REQUIRED } from '../../../messages/validationMessages';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { getAllShiftTypes } from '../../../store/actions/manageCAScheduleAction';
import Loader from '../../../components/Loader';
import { getAllShiftsWithSummary, nofifyCA, publishShift, revertAllAdminScheduleState } from '../../../store/actions/manageAdminScheduleAction';

// Toast object creation.
const toast = new MessageToaster();

const ManageSchedules = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isShiftTypesSuccess, shiftTypesErrorCode, shiftTypesMessage, isShiftTypesLoading, shiftTypes } = useAppSelector((state: RootState) => state.manageCASchedule);
  const {
    isAddNewShiftLoading,
    isAddNewShiftSuccess,
    addNewShiftErrorCode,
    addNewShiftMessage,
    isGetAllShiftWithSummaryLoading,
    isGetAllShiftWithSummarySuccess,
    getAllShiftWithSummaryErrorCode,
    getAllShiftWithSummaryMessage,
    shiftListWithSummary,
    isPublishShiftLoading,
    isPublishShiftSuccess,
    publishShiftErrorCode,
    publishShiftMessage,
    isNotifyCASuccess,
    isNotifyCALoading,
    notifyCAErrorCode,
    notifyCAMessage,
  } = useAppSelector((state: RootState) => state.manageAdminSchedule);

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const roleId = userInfo?.roleId;

  // Get the filter data from session
  const filterDataFromStorage = getDataFromStorage(STORAGE_MANAGE_SCHEDULE_FILTER) || null;

  // Initialize component state variables.
  const [selectedShiftTypeIds, setSelectedShiftTypeIds] = useState<number[]>(filterDataFromStorage ? filterDataFromStorage.shiftType : []);
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(filterDataFromStorage ? filterDataFromStorage.published : [NUMBER_1, NUMBER_0]);
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(filterDataFromStorage ? filterDataFromStorage.startDate : moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(
    filterDataFromStorage ? filterDataFromStorage.endDate : getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment(startDateSelected).toDate()),
  );
  const [sortByFilter, setSortByFilter] = useState<IObject | null>({
    label: ScheduleSortBy[filterDataFromStorage ? filterDataFromStorage?.sortBy : ScheduleSortBy.Shift_Timing].replace(/_/g, ' '),
    value: filterDataFromStorage ? filterDataFromStorage?.sortBy.toString() : ScheduleSortBy.Shift_Timing.toString(),
  });
  const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState<number[]>([]);
  const [shiftsListData, setShiftsListData] = useState<IShift[]>([]);
  const [shiftTypeDetails, setShiftTypeDetails] = useState<IShiftTypes[]>([]);
  const [shifts, setShifts] = useState<ISelectOptions[]>([]);
  const [regularShiftsCount, setRegularShiftsCount] = useState<number>(NUMBER_0);
  const [lightDutyShiftsCount, setLightDutyShiftsCount] = useState<number>(NUMBER_0);
  const [pageSize] = useState<number>(NUMBER_0);
  const [pageIndex] = useState<number>(NUMBER_0);
  const [publishShiftPopup, showPublishShiftPopup] = useState<boolean>(false);
  const [notifyCAPopup, showNotifyCAPopup] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Unmount component.
  useEffect(() => {
    return () => {
      dispatch(revertAllAdminScheduleState());
    };
  }, []);

  // Shift type response state change.
  useEffect(() => {
    if (isShiftTypesSuccess && shiftTypesErrorCode === HTTP_STATUS_200 && shiftTypes) {
      const shiftIds = shiftTypes.map((shift) => shift.shiftId);
      if (selectedShiftTypeIds?.length === 0) {
        setSelectedShiftTypeIds(shiftIds);
      }
    } else if (!isShiftTypesSuccess && shiftTypesErrorCode > HTTP_STATUS_200 && shiftTypesMessage) {
      // toast.toastError(shiftTypesMessage);
    }
  }, [isShiftTypesSuccess, shiftTypesErrorCode, shiftTypes, shiftTypesMessage]);

  // Shifts list response state change.
  useEffect(() => {
    if (isGetAllShiftWithSummarySuccess && getAllShiftWithSummaryErrorCode === HTTP_STATUS_200 && shiftListWithSummary) {
      setShiftsListData(shiftListWithSummary?.shiftInfo);
      setRegularShiftsCount(shiftListWithSummary?.shiftSummary?.regularShift);
      setLightDutyShiftsCount(shiftListWithSummary?.shiftSummary?.lightDutyShifts);
    } else if (!isGetAllShiftWithSummarySuccess && getAllShiftWithSummaryErrorCode > HTTP_STATUS_200 && getAllShiftWithSummaryMessage) {
      // toast.toastError(getAllShiftMessage);
    }
  }, [isGetAllShiftWithSummarySuccess, getAllShiftWithSummaryErrorCode, getAllShiftWithSummaryMessage, shiftListWithSummary]);

  //  Dispatch an action to simulate shift type data fetching
  useEffect(() => {
    if (!isShiftTypesSuccess && !shiftTypesErrorCode) {
      dispatch(getAllShiftTypes());
    }
  }, [dispatch, isShiftTypesSuccess, shiftTypesErrorCode]);

  // Method to fetch shifts.
  const fetchShifts = () => {
    const shiftRequest: IGetAllShiftsRequest = {
      startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      published: selectedPublishStatus,
      shiftType: selectedShiftTypeIds,
      sortBy: sortByFilter?.value ? Number(sortByFilter?.value) : 1,
      page: pageIndex,
      limit: pageSize,
    };

    //  Set the filter details to the session storage
    const updatedDetails = {
      startDate: startDateSelected,
      endDate: endDateSelected,
      published: selectedPublishStatus,
      shiftType: selectedShiftTypeIds,
      sortBy: sortByFilter?.value ? Number(sortByFilter?.value) : 1,
    };

    setStorageData(STORAGE_MANAGE_SCHEDULE_FILTER, JSON.stringify(updatedDetails));

    dispatch(getAllShiftsWithSummary(shiftRequest));
  };

  // Unassign shift api response state change.
  useEffect(() => {
    if (isAddNewShiftSuccess && addNewShiftErrorCode === HTTP_STATUS_200 && addNewShiftMessage) {
      toast.toastSuccess(addNewShiftMessage);
      fetchShifts();
    } else if (!isAddNewShiftSuccess && addNewShiftErrorCode > HTTP_STATUS_200 && addNewShiftMessage) {
      toast.toastError(addNewShiftMessage);
    }
  }, [isAddNewShiftSuccess, addNewShiftErrorCode, addNewShiftMessage]);

  // Publish shift api response state change.
  useEffect(() => {
    if (isPublishShiftSuccess && publishShiftErrorCode === HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastSuccess(publishShiftMessage);
      setSelectedShiftId([]);
      fetchShifts();
    } else if (!isPublishShiftSuccess && publishShiftErrorCode > HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastError(publishShiftMessage);
    }
  }, [isPublishShiftSuccess, publishShiftErrorCode, publishShiftMessage]);

  // Notify CA api response state change.
  useEffect(() => {
    if (isNotifyCASuccess && notifyCAErrorCode === HTTP_STATUS_200 && notifyCAMessage) {
      toast.toastSuccess(notifyCAMessage);
      setSelectedShiftId([]);
      fetchShifts();
    } else if (!isNotifyCASuccess && notifyCAErrorCode > HTTP_STATUS_200 && notifyCAMessage) {
      toast.toastError(notifyCAMessage);
    }
  }, [isPublishShiftSuccess, notifyCAErrorCode, notifyCAMessage]);

  // Component initial loading side effect handler.
  useEffect(() => {
    if (selectedShiftTypeIds?.length > 0) {
      fetchShifts();
    }
  }, [shiftTypes, isApplyFilter, selectedPublishStatus, selectedShiftTypeIds, sortByFilter]);

  // Shift types state change side effect handling.
  useEffect(() => {
    if (shiftTypes && shiftTypes.length > 0) {
      const shiftTypeOptions = shiftTypes.map(
        (shiftType: IShiftTypes): ISelectOptions => ({ label: `${shiftType.shiftName} (${shiftType.shiftShortName})`, value: shiftType.shiftId.toString() }),
      );
      setShiftTypeDetails(shiftTypes);
      setShifts(shiftTypeOptions);
    }
  }, [shiftTypes]);

  // Start date filter input select event handler.
  const handleStartDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      setStartDateSelected(date);
      if (endDateSelected !== null && moment(date).isAfter(moment(endDateSelected))) {
        setEndDateSelected(date);
      }
    }
  };

  // End date filter input select event handler.
  const handleEndDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      setEndDateSelected(date);
    }
  };

  // Sort select change event handler.
  const onSortSelectChange = (val: IObject) => {
    setSortByFilter(val);
  };

  // Filter apply button event handler.
  const handleApply = (filters: { shiftTypeIds: number[]; publishStatus: number[] }) => {
    setSelectedShiftTypeIds(filters.shiftTypeIds);
    setSelectedPublishStatus(filters.publishStatus);
  };

  // Apply date-wise filter button submit event handler.
  const applyDateWiseFilter = () => {
    setIsApplyFilter(!isApplyFilter);
  };

  // Handles the checkbox change event from each EmployeeCard component.
  const handleCheckboxChange = (isChecked: boolean, shiftId: number) => {
    setSelectedShiftId((prevSelected) => (isChecked ? [...prevSelected, shiftId] : prevSelected.filter((id) => id !== shiftId)));
  };

  // Handles the "Notify CA" button click event.
  const handleNotifyCAClick = (): void => {
    showNotifyCAPopup(true);
  };

  // Notify CA confirmation Api call.
  const notifyCAConfirm = (): void => {
    dispatch(nofifyCA());
    showNotifyCAPopup(false);
  };

  // Handles the "Publish" button click event. Shows an error if no shifts are selected also show the confirmation popup.
  const handlePublishClick = (): void => {
    if (selectedShiftId.length === NUMBER_0) {
      toast.toastError(AT_LEAST_ONE_SHIFT_REQUIRED); // Show error toast
    } else {
      showPublishShiftPopup(true);
    }
  };

  // Method to handle the publish button click
  const publishButtonClick = () => {
    if (selectedShiftId.length > NUMBER_0) {
      showPublishShiftPopup(false);
      dispatch(publishShift({ shiftIds: selectedShiftId }));
    }
  };

  // Sort by filter options.
  const sortby = [
    { label: ScheduleSortBy[ScheduleSortBy.Shift_Timing].replace(/_/g, ' '), value: ScheduleSortBy.Shift_Timing.toString() },
    { label: ScheduleSortBy[ScheduleSortBy.Open_Slot].replace(/_/g, ' '), value: ScheduleSortBy.Open_Slot.toString() },
    { label: ScheduleSortBy[ScheduleSortBy.LD_Open_Slot].replace(/_/g, ' '), value: ScheduleSortBy.LD_Open_Slot.toString() },
  ];

  return (
    <div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>{Strings.HD.ManageSchedules}</h1>
          </Col>
          <Col xl="auto" lg="auto" md={12} className="text-end m-md-16">
            <div className="button-container-right">
              {roleId === RoleTypes.Scheduler && (
                <>
                  <Button variant="primary" onClick={handleShow}>
                    {Strings.Button.CreateShift}
                  </Button>
                  <Button variant="primary" onClick={handleNotifyCAClick}>
                    {Strings.Button.NotifyCA}
                  </Button>
                  <Button variant="primary" onClick={handlePublishClick}>
                    {Strings.Button.Publish}
                  </Button>
                </>
              )}

              <Button variant="outline-primary" onClick={() => navigate(AdminRoutePath.SCHViewSchedules)}>
                {Strings.Button.ViewSchedules}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="filter-main">
        <Row className="d-flex justify-content-between">
          <Col xl={6} lg={7} md={12} sm={12}>
            <Row className="d-flex align-items-center">
              <Col className="calendar-mob-left">
                <KKIDatepicker
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder="Start Date"
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  onChangeDatepicker={(date: any) => handleStartDateFilterChange(date)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder="End Date"
                  value={endDateSelected}
                  dateFormat="dd MMM yyyy"
                  onChangeDatepicker={(date: any) => handleEndDateFilterChange(date)}
                  minDate={startDateSelected || moment().toDate()}
                />
              </Col>
              <Col xs="auto" className="ps-0">
                <Button variant="primary" onClick={applyDateWiseFilter}>
                  {Strings.Button.Go}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xl={5} lg={5} md={12} sm={12} className="filter-sort m-md-16">
            <Row>
              <Col xs="auto">
                <Dropdown autoClose="outside" align="end">
                  <Dropdown.Toggle variant="outline-primary" id="ShiftSummary">
                    {Strings.Button.ShiftSummary}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shift-summary">
                    <div className="drop-header d-flex p-3 border-bottom">
                      <Col>
                        <h3 className="mb-0">{Strings.Button.ShiftSummary}</h3>
                      </Col>
                    </div>
                    <div className="d-flex p-3 gap-3 summary-details">
                      <Col>
                        {Strings.Label.RegularShifts}
                        <h2 className="mb-0">{regularShiftsCount || NUMBER_0}</h2>
                      </Col>
                      <Col>
                        {Strings.Label.LightDutyShifts}
                        <h2 className="mb-0">{lightDutyShiftsCount || NUMBER_0}</h2>
                      </Col>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col lg md={4} xs="auto">
                <Row className="d-flex align-items-center">
                  <Col xs="auto" className="pe-0">
                    <span>{Strings.Filter.Sort}:</span>
                  </Col>
                  <Col className="pe-0">
                    <KKISelect
                      id="signupselect"
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Sort.Select}
                      onSelectChange={onSortSelectChange}
                      searchvalue={false}
                      options={sortby}
                      defaultValue={sortByFilter}
                      value={sortByFilter}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="btn-container d-flex">
                <ShiftTypes
                  selectedShiftTypeIds={selectedShiftTypeIds}
                  selectedShiftStatus={selectedPublishStatus}
                  onChange={handleApply}
                  shiftTypes={shiftTypes}
                  leaveStatus={false}
                  isRosterView={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        {shiftsListData &&
          shiftsListData?.length > NUMBER_0 &&
          shiftsListData.map((shiftData: IShift) => (
            <Col xl={6} md={12} sm={12} key={shiftData.shiftId}>
              <ShiftCard shiftDetails={shiftData} scheduler checked={selectedShiftId.includes(shiftData.shiftId)} onCheckboxChange={handleCheckboxChange} />
            </Col>
          ))}
        {!shiftsListData || (shiftsListData && shiftsListData?.length === 0 && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>)}
      </Row>
      {/* Create shift popup */}
      {show && <CreateShiftComponent show={show} handleClose={handleClose} shift={shifts} shiftTypeDetails={shiftTypeDetails} isEdit={false} shiftDetails={null} />}

      <Modal show={publishShiftPopup} onHide={() => showPublishShiftPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{selectedShiftId?.length > NUMBER_1 ? Strings.Text.PublishShiftsPopText : Strings.Text.PublishPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showPublishShiftPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => publishButtonClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={notifyCAPopup} onHide={() => showNotifyCAPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.NotifyCAPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showNotifyCAPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => notifyCAConfirm()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      {(isGetAllShiftWithSummaryLoading || isShiftTypesLoading || isAddNewShiftLoading || isPublishShiftLoading || isNotifyCALoading) && <Loader />}
    </div>
  );
};
export default ManageSchedules;
