/**
 *@file KKICheckbox.tsx
 *@brief Reusable checkbox component
 *@date July, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

import { Form } from 'react-bootstrap';
import '../styles/KKICheckbox.scss';
import { IKKICheckbox } from '../interfaces/generalInterface';

const KKICheckbox = ({ id, name, label, value, checkBoxType, checked, onChange, inline, disabled, error }: IKKICheckbox) => {
  return (
    <div className={`kki-check ${inline ? 'd-inline-block me-3 mb-3' : ''} ${error ? 'check-error' : ''}`}>
      <Form.Check type={checkBoxType} label={label} name={name} id={id} checked={checked} onChange={onChange} value={value} inline={inline} disabled={disabled} />
    </div>
  );
};

export default KKICheckbox;
